
// Usage:
//
//  Simple: @include font-face(font name or variable);
//
//  Use each:
//
//    @each $font in ( COMA SEPARATED FONT NAMES)
//    {
//      @include font-face($font);
//    }
//

@mixin font-face($fontname) {
  @font-face {
    font-family: '#{$fontname}';

    src: url('fonts/#{$fontname}.eot');
    src: url('fonts/#{$fontname}.eot') format('embedded-opentype'),
    url('fonts/#{$fontname}.woff2') format('woff2'),
    url('fonts/#{$fontname}.woff') format('woff'),
    url('fonts/#{$fontname}.ttf') format('truetype'),
    url('fonts/#{$fontname}.svg##{$fontname}') format('svg');

    font-weight: normal;
    font-style: normal;
  }
}

@mixin font( $name, $src, $hash ) {
  @font-face {
    font-family: $name;
    src: url('fonts/' + $src + '.eot');
    src: url('fonts/' + $src + '.eot?#iefix') format('embedded-opentype'),
         url('fonts/' + $src + '.woff') format('woff'),
         url('fonts/' + $src + '.ttf') format('truetype'),
         url('fonts/' + $src + '.svg#' + $hash ) format('svg');
    font-weight:normal;
    font-style:normal;
  }
}
