
.btn-nettogumi{
    display: inline-block;
      margin: 0 auto;
      padding: 15px 35px;
      font-family: "RobotoCondensedBold";
      font-weight: normal;
      font-size: 19px;
      font-size: 1.1875rem;
      color: #030000;
      transition: background 0.3s ease;
      background-color: #ffa402;
      text-transform: uppercase;
      border-radius: 12px;
      background-clip: padding-box;
  }