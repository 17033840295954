.floated-cart.wheel {

  width: 100%;
  height: 0;
  position: fixed;
  bottom: -400px;
  left: 0;
  right: 0;
  background-color: #202020;
  z-index: 1000;
  -moz-box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  -o-box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  transition-duration: 1s;

  .close{
    display: none;
  }

  &.animate {
    height: 110px;
    transition-duration: 1s;
    bottom: 0px;

  }


  .floated-cart-wrapper {
    padding: 10px 20px;

    @include clearfix();

    .product-image {

      .img-container{
        line-height: 0;
        display: block;
        margin: 0;
        float: left;
        height: auto;
        margin-top: -45px;
        width: 240px;
        position: relative;
        min-height: 220px;
      }
      img.tire {
        width: 100%;
        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      img.rim{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.size-sm{
          max-width: 80%;
        }

        &.size-md{
          max-width: 82%;
        }

        &.size-lg{
          max-width: 85%;
        }
      }

      p{
        color: #ffa402;
        margin: 0px;
        margin-left: 25px;
        margin-bottom: 10px;
        margin-top: 10px;
        float: left;
        font-size: 34px;
        line-height: 1em;
        position: relative;
        font-family: $roboto-condensed-bold;
        font-weight: normal;

        span{
          display: block;
          font-size: 1.23em;
        }
      }

    }

    .floatedCartDetails{
      margin-top: -10px;
      padding-top: 10px;
      padding-left: 15px;
      background: #ffa402;
      position: relative;
      min-height: 110px;

      &:after{
        content: '';
        position: absolute;
        right: -100%;
        width: 100%;
        height: 100%;
        top: 0px;
        background: #ffa402;
      }

      .col-6{
        float: left;
      }

      .title{
        color: #202020;
        font-size: 21px;
        margin-top: 8px;
        line-height: 1.3em;
        font-family: $roboto-condensed-bold;
        font-weight: normal;
        margin: 20px 0px;
      }

      .price-button-container{
        .price-container{
          .col-4, .col-8{
            float: left;
          }

          .quantity-text{
            margin: 0px;
            color: #202020;
            text-transform: uppercase;
            line-height: 1.2em;
            font-size: 15px;
            margin-top: 7px;
            font-family: $roboto-condensed-bold;
            font-weight: normal;
          }

          .price-text{
            font-size: 40px;
            font-family: $roboto-condensed-bold;
            font-weight: normal;
            margin: 0px;
            margin-top: 4px;
            line-height: 1em;
            small{
              font-size: .6em;
            }
          }
        }

        .button-container{
          display: inline-block;
          padding: 10px 45px;
          background: #202020;
          color: #fff;
          margin-top: 8px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          a{
            text-transform: uppercase;
            color: #fff;
          }
        }
      }
    }


  }

}

.floated-cart-sm{
  display: none;
}

@include screen($null, $sm) {


  .floated-cart.wheel {
    border-top: 2px solid #ffa402;

    &.animate {
      height: auto;
      bottom: 0px;
    }

    .close{
      display: block;
      position: absolute;
      top: 7px;
      right: 7px;
      color: #202020;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50px;
      padding-top: 4px;
      line-height: 1em;
      font-family: Helvetica;
      font-weight: bold;
      width: 25px;
      height: 25px;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
    }


    .floated-cart-wrapper {
      padding: 10px 0;
      padding-bottom: 0px;

      .product-image {
        display: block;
        width: 100%;

        .img-container{
          width: 200px;
          min-height: 220px;
          position: absolute;
          left: -90px;
          z-index: 9999;
        }

        p{
          padding-left: 85px;
          margin-bottom: 30px;
        }
      }

      .floatedCartDetails{
        width: 100%;
        margin-left: -15px;
        padding-left: 30px;

        .col-6.title-container{
          float: none;
          width: 100%;
          padding-left: 95px;
          position: relative;

          .title{
            margin-top: 0px;
            margin-bottom: 10px;
          }
        }

        .col-6.bottom-container{
          width: 100%;
          float: none;
          text-align: center;
          border-top: 1px solid rgba(0,0,0,.2);
          padding-top: 10px;

          .quantity-text{
            text-align: left;
          }
        }

        
      }

      .col-9 {
        width: 100%;

      }

    }

  }

  .floated-cart-sm{
    display: block;
    position: fixed;
    bottom: -50px;
    left: 0px;
    width: 100%;
    z-index: 99999;
    text-align: center;

    .button-container{
      background: #202020;
      width: auto;
      display: inline-block;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 10px 45px;

      a{
        color: #fff;
        font-size: 18px;
      }
    }
  }
}

@include screen(($sm + 1), $lg) {

  .floated-cart {

    &.animate {
      height: 140px;

    }


    .floated-cart-wrapper {
      padding: 15px 10px 10px;

      .product-image {
        display: none;

      }

      .col-9 {
        width: 100%;

      }

      .text-container {

        .title {
          @include font-size(24px);

        }

        .price-cart-container {
          margin-top: 15px;

          .low-price-package {
            width: 125px;

            .first {
              padding: 1px 10px;
              @include font-size(14px);

            }

            .last {
              padding: 3px 10px;
              @include font-size(14px);

              small {
                @include font-size(14px);

              }

            }

          }

          .price-cont {
            margin-top: 5px;
            margin-left: 20px;

            .price {

              .price-text {
                @include font-size(22px);
                margin-top: 2px;

                small {
                  @include font-size(10px);

                }

              }

              .price-price {
                @include font-size(33px);

                small {
                  @include font-size(22px);

                }

              }

            }

            .piece-price {
              padding: 0;
              @include font-size(16px);

            }

          }

          .piece-button {
            margin-left: 15px;

            .button-wrapper {

              .select-wrapper {
                margin-right: 5px;

                select {
                  padding: 12px 32px 12px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 21px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 13px 42px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 10px -8px 0;

                }

              }

            }

          }

        }

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  .floated-cart {

    &.animate {
      height: 120px;

    }

    .floated-cart-wrapper {
      padding: 10px 0 10px;

      .text-container {

        .title {
          @include font-size(17px);

        }

        .price-cart-container {

          .low-price-package {
            width: 20%;
          }

          .price-cont {
            width: 40%;
            margin-left: 0;
            padding-left: 15px;

            .piece-price {
              padding: 0;
              margin-top: 0;
              @include font-size(14px);
              line-height: 1.1em;

            }

          }

          .piece-button {
            width: 40%;
            margin-left: 0;
            padding-left: 15px;

            .button-wrapper {

              .select-wrapper {
                margin-right: 10px;

                select {
                  padding: 8px 16px 8px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 17px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 8px 18px 10px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 0 -8px 0;

                }

                strong {
                  display: none;

                }

              }

            }

          }

        }

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .floated-cart {

    &.animate {
      height: 140px;

    }


    .floated-cart-wrapper {
      padding: 15px 10px 10px;

      .product-image {

        img {
          max-width: 80%;

        }

      }

      .text-container {

        .title {
          @include font-size(24px);

        }

        .price-cart-container {
          margin-top: 15px;

          .low-price-package {
            width: 125px;

            .first {
              padding: 1px 10px;
              @include font-size(14px);

            }

            .last {
              padding: 3px 10px;
              @include font-size(14px);

              small {
                @include font-size(14px);

              }

            }

          }

          .price-cont {
            margin-top: 2px;
            margin-left: 20px;

            .price {

              .price-text {
                @include font-size(22px);
                margin-top: 2px;

                small {
                  @include font-size(10px);

                }

              }

              .price-price {
                @include font-size(33px);
                line-height: 1.1em;
                small {
                  @include font-size(22px);

                }

              }

            }

            .piece-price {
              padding: 0;
              @include font-size(16px);

            }

          }

          .piece-button {
            margin-left: 15px;

            .button-wrapper {

              .select-wrapper {
                margin-right: 5px;

                select {
                  padding: 12px 32px 12px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 21px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 13px 42px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 10px -8px 0;

                }

              }

            }

          }

        }

      }

    }

  }

}

@include screen(($xl+1), $xxxl) {

  .floated-cart {

    &.animate {
      height: 135px;

    }


    .floated-cart-wrapper {
      padding: 15px 15px 10px;

      .product-image {

        img {
          max-width: 80%;

        }

      }

      .text-container {

        .title {
          @include font-size(24px);

        }

        .price-cart-container {
          margin-top: 15px;

          .low-price-package {
            width: 140px;

            .first {
              padding: 1px 10px;
              @include font-size(14px);

            }

            .last {
              padding: 4px 10px;
              @include font-size(14px);

              small {
                @include font-size(14px);

              }

            }

          }

          .price-cont {
            margin-top: 2px;
            margin-left: 20px;

            .price {

              .price-text {
                @include font-size(22px);
                margin-top: 2px;

                small {
                  @include font-size(10px);

                }

              }

              .price-price {
                @include font-size(33px);
                line-height: 1.1em;
                small {
                  @include font-size(22px);

                }

              }

            }

            .piece-price {
              padding: 0;
              @include font-size(16px);

            }

          }

          .piece-button {
            margin-left: 15px;

            .button-wrapper {

              .select-wrapper {
                margin-right: 5px;

                select {
                  padding: 12px 32px 12px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 21px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 13px 42px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 10px -8px 0;

                }

              }

            }

          }

        }

      }

    }

  }

}
