.featured-product {
    background: url('/images/nettogumi/featured_product_bg.jpg') center center no-repeat;
    background-size: cover;
    padding: 36px 0 40px;
    margin-bottom: 22px;

    .featured-image {

        img {
            margin-top: 9px;
            margin-bottom: -35px;
            line-height: 0;

        }

    }

    .featured-badge {
        position: relative;
        z-index: 2;
        background: url('/images/nettogumi/badge_orange_right_arrow_big.png') center center no-repeat;
        width: 290px;
        height: 73px;

        .display-table-cell {
            vertical-align: middle;

        }

        strong {
            display: block;
            padding: 0 10px 0 15px;
            font-family: $roboto-condensed-bold;
            text-decoration: underline;
            font-weight: normal;
            line-height: 1.1em;
            @include font-size(37px);
            color: $white;

        }

        span {
            display: block;
            padding: 0 20px 0 0;
            font-family: $roboto-bold;
            font-weight: normal;
            line-height: 1.1em;
            @include font-size(17px);
            color: $black-1;

        }

    }

    .featured-title {
        padding: 0;
        margin: 20px 75px 0 0;
        font-family: $poppins-bold;
        font-weight: normal;
        @include font-size(45px);
        color: $yellow;
        text-align: right;

    }


    .product-item-wide {
        background-color: $white;

    }

}

@include screen($null, $sm) {

    .featured-product {
        display: none;
        padding: 25px 0;

        .featured-image {
            display: none;

        }

        .col-9 {
            width: 100%;

        }

        .featured-title {
            margin: 20px auto 0;
            @include font-size(25px);
            line-height: 1.3em;
            text-align: center;

        }

        .product-item-wide {
            margin: 20px 0 0;

        }

    }

}

@include screen(($sm + 1), $lg) {

    .featured-product {
        padding: 36px 0 40px;

        .featured-image {
            display: none;

        }

        .col-9 {
            width: 100%;

        }

        .featured-title {
            margin: 20px auto 0;
            @include font-size(32px);
            text-align: center;

        }

    }

}

@include screen(($sm + 1), $md, landscape) {

    .featured-product {

        .product-item-wide {
            margin: 25px 0 0;

        }

    }

}

@include screen(($lg+1), $xl) {

    .featured-product {
        padding: 36px 0 40px;

        .featured-image {
            display: none;

        }

        .col-9 {
            width: 100%;

        }

        .featured-title {
            margin: 20px auto 0;
            @include font-size(40px);
            text-align: center;

        }

    }

}
