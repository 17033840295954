.product-item-wide {
  width: 100%;
  margin: 40px 0 0;
  border: 1px solid $gray-11;
  position: relative;
  min-height: 350px;

  &.wheel{
    .piw-cartinfo{
      min-height: 400px;
    }
  }

  .row {
    margin: 0;

  }

  &.with-notification,
  &.with-badge {
    position: relative;
  }

  .piw-thumbnail {
    float: left;
    width: 270px;
    min-height: 353px;
    padding: 14px 0 17px;
    overflow: hidden;
    position: relative;

    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      line-height: 0;
      @include transition(all .5s linear);
      //@include transform(scale3d(1, 1, 1));
    }

    &.with-video {
      position: relative;

      .video-preview {
        width: 193px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        margin-left: -(193px / 2);
        z-index: 1;
        background-color: $yellow;
        @include border-radius(3px);
        @include transition(background-color .5s linear);

        @include clearfix();

        a {
          display: block;

        }

        .play {
          display: block;
          float: left;
          background-color: $black;
          padding: 2px 12px 2px 15px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          i {
            display: block;
            font-family: $websymbol;
            font-weight: normal;
            font-style: normal;
            color: $white;
            @include font-size(35px);
            margin: 0 auto 4px;

          }

        }

        .text {
          display: block;
          float: left;
          font-family: $roboto-bold;
          font-weight: normal;
          color: $black-1;
          @include font-size(13px);
          text-transform: uppercase;
          line-height: 1.1em;
          margin-top: 10px;
          padding: 0 15px;

        }

        &:hover {
          background-color: $black-1;

          .text {
            color: $yellow;

          }

        }

      }

    }

    .gallery-preview {
      display: block;
      width: 200px;
      margin: 10px auto 24px;
      z-index: 1;
      background-color: $yellow;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -13px;

      @include border-radius(3px);
      @include transition(background .5s linear);

      @include clearfix();

      a {
        display: block;

      }

      .play {
        display: block;
        float: left;
        background-color: $black;
        padding: 2px 12px 2px 15px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;

        i {
          display: block;
          font-family: $websymbol;
          font-weight: normal;
          font-style: normal;
          color: $white;
          @include font-size(35px);
          margin: 0 auto 4px;

        }

      }

      .text {
        display: block;
        float: left;
        font-family: $roboto-bold;
        font-weight: normal;
        color: $black-1;
        @include font-size(15px);
        text-transform: uppercase;
        line-height: 1.1em;
        margin-top: 8px;
        padding: 0 15px;

        &.mobile{
          display: none;
        }

        &.desktop{
          display: block;
        }

      }

      &:hover {
        background-color: $black-1;

        .text {
          color: $yellow;

        }

      }

    }

    img.winter-icon{
      position: absolute;
      right: 10px;
      bottom: 50px;
    }

  }

  &.with-video-preview {

    .piw-thumbnail {
      position: relative;

      .video-preview {
        width: 193px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        margin-left: -(193px / 2);
        z-index: 1;
        background-color: $yellow;
        @include border-radius(3px);
        @include transition(background-color .5s linear);

        @include clearfix();

        a {
          display: block;

        }

        .play {
          display: block;
          float: left;
          background-color: $black;
          padding: 2px 12px 2px 15px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          i {
            display: block;
            font-family: $websymbol;
            font-weight: normal;
            font-style: normal;
            color: $white;
            @include font-size(35px);
            margin: 0 auto 4px;

          }

        }

        .text {
          display: block;
          float: left;
          font-family: $roboto-bold;
          font-weight: normal;
          color: $black-1;
          @include font-size(13px);
          text-transform: uppercase;
          line-height: 1.1em;
          margin-top: 10px;
          padding: 0 15px;

        }

        &:hover {
          background-color: $black-1;

          .text {
            color: $yellow;

          }

        }

      }

    }

  }

  .piw-description {
    min-height: 353px;
    float: left;
    width: 350px;
    position: relative;

    .piw-description-container {
      padding: 0 0 0 24px;

    }

    .manufacturer-logo {
      margin: 32px 0 2px;

    }

    .title {
      font-family: $roboto-condensed-regular;
      font-weight: normal;
      @include font-size(27px);
      color: $black-1;
      margin: 0;
      padding: 0;
      line-height: 1.3em;

      a {
        color: $black-1;
        @include transition(color .3s ease);

        &:hover {
          color: $yellow;

        }

      }

    }

    .size-info {
      display: inline-block;
      font-family: $roboto-bold;
      font-weight: normal;
      @include font-size(19px);
      color: $gray;
      line-height: 1.3em;
      margin-bottom: 16px;

    }

    .extra-info {
      padding: 15px 34px 15px 0;
      position: relative;

      .display-table-cell {
        vertical-align: middle;

      }

      .icon {
        display: block;
        float: left;
        width: 33px;
        height: 30px;
        margin-right: 15px;

        &.benzin {
          background: url('/images/nettogumi/extra_info_benzinkut.png') center center no-repeat;
          background-size: 100%;

        }

        &.idojaras {
          background: url('/images/nettogumi/extra_info_idojaras.png') center center no-repeat;
          background-size: 100%;

        }

        &.zajszint {
          background: url('/images/nettogumi/extra_info_zajszint.png') center center no-repeat;
          background-size: 100%;
          margin-right: -1px;

        }

      }

      .class {
        display: block;
        float: left;
        width: 30px;
        height: 30px;
        text-align: center;
        font-family: $roboto-bold;
        font-weight: normal;
        color: $white;
        line-height: 1.8em;
        text-transform: uppercase;
        position: relative;
        @include text-shadow(1px, 1px, 2px, rgba(0, 0, 0, .4));

        &::before {
          content: '';
          position: absolute;
          left: -25px;
          width: 0;
          height: 0;
          border-right: 10px solid transparent;
          border-top: 15px solid transparent;
          border-left: 15px solid transparent;
          border-bottom: 15px solid transparent;

        }

        &.class-a {
          background-color: $class-A;

          &::before {
            border-right-color: $class-A;

          }

        }

        &.class-b {
          background-color: $class-B;

          &::before {
            border-right-color: $class-B;

          }

        }

        &.class-c {
          background-color: $class-C;

          &::before {
            border-right-color: $class-C;

          }

        }

        &.class-d {
          background-color: $class-D;

          &::before {
            border-right-color: $class-D;

          }

        }

        &.class-e {
          background-color: $class-E;

          &::before {
            border-right-color: $class-E;

          }

        }

        &.class-f {
          background-color: $class-F;

          &::before {
            border-right-color: $class-F;

          }

        }

        &.class-g {
          background-color: $class-G;

          &::before {
            border-right-color: $class-G;

          }

        }

      }

      .zajszint-text {
        $font-family: $roboto-condensed-regular;
        font-weight: normal;
        @include font-size(16px);
        color: $black-1;
        margin-top: 3px;

      }

      &::before {
        content: '';
        position: absolute;
        top:0;
        right: 0;
        left: -23px;
        width: 100%;
        height: 1px;
        background: url('/images/nettogumi/separator_long_horizontal.png') center center no-repeat;

      }

    }

    .product-rating {
      padding: 15px 0;
      position: relative;

      &.product-rating-rim{
        padding: 10px 0px;
      }

      &::before {
        content: '';
        position: absolute;
        top:0;
        right: 0;
        left: -23px;
        width: 100%;
        height: 1px;
        background: url('/images/nettogumi/separator_long_horizontal.png') center center no-repeat;

      }

      .text {
        font-family: $roboto-condensed-regular;
        font-weight: normal;
        @include font-size(17px);
        color: $black-1;
        display: block;
        float: left;
        margin-right: 10px;

        strong {
          font-family: $roboto-condensed-bold;
          font-weight: normal;

        }

      }

      .star {
        font-family: $websymbol;
        @include font-size(17px);
        color: $yellow;
        display: block;
        margin-top: -1px;
        float: left;

        &.empty {
          color: $gray-12;

        }

      }

      .stars-wrapper {
        display: block;
        float: left;
        margin-top: 2px;
        width: 90px;
        height: 20px;
        position: relative;

        .stars-mask {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 3;
          width: 90px;
          height: 20px;
          background: url('/images/nettogumi/stars_mask_retina.png') no-repeat;
          background-size: 100%;

        }

        .yellow-bg {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 90px;
          height: 20px;
          background: $yellow;

        }

        .gray-bg {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          background: $gray-12;
          width: 90px;
          height: 20px;

        }

      }

    }

    .descr {
      padding-top: 17px;
      padding-right: 34px;
      position: relative;

      &.descr-rim{
        padding-top: 10px;
      }

      &::before {
        content: '';
        position: absolute;
        top:0;
        right: 0;
        left: -23px;
        width: 100%;
        height: 1px;
        background: url('/images/nettogumi/separator_long_horizontal.png') center center no-repeat;

      }

      p {
        margin:0;
        padding: 0;
        line-height: 1.3em;
        color: $black-1;

      }

      .more-info {
        display: inline-block;
        margin-top: 10px;
        font-family: $roboto-bold;
        font-weight: normal;
        @include font-size(17px);
        color: $orange;
        @include transition(color .3s ease);

        &:hover {
          color: $black-1;

        }

      }

    }

    .delivery{
      position: relative;
      margin-top: 10px;
      padding-top: 10px;

      &::before {
        content: '';
        position: absolute;
        top:0;
        right: 0;
        left: -23px;
        width: 100%;
        height: 1px;
        background: url('/images/nettogumi/separator_long_horizontal.png') center center no-repeat;

      }
      .delivery-info{
        color: #808080;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top:0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: url('/images/nettogumi/separator_long_vertical.png') center center no-repeat;

    }

  }

  .piw-cartinfo {
    float: left;
    min-height: 353px;
    width: 306px;
    position: relative;

    .cartinfo-container {
      padding: 0 10px;

    }

    .low-price-package {
      margin-top: 25px;
      border: 1px solid $orange-1;
      margin-right: 29px;
      background-color: $orange-1;

      @include clearfix();

      .col-6 {
        float: left;

      }

      .first {
        font-family: $roboto-condensed-bold;
        font-weight: normal;
        @include font-size(16px);
        color: $orange-1;
        background-color: $white;
        padding: 4px 0 5px 10px;
        text-align: left;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          right: -30px;
          top: 0;
          width: 0;
          height: 0;
          border-left: 13px solid $white;
          border-top: 17px solid transparent;
          border-right: 17px solid transparent;
          border-bottom: 16px solid transparent;

        }

      }

      .last {
        font-family: $roboto-condensed-bold;
        font-weight: normal;
        @include font-size(19px);
        color: $white;
        padding: 3px 10px 4px 0;
        text-align: right;

        small {
          @include font-size(16px);

        }

      }

    }

    .price {
      position: relative;
      margin: 17px 0 0;

      @include clearfix();

      .price-text {
        float: left;
        font-family: $roboto-condensed-bold;
        font-weight: normal;
        @include font-size(24px);
        color: $black-1;
        line-height: 1.1em;
        margin-top: 10px;
        margin-right: 10px;

        small {
          display: block;
          font-family: $roboto-bold;
          font-weight: normal;
          text-transform: uppercase;
          @include font-size(11px);
          line-height: .9em;

        }

      }

      .price-price {
        float: left;
        font-family: $roboto-condensed-bold;
        font-weight: normal;
        @include font-size(44px);
        color: $orange-1;
        line-height: 1.1em;

        small {
          @include font-size(29px);

        }

      }

    }

    .piece-price {
      display: block;
      padding: 10px 0 0;
      font-family: $roboto-bold;
      font-weight: normal;
      @include font-size(20px);
      color: $gray;
      position: relative;

      .piece-price-amount{
        text-decoration: line-through;
      }

      &::before {
        content: '';
        position: absolute;
        top:0;
        right: 0;
        left: -20px;
        width: 100%;
        height: 1px;
        background: url('/images/nettogumi/separator_short_horizontal.png') center center no-repeat;

      }

    }

    .button-wrapper {
      text-align: left;
      margin: 20px 0;

    }

    .product-button {
      display: inline-block;
      margin: 0 auto;
      padding: 20px 55px;
      font-family: $roboto-bold;
      font-weight: normal;
      @include font-size(19px);
      color: $black-1;
      @include transition(background .3s ease);
      background-color: $yellow;
      text-transform: uppercase;
      @include border-radius(12px);

      span {
        display: inline-block;
        width: 38px;
        height: 30px;
        background: url('/images/nettogumi/cart_icon_btn.png') center center no-repeat;
        background-size: 100%;
        margin: 0 10px -5px 0;

      }

      &:hover {
        color: $white;
        background-color: $black-1;

      }

    }

    .delivery-info {
      display: block;
      margin: 25px auto 10px;
      text-align: center;
      font-family: $roboto-condensed-regular;
      font-weight: normal;
      @include font-size(17px);
      color: $gray;

    }

    .stock-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      background-color: $gray-11;
      padding: 6px 0 8px;

      span {
        display: inline-block;
        @include font-size(17px);
        color: $black-1;

        strong {
          font-family: $roboto-bold;
          font-weight: normal;

        }

        &.stock-info-image {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -35px;
            width: 25px;
            height: 25px;
            background: url('/images/nettogumi/stock_icon.png') no-repeat center center;
            background-size: 100%;

          }

        }

      }

    }

  }

  &:hover {

    .piw-thumbnail {

      img  {
        @include transform(scale3d(1.1, 1.1, 1));

      }

    }

  }

  .notification {
    position: absolute;
    top: -10px;
    left: 31.5%;
    font-family: $roboto-regular;
    @include font-size(15px);
    color: $gray-16;
    text-align: center;
    @include border-radius(3px);
    padding: 3px 20px;
    z-index: 1;


    &.bg-info {
      background-color: $yellow-1;

    }

    &.bg-extra {
      background-color: $yellow-2;

    }

  }

  .badge-container{
    position: absolute;
    top: -5px;
    left: 5px;
    z-index: 2;

    .badge {
      position: relative;
      width: 88px;
      height: 99px;
      text-align: center;
      float: left;
      margin-right: 5px;
  
      &.badge-orange {
        background: url('/images/nettogumi/badge_orange_small.png') top no-repeat;
        background-size: 100%;
      }
      
      &.badge-blue{
        background: url('/images/nettogumi/badge_blue_small.png') top no-repeat;
        background-size: 100%;

        .badge-wrapper{
          display: flex;
          height: 90%;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          img{
            margin-bottom: 5px;
          }

          strong{
            color: #fff;
            font-family: "RobotoCondensedBold";
            font-weight: normal;
            display: block;
            @include font-size(15px);
            line-height: 1em;
            text-transform: uppercase;
          }
        }
      }
  
      .badge-wrapper {
        padding: 5px;
  
        .badge-foreground-image{
          max-width: 80%;
        }
  
      }
  
      &.only-text .badge-wrapper {
        padding: 18px 5px 0;
  
        small {
          display: block;
          font-family: $roboto-condensed-bold;
          font-weight: normal;
          @include font-size(15px);
          line-height: 1em;
          color: $black-1;
          text-transform: uppercase;
  
        }
  
        strong {
          display: block;
          font-family: $roboto-condensed-bold;
          font-weight: normal;
          @include font-size(32px);
          line-height: 1.1em;
          color: $white;
          text-transform: uppercase;
  
        }
  
      }
  
      &.with-icon .badge-wrapper {
        padding: 10px 5px 0;
  
        strong {
          display: block;
          font-family: $roboto-condensed-bold;
          font-weight: normal;
          @include font-size(15px);
          line-height: 1em;
          color: $white;
          text-transform: uppercase;
  
        }
  
      }
  
    }
  }

  &.with-promotion {
    border: 2px solid $blue;

    .piw-description {
      width: 351px;

    }

    .piw-cartinfo {
      width: 305px;

    }

  }

  .promotion-wrapper {
    width: 100%;
    background-color: $blue;
    position: relative;
    z-index: 10;

    @include clearfix();

    .promotion-image {

      img {
        display: block;
        margin: -15px 0 0 10px;
        line-height: 0;
        max-width: 105px;
      }


    }

    .promotion-description {
      margin: 0 0 0 20px;

      p {
        margin: 15px 0;
        padding: 0;
        @include font-size(17px);
        color: $white;
        vertical-align: middle;

        strong {
          padding: 0 15px;
          font-family: $roboto-bold;
          font-weight: normal;
          text-transform: uppercase;
          @include font-size(27px);
          color: $yellow-2;
          vertical-align: bottom;

        }

        .inline-link {
          font-family: $roboto-bold;
          font-weight: normal;
          @include font-size(17px);
          color: $white;
          @include transition(color .3s ease);

          &:hover {
            color: $yellow-2;

          }

        }

      }

    }

  }

}

@include screen($null, $sm) {

  .badge{
    &.badge-blue{
      display: none;
    }
  }

  .product-item-wide {
    margin: 50px 0 0;
    min-height: auto;

    .piw-thumbnail {
      float: left;
      width: 100%;
      min-height: auto;
      padding: 14px 0 17px;

      &.with-video {
        position: relative;

        .video-preview {

          .play {

            i {
              margin: 0 auto;

            }

          }

        }

        .gallery-preview{
          width: 180px !important;

          .text{

            &.mobile{
              display: block;
            }
          
            &.desktop{
              display: none;
            }
          }
        }

      }

    }

    .piw-description {
      min-height: auto;
      width: 100%;

      .piw-description-container {
        padding: 0 15px;

      }

      .manufacturer-logo {
        margin: 20px 0 2px;

        img {
          max-width: 35%;

        }

      }

      .title {
        @include font-size(25px);

      }

      .size-info {
        @include font-size(18px);
        margin-bottom: 15px;

      }

      .extra-info {
        padding: 15px 0;

        .icon {
          width: 28px;
          height: 25px;
          margin-right: 15px;

        }

        .class {
          width: 28px;
          height: 26px;
          line-height: 1.5em;

          &::before {
            left: -25px;
            border-right: 9px solid transparent;
            border-top: 13px solid transparent;
            border-left: 16px solid transparent;
            border-bottom: 13px solid transparent;

          }

        }

        .zajszint-text {
          margin-top: 2px;

        }

        &::before {
          left: 0;
          background: $gray-11;

        }

      }

      .product-rating {
        padding: 10px 0;

        &::before {
          left: 0;
          background: $gray-11;

        }

      }

      .descr {
        padding-top: 17px;
        padding-right: 0;

        &::before {
          left: 0;
          background: $gray-11;

        }

        p {
          line-height: 1.4em;
        }

        .more-info {
          margin-top: 7px;
          @include font-size(16px);

        }

      }

      &::before {
        content: none;

      }

    }

    .piw-cartinfo {
      min-height: auto;
      width: 100%;

      .cartinfo-container {
        padding: 0 15px;

      }

      .low-price-package {
        margin-top: 30px;
        margin-right: 0;

        .last {
          text-align: center;

        }

      }

      .price {
        margin: 17px 0 0;

      }

      .piece-price {
        @include font-size(18px);

        &::before {
          left: 0;
          background: $gray-11;

        }

      }

      .button-wrapper {
        text-align: center;
        margin: 20px 0;

      }

      .product-button {
        display: block;
        width: 100%;

      }

      .delivery-info {
        margin: 20px auto 20px;

      }

      .stock-info {
        position: inherit;
        width: 100%;

        span {
          @include font-size(15px);

          &.stock-info-image {
            margin-top: 3px;
            margin-right: -20px;

            &::before {
              top: 2px;
              left: -25px;
              width: 20px;
              height: 20px;

            }

          }

        }

      }

    }

    .notification {
      left: 95px;
      @include font-size(13px);
      line-height: 1.3em;
      padding: 3px 10px;

    }

    &.with-promotion {

      border-width: 4px;
      border-color: #ff4f02 !important;

      .piw-description {
        width: 100%;

      }

      .piw-cartinfo {
        width: 100%;

      }

    }

    .promotion-wrapper {
      display: none;
      .promotion-image {
        display: none;

      }

      .promotion-description {
        margin: 0;

        p {
          margin: 15px;
          @include font-size(14px);
          line-height: 1.3em;

          strong {
            display: block;
            padding: 0 0 10px;
            @include font-size(21px);

          }

          .inline-link {
            @include font-size(14px);

          }

        }

      }

    }

  }

}

@include screen($null, $xs) {

  .product-item-wide {

    .piw-cartinfo {

      .low-price-package {

        .last {
          padding: 3px 10px 4px;
          @include font-size(15px);

          small {
            @include font-size(12px);
          }

        }

      }

      .price {
        margin: 15px 0 0;

        .price-text {
          margin-top: 6px;

        }

        .price-price {
          @include font-size(40px);

        }

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  .product-item-wide {
    width: 100%;
    min-height: 340px;

    &.with-video-preview {

      .piw-thumbnail {

        .video-preview {

          .play {
            padding: 2px 12px 2px 15px;

            i {
              @include font-size(33px);
              margin: 2px auto 2px;

            }

          }

        }

      }

    }

    .piw-thumbnail {
      width: 29%;
      padding: 14px 0 0;
      min-height: 340px;

    }

    .piw-description {
      width: 37%;
      min-height: auto;

      .piw-description-container {
        padding: 0 0 0 14px;

      }

      .manufacturer-logo {
        margin: 32px 0 2px;

        img {
          max-width: 30%;

        }

      }

      .title {
        @include font-size(22px);

      }

      .size-info {
        @include font-size(17px);

      }

      .extra-info {

        .icon {
          width: 28px;
          height: 25px;
          margin-right: 15px;

        }

        .class {
          width: 28px;
          height: 26px;
          line-height: 1.5em;

          &::before {
            left: -25px;
            border-right: 10px solid transparent;
            border-top: 13px solid transparent;
            border-left: 16px solid transparent;
            border-bottom: 13px solid transparent;

          }

        }

        .zajszint-text {
          margin-top: 2px;

        }

        &::before {
          left: -14px;

        }

      }

      .product-rating {

        &::before {
          left: -14px;

        }

      }

      .descr {
        padding-right: 24px;

        &::before {
          left: -14px;

        }

        p {
          @include font-size(14px);

        }

        .more-info {
          @include font-size(15px);

        }

      }

    }

    .piw-cartinfo {
      width: 34%;
      min-height: 340px;

      .cartinfo-container {
        padding: 0 10px;

      }

      .low-price-package {
        margin-right: 0;

        .first {
          @include font-size(13px);
          padding: 4px 0 5px 10px;

        }

        .last {
          @include font-size(15px);
          padding: 4px 10px 4px 0;

          small {
            @include font-size(12px);

          }

        }

      }

      .price {
        margin: 15px 0 0;


        .price-text {
          @include font-size(21px);
          margin-top: 5px;

          small {
            @include font-size(10px);

          }

        }

        .price-price {
          @include font-size(35px);

          small {
            @include font-size(20px);

          }

        }

      }

      .piece-price {
        padding: 5px 0 0;
        @include font-size(16px);

        &::before {
          left: -10px;

        }

      }

      .product-button {
        padding: 18px 53px;
        @include font-size(17px);

        span {
          width: 32px;
          height: 24px;
          margin: 0 10px -5px 0;

        }

      }

      .delivery-info {
        margin: 25px auto 10px;
        @include font-size(15px);

      }

      .stock-info {
        width: 100.1%;

        span {
          @include font-size(15px);

          &.stock-info-image {
            margin-right: -20px;

            &::before {
              top: 0;
              left: -25px;
              width: 20px;
              height: 20px;

            }

          }

        }

      }

    }

    .promotion-wrapper {

      .promotion-image {

        img {
          margin: -14px 0 0 10px;

        }


      }

      .promotion-description {
        margin: 0 0 0 20px;

        p {
          margin: 17px 0 14px;
          @include font-size(13px);

          strong {
            padding: 0 15px;
            @include font-size(18px);

          }

          .inline-link {
            @include font-size(13px);

          }

        }

      }

    }

    &.with-promotion {

      .piw-description {
        width: 37%;

      }

      .piw-cartinfo {
        width: 34%;

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  .product-item-wide {
    min-height: auto;

    .piw-thumbnail {
      width: 50%;
      min-height: auto;

    }

    .piw-description {
      width: 50%;
      min-height: auto;

    }

    .piw-cartinfo {
      width: 100%;
      min-height: auto;

      @include clearfix();

      .mobile-float-left {
        width: 45%;
        float: left;

      }

      .mobile-float-right {
        width: 45%;
        float: right;

      }

      .low-price-package {
        margin-right: 0;

        .first {
          @include font-size(13px);
          padding: 4px 0 5px 10px;

        }

        .last {
          @include font-size(15px);
          padding: 4px 10px 4px;
          text-align: center;

          small {
            @include font-size(12px);

          }

        }

      }

      .price {
        margin: 15px 0 0;


        .price-text {
          @include font-size(21px);
          margin-top: 3px;

          small {
            @include font-size(10px);

          }

        }

        .price-price {
          @include font-size(35px);

          small {
            @include font-size(20px);

          }

        }

      }

      .piece-price {
        margin: 0 0 15px;
        padding: 0;
        @include font-size(14px);

        &::before {
          content: none;

        }

      }

      .button-wrapper {
        text-align: center;

      }

      .product-button {
        padding: 18px 53px;
        @include font-size(17px);

        span {
          width: 32px;
          height: 24px;
          margin: 0 10px -5px 0;

        }

      }

      .delivery-info {
        margin: 25px auto 0;
        @include font-size(15px);

      }

      .stock-info {
        position: inherit;

        span {

          &.stock-info-image {

            &::before {
              top: 3px;

            }

          }

        }

      }

    }

    .promotion-wrapper {

      .promotion-image {
        display: none;

      }

      .promotion-description {
        margin: 0;

        p {
          margin: 17px 15px 14px;
          @include font-size(13px);

          strong {
            padding: 0 15px 0 0;
            @include font-size(16px);

          }

          .inline-link {
            @include font-size(13px);

          }

        }

      }

    }

    &.with-promotion {

      .piw-description {
        width: 50%;

      }

      .piw-cartinfo {
        width: 100%;

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .product-item-wide {
    width: 100%;
    min-height: 350px;

    .piw-thumbnail {
      width: 29%;

    }

    .piw-description {
      width: 37%;

    }

    .piw-cartinfo {
      width: 34%;

      .stock-info {
        width: 100.1%;

      }

    }


    &.with-promotion {

      .piw-description {
        width: 37%;

      }

      .piw-cartinfo {
        width: 34%;

      }

    }

  }

  .product-list {

    .product-item-wide {


      &.with-video-preview {

        .piw-thumbnail {

          .video-preview {

            .play {
              padding: 2px 12px 2px 15px;

              i {
                @include font-size(33px);
                margin: 2px auto 2px;

              }

            }

          }

        }

      }

      .piw-description {

        .piw-description-container {
          padding: 0 0 0 14px;

        }

        .manufacturer-logo {
          margin: 32px 0 2px;

          img {
            max-width: 30%;

          }

        }

        .title {
          @include font-size(22px);

        }

        .size-info {
          @include font-size(17px);

        }

        .extra-info {

          &::before {
            left: -14px;

          }

        }

        .product-rating {

          &::before {
            left: -14px;

          }

        }

        .descr {
          padding-right: 24px;

          &::before {
            left: -14px;

          }

          p {
            @include font-size(14px);

          }

          .more-info {
            @include font-size(15px);

          }

        }

      }

      .piw-cartinfo {

        .cartinfo-container {
          padding: 0 10px;

        }

        .low-price-package {
          margin-right: 0;

          .first {
            @include font-size(15px);
            padding: 4px 0 5px 10px;

          }

          .last {
            @include font-size(17px);
            padding: 4px 10px 4px 0;

            small {
              @include font-size(14px);

            }

          }

        }

        .price {
          margin: 15px 0 0;


          .price-text {
            @include font-size(22px);
            margin-top: 5px;

            small {
              @include font-size(10px);

            }

          }

          .price-price {
            @include font-size(38px);

            small {
              @include font-size(22px);

            }

          }

        }

        .piece-price {
          padding: 5px 0 0;
          @include font-size(16px);

          &::before {
            left: -10px;

          }

        }

        .product-button {
          padding: 20px 55px;
          @include font-size(17px);

          span {
            width: 32px;
            height: 24px;
            margin: 0 10px -5px 0;

          }

        }

        .delivery-info {
          margin: 25px auto 10px;
          @include font-size(15px);

        }

        .stock-info {

          span {
            @include font-size(15px);

            &.stock-info-image {
              margin-right: -20px;

              &::before {
                top: 0;
                left: -25px;
                width: 20px;
                height: 20px;

              }

            }

          }

        }

      }

      .promotion-wrapper {

        .promotion-image {

          img {
            margin: -14px 0 0 10px;

          }


        }

        .promotion-description {
          margin: 0 0 0 20px;

          p {
            margin: 17px 0 14px;
            @include font-size(14px);

            strong {
              padding: 0 15px;
              @include font-size(20px);

            }

            .inline-link {
              @include font-size(14px);

            }

          }

        }

      }

    }

  }

}

@include screen(($xl+1), $xxl) {

  .product-item-wide {
    width: 100%;
    margin: 40px 0 0;

    .piw-thumbnail {
      width: 29%;

    }

    .piw-description {
      width: 37%;

    }

    .piw-cartinfo {
      width: 34%;

      .stock-info {
        width: 100.1%;

      }

    }


    &.with-promotion {

      .piw-description {
        width: 37%;

      }

      .piw-cartinfo {
        width: 34%;

      }

    }

  }

}
