.szettben-olcsobb {
  margin: 52px 0 0;

  .szettben-olcsobb-container {
    border: 1px solid $gray-9;

    @include clearfix();

    .with-banner {
      padding-bottom: 21px + 17px;

      .ribbon {
        width: 130px;
        margin: -5px auto 0;

        .top {
          height: 105px;
          background-color: $orange-1;
          position: relative;

          .badge-wrapper {
            padding: 25px 5px 0;
            text-align: center;

            small {
              display: block;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(19px);
              line-height: 1em;
              color: $black-1;
              text-transform: uppercase;

            }

            strong {
              display: block;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(43px);
              line-height: 1.1em;
              color: $white;
              text-transform: uppercase;

            }

          }

          &::after {
            content: '';
            position: absolute;
            bottom: -21px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 65px 21px 65px;
            border-color: transparent transparent $orange-1 transparent;
            -webkit-transform: rotate(-180deg);
            z-index: 1;

          }

        }

        .bottom {
          height: 90px;
          background-color: $yellow;
          position: relative;

          .badge-wrapper {
            padding: 35px 5px 0;
            text-align: center;

            small {
              display: block;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(16px);
              line-height: 1em;
              color: $black-1;

            }

            strong {
              display: block;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(20px);
              line-height: 1.1em;
              color: $black-1;

            }

          }

          &::after {
            content: '';
            position: absolute;
            bottom: -21px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 65px 21px 65px;
            border-color: transparent transparent $yellow transparent;
            -webkit-transform: rotate(-180deg);
            z-index: 2;

          }

        }

      }

    }

    .with-texts {
      padding: 40px 40px 40px 0;

      .title {
        margin: 0;
        padding: 0 0 0 80px;
        font-family: $poppins-medium;
        font-weight: normal;
        @include font-size(37px);
        line-height: 1.2em;
        color: $black-1;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -7px;
          left: 0;
          width: 59px;
          height: 54px;
          background: url('/images/nettogumi/gumik_icon.png') center center no-repeat;
          background-size: 100%;

        }

      }

      p {
        margin: 0 0 15px;
        padding: 0;
        @include font-size(17px);
        line-height: 1.3em;
        color: $black-1;

        &:nth-of-type(1) {
          margin-top: 23px;

        }

        &:last-child {
          margin-bottom: 0;

        }

      }

    }

  }

}

@include screen($null, $sm) {

  .szettben-olcsobb {
    margin: 40px 0 0;

    .szettben-olcsobb-container {

      .with-banner {
        padding-bottom: 21px;
        @include clearfix();

        .ribbon {
          width: 100%;
          margin: -5px auto 0;

          .top {
            width: 48%;
            float: left;
            height: 100px;

            .badge-wrapper {
              padding: 20px 5px 0;

            }

            &::after {
              bottom: inherit;
              top: 0;
              right: -21px;
              border-width: 50px 21px 50px 0;
              border-color: transparent $orange-1 transparent transparent;

            }

          }

          .bottom {
            width: 46.5%;
            float: left;
            height: 100px;

            .badge-wrapper {
              padding: 30px 5px 0;

            }

            &::after {
              bottom: inherit;
              top: 0;
              right: -21px;
              border-width: 50px 21px 50px 0;
              border-color: transparent $yellow transparent transparent;

            }

          }

        }

      }

      .with-texts {
        padding: 0 20px 20px;

        .title {
          padding: 0 0 0 50px;
          @include font-size(27px);

          &::before {
            top: 1px;
            width: 43px;
            height: 40px;

          }

        }

        p {
          @include font-size(16px);

        }

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  .szettben-olcsobb {
    margin: 40px 0 0;

    .szettben-olcsobb-container {

      .col-md-2 {
        width: 25%;
        float: left;

      }

      .col-md-10 {
        width: 75%;
        float: left;

      }

      .with-banner {
        padding-bottom: 21px + 17px;

      }

      .with-texts {
        padding: 30px 30px 30px 0;

        .title {
          padding: 0 0 0 50px;
          @include font-size(27px);

          &::before {
            top: -7px;
            width: 43px;
            height: 40px;

          }

        }

        p {
          @include font-size(16px);

        }

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .szettben-olcsobb {
    margin: 40px 0 0;

    .szettben-olcsobb-container {

      .with-texts {
        padding: 40px 40px 40px 0;

        .title {
          padding: 0 0 0 70px;
          @include font-size(35px);

          &::before {
            top: -7px;
            width: 55px;
            height: 50px;

          }

        }

        p {
          @include font-size(16px);

        }

      }

    }

  }

}
