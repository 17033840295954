.ie-popup {
    z-index: 600;
    position: fixed;
    bottom: 10%;
    left:0px;
    right:320px;
    color: white;
    background: rgba(0, 0, 0, 0.75);
    padding:30px;
    .ie-popup-wrapper {
        h3 {
            line-height: 1.2em;
            color:$yellow;
        }

    }
}

    /* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
    .ie-popup {
        bottom: 270px;
        right:0px;
    }
}
