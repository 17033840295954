.cart-data {
    padding-top: 25px;

    @include screen($null, $sm){
        > .container > .row > .col-xs-12{
            padding: 0px;
        }
    }

    hr {
        margin: 20px 30px;
        background-color: $gray-9;
    }
    .price-sum {
        font-weight: bold;
        @include font-size(24px);
        .price-summary {
            margin-top: 0;
        }
    }
    .col-sm-2,
    .col-sm-3 {
        position: absolute;
        height: 100%;
    }
    .cart-item {
        margin-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $gray-9;

        .row {
            position: relative;

            .col-xs-24,
            .col-sm-24{
                width: 100%;
                float: left;
            }

            .content-bottom{
                padding: 18px 0px;
                padding-left: 60px;
                position: absolute;
                bottom: -40px;
                left: 0px;

                .label{
                    padding-left: 45px;
                    font-size: .85em;
                    font-family: "Roboto";
                    display: block;
                }

                input{
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }

                .checkmark{
                    position: absolute;
                    top: 15px;
                    left: 60px;
                    height: 30px;
                    width: 30px;
                    background-color: #f3f4f4;
                    border: 1px solid #c4c4c4;
                    border-radius: 0px;

                    &:after{
                        content: "";
                        position: absolute;
                        display: none;
                        left: 10px;
                        top: 5px;
                        width: 9px;
                        height: 16px;
                        border: solid #555;
                        border-width: 0 4px 4px 0;
                        transform: rotate(45deg);
                    }
                }

                input:checked ~ .checkmark:after {
                    display: block;
                }
            }
        }
        &:last-child {
            border-bottom: 0;
        }
        .col-sm-5 {
            &:after {
                position: absolute;
                content: "";
                display: inline-block;
                width: 1px;
                height: 80px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: $gray-9;
            }
        }
        .col-sm-2 {
            &:after {
                position: absolute;
                content: "";
                display: inline-block;
                width: 1px;
                height: 80px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: $gray-9;
            }
        }
        .col-sm-2 {
            &:after {
                position: absolute;
                content: "";
                display: inline-block;
                width: 1px;
                height: 80px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: $gray-9;
            }
        }
        @include screen(($sm + 1), $lg) {
            .col-sm-2 {
                position: absolute;
                margin-top: 0;
                &:after {
                    height: 80px;
                }
            }
        }
        @include screen(($sm + 1), $md) {
            .col-xs-4 {
                position: absolute;
                margin-top: 0;
                &:after {
                    height: 80px;
                }
            }
        }
        @include screen($null, $sm) {
            margin-top: 10px;
            padding-bottom: 10px;

            .col-xs-4 {
                position: relative;
                margin-top: 10px;
                min-height: 38px;
                &:after {
                    display: none;
                    height: 40px;
                }
            }
            .col-xs-12 {
                position: relative;

                &:after {
                    display: none;
                }
            }
        }

        @include screen(null, $sm){
            .assembly-container{
                min-height: 40px;

                .content-bottom{
                    bottom: -15px;
                    padding-left: 0px;

                    .label{
                        padding-left: 55px;
                    }

                    .checkmark{
                        left: 15px;
                    }
                }
            }
        }
    }
    .cart-data-box {
        padding: 25px;
        margin-top: 48px;
        border: 1px solid $gray-9;

        @include screen($null, $sm){
            padding: 5px;
            margin-top: 15px;

            &.delivery-box{
                padding: 25px;
            }

            label.radio-button-wrapper{
                @include font-size(17px);
            }
        }

        h3 {
            margin-top: 8px;
            font-weight: bold;
            @include font-size(27px);
        }
        .form-group {
            hr {
                margin-left: 0;
                margin-right: 0;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            label {
                display: block;
                margin-bottom: 3px;
            }
            .form-control {
                width: 100%;
                height: 36px;
                border-radius: 6px;
                padding-top: 4px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 4px;
                line-height: 1;
                @include font-size(16px);
                background-color: $gray-2;
                border: 1px solid $gray-9;
                &.postcode {
                    width: 68px;
                    margin-bottom: 4px;
                }
                &.city {
                    width: 259px;
                    margin-bottom: 4px;
                }
            }
            .radio-button-wrapper {
                margin-bottom: 16px;
                padding-bottom: 16px;
                border-bottom: 1px solid $gray-9;
                input[type="radio"] {
                    display: none;
                }
                .radio-button {
                    display: block;
                    position: relative;
                    padding-left: 30px;
                    span {
                        color: $orange-1;
                    }
                    &:before {
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        top: 8px;
                        left: 4px;
                        z-index: 1;
                        border-radius: 50%;
                        background-color: $yellow;
                    }
                    &:after {
                        position: absolute;
                        content: "";
                        width: 18px;
                        height: 18px;
                        top: 4px;
                        left: 0;
                        z-index: 0;
                        border-radius: 50%;
                        background-color: $white;
                        border: 2px solid $black;
                    }
                }
                input[type="radio"]:checked+.radio-button {
                    &:before {
                        content: "";
                    }
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
        .form-element {
            margin-top: 0;
            width: 100%;
            label {
                font-family: "RobotoMedium";
            }
        }
        textarea {
            max-width: 100%;
            margin-bottom: 0;
            min-height: 138px;
            margin-top: 3px;
        }
        .product-item {
            position: relative;
            width: 100%;
            border: 0;
            float: none;
            margin-top: 0;
            padding-left: 100px;
            background-color: transparent;
            border-bottom: 1px solid $gray-9;

            &.delivery-item{
                height: 75px;
                display: flex;
                align-items: center;
            }

            h4 {
                margin-top: 0;
                margin-bottom: 0;
                font-size: 18px;
                font-family: "RobotoCondensedBold";
            }
            .size-info {
                color: $gray;
                margin-top: 0;
                margin-bottom: 10px;
                @include font-size(14px);
                line-height: 0.9375rem;
            }
            .price {
                margin-top: 7px;
                span {
                    color: $orange-1;
                }
            }
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 95px;

                &.delivery-item{
                    width: 75px;
                }
            }
            .product-description {
                .manufacturer-logo {
                    margin-bottom: 0;
                    height: auto;
                    margin: 0px;
                    margin-top: 10px;
                    img {
                        position: relative;
                        width: 100px;
                        left: 0;
                        margin-bottom: -5px;
                    }
                }
                .product-detailed-description {
                    display: none;
                }
                .more-info {
                    line-height: 1;
                    margin-top: 8px;
                    margin-bottom: 0;
                    .popup-youtube {
                        display: inline-block;
                        float: left;
                        margin-right: 5px;
                        .play {
                            display: block;
                            border-radius: 3px;
                            background-color: $black;
                            padding: 3px 8px 3px 10px;
                            i {
                                position: relative;
                                top: 0px;
                                display: block;
                                font-family: $websymbol;
                                font-weight: normal;
                                font-style: normal;
                                color: $white;
                                @include font-size(13px);
                                line-height: 1;
                                margin: 0 auto 0;
                            }
                        }
                    }
                    .btn-dark {
                        display: inline-block;
                        color: $white;
                        background-color: $black;
                        text-transform: uppercase;
                        font-size: 10px;
                        font-size: 0.625rem;
                        //@include font-size(13px);
                        line-height: 1;
                        padding: 6px 5px 3px;
                        border-radius: 3px;
                    }
                    .btn-orange {
                        display: inline-block;
                        color: $white;
                        background-color: $orange-1;
                        text-transform: uppercase;
                        @include font-size(10px);
                        line-height: 1;
                        padding: 6px 5px 3px;
                        border-radius: 3px;
                    }
                }
            }
            &:first-child {}
            &:last-child {
                border-bottom: 0;
            }

            @include screen($null, $sm) {
                padding-left: 90px;
                h2{
                    @include font-size(16px);
                }
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 85px;
                }
            }
        }
        .spaced-v {
            position: absolute;
            top: 50%;
            width: 76%;
            @include font-size(15px);
            transform: translateY(-50%);
            span {
                display: inline-block;
                font-weight: bold;
                color: $gray;
                &.price {
                    color: $orange-1;
                }
            }
            .delete {
                position: relative;
                top: 3px;
                display: inline-block;
                cursor: pointer;
                float: right;

            }
            @include screen(($sm + 1), $lg) {
                position: relative;
                top: 0;
                transform: translateY(0);
                margin-top: 25px;
            }
            @include screen(($sm + 1), $lg) {
                position: absolute;
                top: 50%;
                width: 76%;
                margin-top: 0;
                @include font-size(15px);
                transform: translateY(-50%);
            }
            @include screen($null, $sm) {
                position: relative;
                display: inline;
                top: 0;
                transform: translateY(0);
                @include font-size(11px);
            }
        }
        .text-center {
            text-align: center;

            @include screen(($sm + 1), $lg) {
                text-align: center;
            }
            @include screen(($sm + 1), $lg) {
                text-align: center;
            }
            @include screen($null, $sm) {
                text-align: center;
            }
        }
        .mobile-fix {
            padding: 0 15px;

            @include screen(($sm + 1), $lg) {
                padding: 0 15px;
            }
            @include screen(($sm + 1), $lg) {
                padding: 0 15px;
            }
            @include screen($null, $sm) {
                padding: 6px 0 0 0;

                font-size: 13px;
            }
        }
    }
    .checkbox-button-wrapper {
        input[type="checkbox"] {
            display: none;
        }
        .checkbox-button {
            display: block;
            position: relative;
            padding-left: 30px;
            margin-top: 8px;
            padding-top: 2px;
            line-height: 1.3;
            span {
                color: $orange-1;
            }
            &:before {
                position: absolute;
                width: 16px;
                height: 16px;
                top: 5px;
                left: 1px;
                z-index: 1;
                border-radius: 3px;
                background: url('/images/nettogumi/done.png') no-repeat;
                background-size: 100%;
            }
            &:after {
                position: absolute;
                content: "";
                width: 18px;
                height: 18px;
                top: 4px;
                left: 0;
                z-index: 0;
                border-radius: 3px;
                background-color: $white;
                border: 2px solid $black;
            }
        }
        input[type="checkbox"]:checked+.checkbox-button {
            &:before {
                content: "";
            }
        }

        @include screen(($sm + 1), $lg) {
            .checkbox-button {
                padding-top: 5px;
                margin-top: 8px;
            }
        }
        @include screen(($sm + 1), $lg) {
            .checkbox-button {
                padding-top: 5px;
                margin-top: 8px;
            }
        }
        //Mobile
        @include screen($null, $sm) {
            .checkbox-button {
                padding-top: 5px;
                margin-top: 8px;
            }
        }
    }
    .price-summary {
        @include font-size(27px);
        color: $orange-1;
        float: right;
        margin-top: 32px;

        @include screen(($sm + 1), $lg) {
            @include font-size(27px);
        }
        @include screen(($sm + 1), $lg) {
            @include font-size(27px);
        }
        @include screen($null, $sm) {
            @include font-size(18px);
        }
    }
    .price-summary-text {
        float: left;
        @include font-size(27px);
        margin-top: 32px;
        font-weight: bold;
    }
    .button-wrapper {

        margin-top: 32px;
        .product-button {
            display: block;
            text-align: center;
            font-weight: bold;
            font-size: 1.1875rem;
            color: $black;
            text-transform: uppercase;
            background-clip: padding-box;
            margin: 0px auto;
            padding: 15px 35px;
            transition: background 0.3s ease;
            border-radius: 12px;
            &:hover {
                color: $white;
                background-color: $black;
            }
        }

    }
}

#couponContainer{
    .text-right{
        text-align: right;
    }

    p{
        font-weight: bold;
        font-size: 18px;
        font-family: "RobotoCondensedBold";
    }

    input[type="text"]{
        margin-top: 1em;
    }

    .coupon-button{
        background: #009830;
        color: #fff;
        border: none;
        border-radius: 3px;
        padding: 3px 15px;
        font-size: 14px;
        cursor: pointer;

        &:disabled{
            opacity: .8;
            cursor: not-allowed;
        }
    }

    .coupon-result-container{
        color: #808080;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 0.9375rem;
        margin-left: 10px;
    }
}

.btn-orange {
    display: inline-block;
    margin: 0 auto;
    padding: 15px 35px;
    font-family: $roboto-condensed-bold;
    font-weight: normal;
    @include font-size(19px);
    color: $black-1;
    @include transition(background .3s ease);
    background-color: $yellow;
    text-transform: uppercase;
    @include border-radius(12px);
    &:hover {
        color: $white;
        background-color: $black-1;
    }
}

.mfp-popup-upsell-wrapper {
    input[type="text"] {
        width: 520px;
    }
}

.obtain {
    background-color: $yellow;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 40px 0;
    .section-title {
        @include font-size(36px);
        text-align: left;
    }
}

.btn-block {
    display: block;
    margin: -4px auto 0;
    padding: 18px 26px 18px 26px;
    font-family: "RobotoBold";
    font-weight: normal;
    font-size: 19px;
    font-size: 1.1875rem;
    color: #030000;
    transition: background 0.3s ease;
    background-color: #ffa402;
    text-transform: uppercase;
    border-radius: 12px;
    text-align: center;
    background-clip: padding-box;
    span {
        display: inline-block;
        left: 26px;
        width: 38px;
        height: 30px;
        background: url('/images/nettogumi/pay_vagon.png') no-repeat;
        background-size: 100%;
        margin: 0 10px -13px 0;
    }
}

.more-items {
    .item {
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 1px solid $gray-9;
        &:last-child {
            margin-bottom: 70px;
            border-bottom: 1px solid $gray-9;
        }
        .col-xs-12 {
            position: static;
            img {
                max-height: 120px;
                max-width: 100px;
            }
            .item-info-wrapper {
                position: absolute;
                top: 50%;
                left: 120px;
                transform: translateY(-50%);
                img {
                    max-width: none;
                    max-height: 15px;
                }
                h4 {
                    margin: 0;
                    line-height: 1.3em;
                }
            }
        }
        .auto-height {
            position: absolute;
            height: 100%;
            top: 0;
            .item-info-wrapper {
                left: 0;
                width: 100%;
                margin-top: 0;
                .old-price {
                    display: inline-block;
                    font-weight: bold;
                    @include font-size(18px);
                    text-decoration: line-through;
                }
                .new-price {
                    color: $orange-1;
                    font-weight: bold;
                    display: inline-block;
                    @include font-size(32px);
                }
                .select-wrapper {
                    width: 100px;
                    margin-bottom: 0;
                    display: inline-block;
                }
                .btn-block {
                    display: inline-block;
                    padding: 15px 35px;
                }
                &.form-element {
                    text-align: right;
                    padding-right: 30px;
                }
            }
        }
    }
}

@include screen(($sm+1),
$lg) {
    .more-items {
        .item {
            position: relative;
            padding-top: 15px;
            padding-bottom: 15px;
            border-top: 1px solid $gray-9;
            &:last-child {
                margin-bottom: 70px;
                border-bottom: 1px solid $gray-9;
            }
            .col-xs-12 {
                position: static;
                img {
                    max-height: 120px;
                    max-width: 100px;
                }
                .item-info-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 120px;
                    transform: translateY(-50%);
                    img {
                        max-width: none;
                        max-height: 15px;
                    }
                    h4 {
                        margin: 0;
                        line-height: 1.3em;
                    }
                }
            }
            .auto-height {
                position: relative;
                height: 100%;
                top: 0;
                .item-info-wrapper {
                    left: 0;
                    width: 100%;
                    margin-top: 0 !important;
                    .old-price {
                        display: inline-block;
                        font-weight: bold;
                        @include font-size(18px);
                        text-decoration: line-through;
                    }
                    .new-price {
                        color: $orange-1;
                        font-weight: bold;
                        display: inline-block;
                        @include font-size(32px);
                    }
                    .select-wrapper {
                        width: 100px;
                        margin-bottom: 0;
                        display: inline-block;
                    }
                    .btn-block {
                        display: inline-block;
                        padding: 15px 35px;
                    }
                    &.form-element {
                        text-align: right;
                        padding-right: 30px;
                    }
                }
            }
        }
    }
}

@include screen(($sm+1),
$lg) {
    .more-items {
        .item {
            position: relative;
            padding-top: 15px;
            padding-bottom: 15px;
            border-top: 1px solid $gray-9;
            &:last-child {
                margin-bottom: 70px;
                border-bottom: 1px solid $gray-9;
            }
            .col-xs-5,
            .col-xs-7 {
                position: static;
                img {
                    max-height: 120px;
                    max-width: 100px;
                }
                .item-info-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 120px;
                    transform: translateY(-50%);
                    img {
                        max-width: none;
                        max-height: 15px;
                    }
                    h4 {
                        margin: 0;
                        line-height: 1.3em;
                    }
                }
            }
            .auto-height {
                position: absolute;
                height: 100%;
                top: 0;
                .item-info-wrapper {
                    left: 0;
                    width: 100%;
                    margin-top: 0;
                    .old-price {
                        display: inline-block;
                        font-weight: bold;
                        @include font-size(18px);
                        text-decoration: line-through;
                    }
                    .new-price {
                        color: $orange-1;
                        font-weight: bold;
                        display: inline-block;
                        @include font-size(32px);
                    }
                    .select-wrapper {
                        width: 100px;
                        margin-bottom: 0;
                        display: inline-block;
                    }
                    .btn-block {
                        display: inline-block;
                        padding: 15px 35px;
                    }
                    &.form-element {
                        text-align: right;
                        padding-right: 30px;
                    }
                }
            }
        }
    }
}

.more-items {
    .item {
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 1px solid $gray-9;
        &:last-child {
            margin-bottom: 70px;
            border-bottom: 1px solid $gray-9;
        }
        .col-xs-5,
        .col-xs-7 {
            position: static;
            img {
                max-height: 80px;
                max-width: 80px;
            }
            .item-info-wrapper {
                position: absolute;
                top: 50%;
                left: 80px;
                transform: translateY(-50%);
                img {
                    max-width: none;
                    max-height: 15px;
                }
                h4 {
                    margin: 0;
                    line-height: 1.3em;
                }
            }
        }
        .auto-height {
            position: absolute;
            height: 100%;
            top: 0;
            .item-info-wrapper {
                left: 0;
                width: 100%;
                margin-top: 0 !important;
                .old-price {
                    display: inline-block;
                    font-weight: bold;
                    @include font-size(18px);
                    text-decoration: line-through;
                }
                .new-price {
                    color: $orange-1;
                    font-weight: bold;
                    display: inline-block;
                    @include font-size(32px);
                }
                .select-wrapper {
                    width: 80px;
                    margin-bottom: 0;
                    display: inline-block;
                }
                .btn-block {
                    display: inline-block;
                    padding: 15px;
                    @include font-size(16px);
                }
                &.form-element {
                    text-align: right;
                    padding-right: 0;
                }
            }
        }
    }
}

@include screen($null,
$sm) {
    .more-items {
        .item {
            position: relative;
            padding-top: 15px;
            padding-bottom: 15px;
            border-top: 1px solid $gray-9;
            &:last-child {
                margin-bottom: 70px;
                border-bottom: 1px solid $gray-9;
            }
            .col-xs-5,
            .col-xs-7 {
                position: static;
                img {
                    max-height: 120px;
                    max-width: 100px;
                }
                .item-info-wrapper {
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: translateY(0);
                    img {
                        max-width: none;
                        max-height: 15px;
                    }
                    h4 {
                        margin: 0;
                        line-height: 1.3em;
                    }
                }
            }
            .auto-height {
                position: relative;
                height: 100%;
                top: 0;
                .item-info-wrapper {
                    left: 0;
                    width: 100%;
                    margin-top: 0 !important;
                    .old-price {
                        display: inline-block;
                        font-weight: bold;
                        @include font-size(18px);
                        text-decoration: line-through;
                    }
                    .new-price {
                        color: $orange-1;
                        font-weight: bold;
                        display: inline-block;
                        @include font-size(32px);
                    }
                    .select-wrapper {
                        width: 100%;
                        margin-top: 15px;
                        margin-bottom: 0;
                        display: block;
                    }
                    .btn-block {
                        display: block;
                        margin-top: 15px;
                        padding: 15px 35px;
                    }
                    &.form-element {
                        text-align: right;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

@include screen(($sm+1),
$lg) {
    .hidden-sm {
        display: none;
    }
}

@include screen($null,
$sm) {
    .hidden-xs {
        display: none;
    }
}
