.product-list {

  .product-list-title {
    font-family: $poppins-medium;
    font-weight: normal;
    @include font-size(53px);
    line-height: 1.3em;
    color: $gray-17;
    margin: -5px 0 15px;
    padding: 0;

  }

  .product-list-filter {
    /*border-top: 1px solid $gray-11;*/
    border-bottom: 1px solid $gray-11;
    padding: 12px 4px 12px 8px;

    .show-on-mobile {
      display: none;

    }

    .display-table-cell {
      vertical-align: middle;

    }

    .set {
      text-transform: uppercase;
      @include font-size(19px);
      color: $black-1;

      .set-szezon {
        display: inline-block;
        margin-left: 20px;
        margin-bottom: -9px;
        width: 27px;
        height: 30px;
        background-size: 100%;

        &.teli {
          background: url('/images/nettogumi/szezon_teli_small.png') no-repeat;

        }

      }

      .result-count {
        display: inline-block;
        margin-left: 30px;
        font-family: $roboto-bold;
        font-weight: normal;
        @include font-size(19px);
        background-color: $yellow;
        padding: 3px 15px;
        text-transform: none;

      }

    }

    .sorting-wrapper {
      text-align: right;

      label {
        font-family: $roboto-regular;
        font-weight: normal;
        @include font-size(17px);
        color: $black-1;
        margin-right: 17px;

      }

      .select-wrapper {
        position: relative;
        display: inline-block;

        select {
          padding: 10px 25px;
          background-color: $gray-2;
          border: 1px solid $gray-15;
          @include border-radius(6px);
          width: 100%;
          color: $gray-16;
          @include font-size(16px);
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

        }

        &::after {
          content: '';
          height: 9px;
          width: 9px;
          background: url('/images/nettogumi/arrow_down.png') no-repeat;
pointer-events: none;
          background-size: 100%;
          position: absolute;
          top: 20px;
          right: 15px;
          z-index: 1;

        }

      }

    }

  }

}

@include screen($null, $sm) {

  .product-list {
    width: 100%;

    .product-list-title {
      @include font-size(25px);
      line-height: 1.3em;
      margin: 0 0 15px;

    }

    .product-list-filter {
      padding: 10px 0;

      .show-on-mobile {
        display: block;

      }

      .display-table,
      .display-table-row {
        display: block;

      }

      .set-wrapper {
        display: block;
        width: 100%;
        margin-bottom: 20px;

      }

      .set {
        @include font-size(13px);

        .set-szezon {
          margin-left: 10px;
          margin-bottom: -7px;
          width: 20px;
          height: 23px;

          &.teli {
            background-size: 100%;

          }

        }

        .result-count {
          float: right;
          margin-left: 5px;
          @include font-size(12px);
          padding: 3px 10px;

        }

      }

      .open-filter-sidebar {
        font-family: "RobotoBold";
        font-weight: normal;
        font-size: 18px;
        background-color: $yellow;
        padding: 6px 14px;
        text-transform: none;
        position: fixed;
        right: -30px;
        top: 25%;
        z-index: 99999999;
        transform: rotate(-90deg);
        cursor: pointer;
      }

      .close-filter-sidebar{
        font-family: "RobotoBold";
        font-weight: normal;
        font-size: 18px;
        background-color: #ffa402;
        padding: 7px 14px;
        text-transform: none;
        position: fixed;
        right: -2px;
        top: 12%;
        z-index: 99999999;
        transform: rotate(-90deg);
        cursor: pointer;
        display: none;
      }      

      .sorting-wrapper {
        display: block;
        width: 80%;
        float: left;
        text-align: left;

        label {
          display: none;

        }

        .select-wrapper {
          width: 100%;

          select {
            padding: 6px 40px 6px 20px;
            @include font-size(13px);

          }

          &::after {
            height: 7px;
            width: 7px;
            top: 16px;
            right: 10px;

          }

        }

      }

    }

  }

  html.show-filter{
    .close_filter_btn{
      display: block !important;
    }
  }

}

@include screen($null, $xs) {

  .product-list {

    .product-list-filter {

      .set {
        @include font-size(12px);

        .result-count {
          @include font-size(11px);
          padding: 3px 8px;

        }

      }

    }

  }

}


@include screen(($sm + 1), $lg) {

  .product-list {
    width: 100%;

    .product-list-title {
      @include font-size(35px);
      margin: -5px 0 15px;

    }

    .product-list-filter {
      padding: 12px 4px 12px 8px;

      .show-on-mobile {
        display: block;

      }

      .set {
        @include font-size(15px);

        .set-szezon {
          margin-left: 20px;
          margin-bottom: -7px;
          width: 22px;
          height: 25px;

          &.teli {
            background-size: 100%;

          }

        }

        .result-count {
          margin-left: 20px;
          @include font-size(15px);
          padding: 3px 15px;

        }

      }

      .open-filter-sidebar {
        font-family: "RobotoBold";
        font-weight: normal;
        font-size: 18px;
        background-color: $yellow;
        padding: 6px 14px;
        text-transform: none;
        position: fixed;
        right: -30px;
        top: 25%;
        z-index: 999;
        transform: rotate(-90deg);
        cursor: pointer;

      }

      .sorting-wrapper {

        label {
          @include font-size(15px);
          margin-right: 17px;

        }

        .select-wrapper {

          select {
            padding: 6px 40px 6px 20px;
            @include font-size(13px);

          }

          &::after {
            height: 7px;
            width: 7px;
            top: 16px;
            right: 10px;

          }

        }

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  .product-list {
    width: 100%;

    .product-list-title {
      @include font-size(25px);
      line-height: 1.3em;
      margin: 0 0 15px;

    }

    .product-list-filter {
      padding: 10px 0;

      .show-on-mobile {
        display: block;

      }

      .set-wrapper {
        margin-bottom: 20px;

      }

      .set {
        @include font-size(13px);

        .set-szezon {
          margin-left: 10px;
          margin-bottom: -7px;
          width: 20px;
          height: 23px;

          &.teli {
            background-size: 100%;

          }

        }

        .result-count {
          margin-left: 5px;
          @include font-size(12px);
          padding: 3px 10px;

        }

      }

      .open-filter-sidebar {
        font-family: "RobotoBold";
        font-weight: normal;
        font-size: 18px;
        background-color: $yellow;
        padding: 6px 14px;
        text-transform: none;
        position: fixed;
        right: -30px;
        top: 25%;
        z-index: 999;
        transform: rotate(-90deg);
        cursor: pointer;

      }

      .sorting-wrapper {
        text-align: left;

        label {
          display: none;

        }

        .select-wrapper {
          width: 100%;

          select {
            padding: 6px 40px 6px 20px;
            @include font-size(13px);

          }

          &::after {
            height: 7px;
            width: 7px;
            top: 16px;
            right: 10px;

          }

        }

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .product-list {
    width: 80%;

    .product-list-title {
      @include font-size(35px);
      margin: -5px 0 15px;

    }

    .product-list-filter {
      padding: 12px 4px 12px 8px;

      .set {
        @include font-size(15px);

        .set-szezon {
          margin-left: 20px;
          margin-bottom: -7px;
          width: 22px;
          height: 25px;

          &.teli {
            background-size: 100%;

          }

        }

        .result-count {
          margin-left: 20px;
          @include font-size(15px);
          padding: 3px 15px;

        }

      }

      .sorting-wrapper {

        label {
          @include font-size(15px);
          margin-right: 17px;

        }

        .select-wrapper {

          select {
            padding: 6px 40px 6px 20px;
            @include font-size(13px);

          }

          &::after {
            height: 7px;
            width: 7px;
            top: 16px;
            right: 10px;

          }

        }

      }

    }

  }
 

}
