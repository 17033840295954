.product-main-info-wrapper {
  margin: 56px 0 0;

  .container{
    hr{
      background-color: #ddd;
      margin-top: 35px;
    }
  }
  .product-thumbnail {

    .product-thumbnail-wrapper {
      border: 1px solid $gray-9;
      position: relative;

      .badge-container{
        position: absolute;
        top: -5px;
        left: 5px;
        z-index: 2;

        .badge {
          position: relative;
          top: -6px;
          left: 12px;
          z-index: 2;
          width: 88px;
          height: 99px;
          text-align: center;
          float: left;
          margin-right: 5px;
  
          &.badge-orange {
            background: url('/images/nettogumi/badge_orange_small.png') top no-repeat;
            background-size: 100%;
          }

          &.badge-blue{
            background: url('/images/nettogumi/badge_blue_small.png') top no-repeat;
            background-size: 100%;
    
            .badge-wrapper{
              display: flex;
              height: 90%;
              align-items: center;
              justify-content: center;
              flex-direction: column;
    
              img{
                margin-bottom: 5px;
              }
    
              strong{
                color: #fff;
                font-family: "RobotoCondensedBold";
                font-weight: normal;
                display: block;
                @include font-size(15px);
                line-height: 1em;
                text-transform: uppercase;
              }
            }
          }
  
          .badge-wrapper {
            padding: 5px;
  
            .badge-foreground-image{
              max-width: 80%;
            }
          }
  
          &.only-text .badge-wrapper {
            padding: 18px 5px 0;
  
            small {
              display: block;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(15px);
              line-height: 1em;
              color: $black-1;
              text-transform: uppercase;
  
            }
  
            strong {
              display: block;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(32px);
              line-height: 1.1em;
              color: $white;
              text-transform: uppercase;
  
            }
  
          }
  
          &.with-icon .badge-wrapper {
            padding: 10px 5px 0;
  
            strong {
              display: block;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(15px);
              line-height: 1em;
              color: $white;
              text-transform: uppercase;
  
            }
  
          }
  
        }
      }
  

      .thumbnail {
        margin: 40px auto 0;
        overflow: hidden;

        img.szerelve-badge{
          position: absolute;
          left: 5px;
          top: 50%;
          z-index: 999;
          transform: translateY(-50%);
          width: 110px;
        }

        a {
          display: block;

          &:hover {
            img  {
              @include transform(scale3d(1.1, 1.1, 1));

            }

          }

        }

        img {
          max-width: 100%;
          display: block;
          margin: 0 auto;
          line-height: 0;
          @include transition(all .5s linear);
          //@include transform(scale3d(1, 1, 1));

        }

      }

      

      .video-preview {
        display: block;
        width: 211px;
        margin: 10px auto 24px;
        z-index: 1;
        background-color: $yellow;
        @include border-radius(3px);
        @include transition(background .5s linear);

        @include clearfix();

        a {
          display: block;

        }

        .play {
          display: block;
          float: left;
          background-color: $black;
          padding: 2px 12px 2px 15px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          i {
            display: block;
            font-family: $websymbol;
            font-weight: normal;
            font-style: normal;
            color: $white;
            @include font-size(35px);
            margin: 0 auto 4px;

          }

        }

        .text {
          display: block;
          float: left;
          font-family: $roboto-bold;
          font-weight: normal;
          color: $black-1;
          @include font-size(15px);
          text-transform: uppercase;
          line-height: 1.1em;
          margin-top: 8px;
          padding: 0 15px;


        }

        &:hover {
          background-color: $black-1;

          .text {
            color: $yellow;

          }

        }

      }
      .gallery-preview {
        display: block;
        width: 250px;
        margin: 10px auto 24px;
        z-index: 1;
        background-color: $yellow;
        @include border-radius(3px);
        @include transition(background .5s linear);

        @include clearfix();

        a {
          display: block;

        }

        .play {
          display: block;
          float: left;
          background-color: $black;
          padding: 2px 12px 2px 15px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          i {
            display: block;
            font-family: $websymbol;
            font-weight: normal;
            font-style: normal;
            color: $white;
            @include font-size(35px);
            margin: 0 auto 4px;

          }

        }

        .text {
          display: block;
          float: left;
          font-family: $roboto-bold;
          font-weight: normal;
          color: $black-1;
          @include font-size(15px);
          text-transform: uppercase;
          line-height: 1.1em;
          margin-top: 8px;
          padding: 0 15px;

          &.mobile{
            display: none;
          }

          &.desktop{
            display: block;
          }

        }

        &:hover {
          background-color: $black-1;

          .text {
            color: $yellow;

          }

        }

      }

    }

    .additional-pictures-container{
      display: block;
      margin-top: 15px;
      .col-4{
        float: left;
        padding: 5px;

        &:first-child{
          transform: translateX(-5px);
        }

        &:last-child{
          transform: translateX(5px);
        }

        .picture-container{
          padding: 10px;
          border: 1px solid #ccc;
        }
      }
    }

  }

  .product-details {

    .product-title {
      font-family: $poppins-medium;
      font-weight: normal;
      @include font-size(53px);
      color: $gray-3;
      margin: 0;
      padding: 0;
      line-height: 1.3em;

    }

    .product-size {
      font-family: $roboto-bold;
      font-weight: normal;
      @include font-size(24px);
      color: $gray;
      margin: -5px 0 0;
      padding: 0;
      line-height: 1.3em;

    }

    .price-cart-wrapper {
      margin: 25px 0 0;

      @include clearfix();

      .col-md-5 {
        width: 43%;
        padding-left: 0;

      }

      .col-md-6 {
        float: right;
        width: 55%;
        padding: 0;

      }

      .low-price-package {
        border: 1px solid $orange-1;
        background-color: $orange-1;

        @include clearfix();

        .col-6 {
          float: left;

        }

        .first {
          font-family: $roboto-condensed-bold;
          font-weight: normal;
          @include font-size(16px);
          color: $orange-1;
          background-color: $white;
          padding: 4px 0 5px 10px;
          text-align: left;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            right: -30px;
            top: 0;
            width: 0;
            height: 0;
            border-left: 13px solid $white;
            border-top: 17px solid transparent;
            border-right: 17px solid transparent;
            border-bottom: 16px solid transparent;

          }

        }

        .last {
          font-family: $roboto-condensed-bold;
          font-weight: normal;
          @include font-size(19px);
          color: $white;
          padding: 3px 10px 4px 15px;
          text-align: center;

          small {
            @include font-size(16px);

          }

        }

      }

      .price {
        position: relative;
        margin: 10px 0 0;

        @include clearfix();

        .price-text {
          float: left;
          font-family: $roboto-condensed-bold;
          font-weight: normal;
          @include font-size(24px);
          color: $black-1;
          line-height: 1.1em;
          margin-top: 10px;
          margin-right: 10px;

          small {
            display: block;
            font-family: $roboto-bold;
            font-weight: normal;
            text-transform: uppercase;
            @include font-size(11px);
            line-height: .9em;

          }

        }

        .price-price {
          float: left;
          font-family: $roboto-condensed-bold;
          font-weight: normal;
          @include font-size(44px);
          color: $orange-1;
          line-height: 1.1em;

          small {
            @include font-size(29px);

          }

        }

      }

      .piece-price {
        display: block;
        padding: 15px 0 0;
        font-family: $roboto-bold;
        font-weight: normal;
        @include font-size(20px);
        color: $gray;

        .piece-price-amount{
          text-decoration: line-through;
        }

      }

      .button-wrapper {
        @include clearfix();

        .select-wrapper {
          position: relative;
          margin-top: 0;
          margin-right: 10px;

          select {
            padding: 17px 40px 17px 20px;
            background-color: $gray-2;
            border: 1px solid $gray-15;
            @include border-radius(0);
            width: 100%;
            color: $gray-16;
            @include font-size(21px);
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

          }

          &::after {
            content: '';
            height: 9px;
            width: 9px;
            background: url('/images/nettogumi/arrow_down.png') no-repeat;
pointer-events: none;
            background-size: 100%;
            position: absolute;
            top: 25px;
            right: 15px;
            z-index: 1;

          }

        }

        .product-button {
          display: inline-block;
          margin: -4px auto 0;
          padding: 20px 55px;
          font-family: $roboto-bold;
          font-weight: normal;
          @include font-size(19px);
          color: $black-1;
          @include transition(background .3s ease);
          background-color: $yellow;
          text-transform: uppercase;
          @include border-radius(12px);

          span {
            display: inline-block;
            width: 38px;
            height: 30px;
            background: url('/images/nettogumi/cart_icon_btn.png') center center no-repeat;
            background-size: 100%;
            margin: 0 10px -5px 0;

          }

          &:hover {
            color: $white;
            background-color: $black-1;

          }

        }

      }

      .delivery-info {
        margin: 28px 0 0;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 48px;
          height: 43px;
          background: url('/images/nettogumi/penztarca_icon.png') center center no-repeat;
          background-size: 100% 100%;

        }

        .open-details,
        p {
          display: block;
          cursor: pointer;
          margin: 0;
          padding: 0 0 0 74px;
          @include font-size(15px);
          color: $black-1;

          strong {
            font-family: $roboto-bold;
            font-weight: normal;
            @include font-size(16px);
            display: block;

          }

          &:hover a {
            color: $black-1;

          }

          a {
            font-family: $roboto-bold;
            font-weight: normal;
            color: $orange-1;
            @include transition(color .5s linear);

            &:hover {
              color: $black-1;

            }

          }

        }

      }

    }

    .delivery-info-details {
      display: none;

      p {
        margin: 15px 0 0;
        padding: 0;
        @include font-size(15px);
        color: $black-1;

        strong {
          font-family: $roboto-bold;
          font-weight: normal;

        }

        a {
          font-family: $roboto-bold;
          font-weight: normal;
          color: $orange-1;
          @include transition(color .5s linear);

          &:hover {
            color: $black-1;

          }

        }

      }

    }

    .product-description {
      background-color: $gray-18;
      border-top: 1px solid $gray-11;
      padding: 15px 15px 25px;
      margin-top: 15px;

      &.wheel-rim{
        border: none;
        background: transparent;
        margin-top: 0px;
      }

      p {
        margin: 0 0 15px;
        padding: 0;
        @include font-size(17px);
        color: $gray-17;
        line-height: 1.3em;

        &:last-child {
          margin: 0;

        }

      }

    }

    .details-wrapper {
      padding: 0;

      .display-table-row {
        vertical-align: middle;

        &:nth-child(even) {
          background-color: $gray-18;

        }

        &:nth-child(4) {

          .col-4,
          .col-8 {
            border-bottom: 1px solid $gray-11;

          }

        }

        .col-4  {
          border-top: 1px solid $gray-11;
          padding: 10px 0 10px 15px;
          vertical-align: middle;

          strong {
            font-family: $roboto-bold;
            font-weight: normal;
            @include font-size(17px);
            color: $black-1;

          }

        }

        .col-8 {
          border-top: 1px solid $gray-11;
          padding: 10px 0 10px 15px;
          vertical-align: middle;

          &.with-image {
            line-height: 0;

          }

          p {
            margin: 0;
            padding: 0;
            @include font-size(17px);
            color: $black-1;

          }

        }

      }

      .extra-info {
        position: relative;
        width: 65%;

        .display-table-cell {
          vertical-align: middle;

        }

        .col-4  {
          border-top: none;
          padding: 0;

        }

        .icon {
          display: block;
          float: left;
          width: 33px;
          height: 30px;
          margin-right: 15px;

          &.benzin {
            background: url('/images/nettogumi/extra_info_benzinkut.png') center center no-repeat;
            background-size: 100%;

          }

          &.idojaras {
            background: url('/images/nettogumi/extra_info_idojaras.png') center center no-repeat;
            background-size: 100%;

          }

          &.zajszint {
            background: url('/images/nettogumi/extra_info_zajszint.png') center center no-repeat;
            background-size: 100%;
            margin-right: -1px;

          }

        }

        .class {
          display: block;
          float: left;
          width: 30px;
          height: 30px;
          text-align: center;
          font-family: $roboto-bold;
          font-weight: normal;
          color: $white;
          line-height: 1.8em;
          text-transform: uppercase;
          position: relative;
          @include text-shadow(1px, 1px, 2px, rgba(0, 0, 0, .4));

          &::before {
            content: '';
            position: absolute;
            left: -25px;
            width: 0;
            height: 0;
            border-right: 10px solid transparent;
            border-top: 15px solid transparent;
            border-left: 15px solid transparent;
            border-bottom: 15px solid transparent;

          }

          &.class-a {
            background-color: $class-A;

            &::before {
              border-right-color: $class-A;

            }

          }

          &.class-b {
            background-color: $class-B;

            &::before {
              border-right-color: $class-B;

            }

          }

          &.class-c {
            background-color: $class-C;

            &::before {
              border-right-color: $class-C;

            }

          }

          &.class-d {
            background-color: $class-D;

            &::before {
              border-right-color: $class-D;

            }

          }

          &.class-e {
            background-color: $class-E;

            &::before {
              border-right-color: $class-E;

            }

          }

          &.class-f {
            background-color: $class-F;

            &::before {
              border-right-color: $class-F;

            }

          }

          &.class-g {
            background-color: $class-G;

            &::before {
              border-right-color: $class-G;

            }

          }

        }

        .zajszint-text {
          $font-family: $roboto-condensed-regular;
          font-weight: normal;
          @include font-size(16px);
          color: $black-1;
          margin-top: 3px;

        }

      }

      .star {
        font-family: $websymbol;
        @include font-size(17px);
        color: $yellow;
        display: block;
        margin-top: -1px;
        float: left;

        &.empty {
          color: $gray-12;

        }

      }

      .stars-wrapper {
        display: block;
        float: left;
        margin-top: 1px;
        width: 90px;
        height: 20px;
        position: relative;

        .stars-mask {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 3;
          width: 90px;
          height: 20px;
          background: url('/images/nettogumi/stars_mask_retina.png') no-repeat;
          background-size: 100%;

        }

        .yellow-bg {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 90px;
          height: 20px;
          background: $yellow;

        }

        .gray-bg {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          background: $gray-12;
          width: 90px;
          height: 20px;

        }

      }

      .why {
        font-family: $roboto-bold;
        font-weight: normal;
        @include font-size(17px);
        color: $gray-17;
        padding: 0 9px 0 17px;

      }

      #show-deatils {
        font-family: $roboto-bold;
        font-weight: normal;
        @include font-size(17px);
        color: $orange-1;
        @include transition(color .5s linear);

        &:hover {
          color: $gray-17;

        }

      }

    }

  }

  #rimTireContainer{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 25px;
    padding-top: 15px;
    text-align: center;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ecebeb+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(top, rgba(236,235,235,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(236,235,235,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(236,235,235,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecebeb', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

    .col-6{
      float: left;
      text-align: center;
    }

    a{
      color: #202020;
      text-decoration: none;

      img{
        height: 27px;
        margin-right: 5px;
        vertical-align: middle;
      }

      span{
        color: #ff470a;
        display: inline-block;
        vertical-align: middle;
        line-height: 27px;
        font-family: "RobotoBold";
        font-weight: normal;
        font-size: 18px;
      }
    }
  }

}

@include screen($null, $sm) {

  .visible-xs{
    display: block !important;
  }

  .hidden-xs{
    display: none !important;
  }
 
  .additional-pictures-container{
    display: none !important;
  }

  section.szettben-olcsobb{
    display: none !important;
  }

  .product-main-info-wrapper {
    margin: 40px 0 0;

    .container {
      padding: 0;
    }

    .product-thumbnail {

      .product-thumbnail-wrapper {

        .thumbnail {
          margin: 20px auto 0;

        }

        .gallery-preview{
          width: 180px !important;

          .text{

            &.mobile{
              display: block;
            }
          
            &.desktop{
              display: none;
            }
          }
        }

      }

    }

    .product-details {
      margin-top: 40px;

      .product-title {
        @include font-size(25px);

      }

      .product-size {
        @include font-size(18px);

      }

      .price-cart-wrapper {
        margin: 15px 0 0;

        .col-md-5 {
          width: 100%;

        }

        .col-md-6 {
          float: none;
          width: 100%;

        }


        .piece-price {
          padding: 0;

        }

        .button-wrapper {
          margin-top: 20px;

          .select-wrapper {
            margin-right: 20px;

            select {
              padding: 12px 32px 12px 16px;
              @include font-size(18px);

            }

            &::after {
              top: 21px;
              right: 10px;

            }

          }

          .product-button {
            margin: -3px auto 0;
            padding: 12px 42px 14px;
            @include font-size(17px);

            span {
              width: 31px;
              height: 30px;
              margin: 0 10px -8px 0;

            }

          }

        }

        .delivery-info {
          margin: 25px 0 0;
          position: relative;

          &::before {
            width: 40px;
            height: 32px;

          }

          .open-details {
            padding: 0 0 0 54px;
            @include font-size(14px);

            strong {
              line-height: 1.3em;

            }

          }

        }

      }

      .delivery-info-details {

        p {
          @include font-size(14px);

        }

      }

      .product-description {
        padding: 15px 10px 20px;
        margin-top: 20px;

        p {
          margin: 0 0 10px;
          @include font-size(16px);

        }

      }

      .details-wrapper {

        .display-table-row {

          .col-4  {
            padding: 10px 0 10px 10px;

            strong {
              @include font-size(15px);
              line-height: 1.2em;

            }

          }

          .col-8 {
            padding: 10px 0 10px 10px;

            &.with-image {

              img {
                max-width: 40%;

              }

            }

            p {
              @include font-size(14px);

            }

            #show-deatils {
              display: block;
              line-height: 1em;

            }

          }

        }

        .extra-info {
          width: 100%;

          .icon {
            width: 20px;
            height: 17px;
            margin-right: 15px;

            &.zajszint {
              margin-top: 3px;
              margin-right: 0;

            }

          }

          .class {
            width: 15px;
            height: 17px;
            @include font-size(12px);
            line-height: 1.4em;

            &::before {
              left: -25px;
              border-right: 9px solid transparent;
              border-top: 9px solid transparent;
              border-left: 16px solid transparent;
              border-bottom: 8px solid transparent;

            }

          }

          .zajszint-text {
            margin-top: 0;
            @include font-size(12px);

          }

        }

        .star {
          @include font-size(14px);

        }

        .stars-wrapper {
          margin-top: 0;

        }

        .why {
          @include font-size(13px);
          padding: 0 9px 0 17px;

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;

          }

        }

        #show-deatils {
          @include font-size(13px);

        }

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  .product-main-info-wrapper {
    margin: 40px 0 0;

    .container {
      padding: 0;

    }

    .product-thumbnail {
      width: 40%;
      float: left;

      .product-thumbnail-wrapper {

        .badge {
          width: 80px;
          height: 90px;

          &.only-text .badge-wrapper {
            padding: 22px 5px 0;

            small {
              @include font-size(12px);

            }

            strong {
              @include font-size(25px);

            }

          }

          &.with-icon .badge-wrapper {

            strong {
              @include font-size(12px);

            }

          }

        }

        .thumbnail {
          margin: 20px auto 0;

        }

      }

    }

    .product-details {
      width: 60%;
      float: left;
      padding-left: 0;

      .product-title {
        @include font-size(33px);
        line-height: 1.2em;

      }

      .product-size {
        @include font-size(19px);

      }

      .price-cart-wrapper {
        margin: 15px 0 0;

        .col-md-5 {
          width: 100%;

        }

        .col-md-6 {
          float: none;
          width: 100%;

        }

        .low-price-package {
          width: 30%;
          float: left;

          .col-6 {
            float: none;
            width: 100%;

          }

          .first {
            @include font-size(13px);
            padding: 2px 0;
            text-align: center;

            &::before {
              content: none;

            }

          }

          .last {
            @include font-size(16px);
            padding: 4px 10px;

            small {
              @include font-size(13px);

            }

          }

        }

        .price {
          width: 70%;
          float: left;
          position: relative;
          margin: 0;
          padding-left: 20px;

        }

        .piece-price {
          padding: 10px 0 0 150px;
          @include font-size(17px);

        }

        .button-wrapper {
          margin-top: 20px;

          .select-wrapper {
            margin-right: 5px;

            select {
              padding: 12px 22px 12px 16px;
              @include font-size(18px);

            }

            &::after {
              top: 21px;
              right: 10px;

            }

          }

          .product-button {
            margin: -3px auto 0;
            padding: 14px 22px;
            @include font-size(17px);

            span {
              width: 31px;
              height: 30px;
              margin: 0 10px -8px 0;

            }

          }

        }

        .delivery-info {
          margin: 25px 0 0;
          position: relative;

          &::before {
            width: 40px;
            height: 32px;

          }

          .open-details {
            padding: 0 0 0 54px;
            @include font-size(14px);

            strong {
              line-height: 1.3em;

            }

          }

        }

      }

      .delivery-info-details {

        p {
          @include font-size(14px);

        }

      }

      .product-description {
        padding: 15px 10px 20px;
        margin-top: 20px;

        p {
          margin: 0 0 10px;
          @include font-size(16px);

        }

      }

      .details-wrapper {

        .display-table-row {

          .col-4  {
            padding: 10px 0 10px 10px;

            strong {
              @include font-size(15px);
              line-height: 1.2em;

            }

          }

          .col-8 {
            padding: 10px 0 10px 10px;

            &.with-image {

              img {
                max-width: 40%;

              }

            }

            p {
              @include font-size(14px);

            }

          }

        }

        .extra-info {
          width: 83%;

          .icon {
            width: 28px;
            height: 25px;
            margin-right: 15px;

          }

          .class {
            width: 28px;
            height: 26px;
            line-height: 1.5em;

            &::before {
              left: -25px;
              border-right: 9px solid transparent;
              border-top: 13px solid transparent;
              border-left: 16px solid transparent;
              border-bottom: 13px solid transparent;

            }

          }

          .zajszint-text {
            margin-top: 1px;

          }

        }

        .star {
          @include font-size(14px);

        }

        .why {
          @include font-size(13px);
          padding: 0 9px 0 17px;

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;

          }

        }

        #show-deatils {
          @include font-size(13px);

        }

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {


  .product-main-info-wrapper {

    .product-details {

      .product-title {
        @include font-size(30px);
        line-height: 1.2em;

      }

      .price-cart-wrapper {

        .col-md-5 {
          padding-right: 0;

        }


        .price {
          padding-left: 15px;

          .price-text {
            margin-top: 5px;

          }

          .price-price {
            @include font-size(37px);

          }

        }

        .piece-price {
          padding: 10px 0 0 130px;

        }

        .delivery-info {
          margin: 25px 0 0;
          position: relative;

          &::before {
            width: 40px;
            height: 32px;

          }

          .open-details {
            padding: 0 0 0 54px;
            @include font-size(14px);

            strong {
              line-height: 1.3em;

            }

          }

        }

      }

      .details-wrapper {

        .extra-info {
          width: 80%;

          .icon {
            width: 20px;
            height: 17px;
            margin-right: 15px;

            &.zajszint {
              margin-top: 3px;
              margin-right: 0;

            }

          }

          .class {
            width: 15px;
            height: 17px;
            @include font-size(12px);
            line-height: 1.4em;

            &::before {
              left: -25px;
              border-right: 9px solid transparent;
              border-top: 9px solid transparent;
              border-left: 16px solid transparent;
              border-bottom: 8px solid transparent;

            }

          }

          .zajszint-text {
            margin-top: 0;
            @include font-size(12px);

          }

        }

        #show-deatils {
         display: block;
         line-height: 1em;

        }

      }

    }

    .delivery-info-details {

      p {
        @include font-size(14px);

      }

    }

  }

}

@include screen(($lg+1), $xl) {


  .product-main-info-wrapper {
    margin: 50px 0 0;

    .product-details {

      .product-title {
        @include font-size(40px);

      }

      .product-size {
        @include font-size(20px);

      }

      .price-cart-wrapper {

        .col-md-5 {
          width: 42%;

        }

        .low-price-package {

          .first {
            @include font-size(12px);

          }

          .last {
            @include font-size(15px);
            padding: 4px 10px 3px 15px;

            small {
              @include font-size(11px);

            }

          }

        }

        .price {

          .price-text {
            @include font-size(22px);
            margin-top: 2px;

            small {
              @include font-size(10px);

            }

          }

          .price-price {
            @include font-size(33px);

            small {
              @include font-size(22px);

            }

          }

        }

        .piece-price {
          padding: 10px 0 0;
          @include font-size(17px);

        }

        .button-wrapper {

          .select-wrapper {
            margin-right: 5px;

            select {
              padding: 12px 16px 12px 16px;
              @include font-size(18px);

            }

            &::after {
              top: 21px;
              right: 10px;

            }

          }

          .product-button {
            margin: -3px auto 0;
            padding: 13px 32px;
            @include font-size(17px);

            span {
              width: 31px;
              height: 30px;
              margin: 0 10px -8px 0;

            }

          }

        }

        .delivery-info {
          margin: 20px 0 0;

          &::before {
            width: 40px;
            height: 35px;

          }

          .open-details {
            padding: 0 0 0 54px;
            @include font-size(13px);
            line-height: 1.4em;

            strong {
              @include font-size(15px);

            }

          }



        }

      }

      .delivery-info-details {

        p {
          @include font-size(13px);

        }

      }

      .product-description {

        p {
          @include font-size(16px);

        }

      }

      .details-wrapper {

        .display-table-row {

          .col-4  {
            padding: 10px 0 10px 15px;

            strong {
              font-family: $roboto-bold;
              font-weight: normal;
              @include font-size(16px);
              color: $black-1;

            }

          }

          .col-8 {
            padding: 10px 0 10px 15px;

            p {
              @include font-size(16px);

            }

          }

        }

        .extra-info {
          width: 80%;

          .icon {
            width: 28px;
            height: 25px;
            margin-right: 15px;

          }

          .class {
            width: 28px;
            height: 26px;
            line-height: 1.5em;

            &::before {
              left: -25px;
              border-right: 10px solid transparent;
              border-top: 13px solid transparent;
              border-left: 16px solid transparent;
              border-bottom: 13px solid transparent;

            }

          }

          .zajszint-text {
            margin-top: 2px;

          }

        }

        .star {
          @include font-size(16px);

        }

        .why {
          @include font-size(13px);
          padding: 0 5px 0 5px;

        }

        #show-deatils {
          @include font-size(13px);

        }

      }

    }

  }

}

@include screen(($xl+1), $xxl) {
 
  .product-main-info-wrapper {

    .product-details {

      .product-title {
        @include font-size(50px);

      }

      .price-cart-wrapper {

        .col-md-5 {
          padding-right: 0;

        }

        .price {

          .price-text {
            margin-top: 8px;

          }

          .price-price {
            @include font-size(42px);

          }

        }

        .button-wrapper {

          .select-wrapper {

            select {
              padding: 17px 20px 17px 20px;

            }

          }

        }

      }

      .details-wrapper {

        .display-table-row {

          .col-8 {

            p {
              @include font-size(16px);

            }

          }

        }


        .why {
          @include font-size(16px);
          padding: 0 9px 0 15px;

        }

        #show-deatils {
          @include font-size(16px);

        }

      }

    }

  }

}
