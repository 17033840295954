header {

  &.with-bottom-shadow {

    .header-bottom {
      box-shadow: 0 7px 18px rgba(0, 0, 0, .2);
      -moz-box-shadow: 0 7px 18px rgba(0, 0, 0, .2);
      -webkit-box-shadow: 0 7px 18px rgba(0, 0, 0, .2);
      -o-box-shadow: 0 7px 18px rgba(0, 0, 0, .2);

    }

  }

  .header-top {
    background-color: $gray-6;
    padding: 15px 0;

    .contact-info {
      text-align: left;

      span {
        display: inline-block;
        font-family: $roboto-bold;
        font-weight: normal;
        color: $white;
        @include font-size(17px);
        position: relative;

        a {
          color: $white;
          @include transition(color .3s ease);

          &:hover {
            color: $yellow;

          }

        }

        &.tel {
          margin-left: 40px;
          margin-right: 70px;

          &::before {
            content: '';
            position: absolute;
            top: 3px;
            left: -28px;
            width: 18px;
            height: 18px;
            background: url('/images/nettogumi/yellow_phone_icon.png') no-repeat;
            background-size: 100%;

          }

        }

        &.envelop {

          &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: -35px;
            width: 22px;
            height: 14px;
            background: url('/images/nettogumi/yellow_envelop_icon.png') no-repeat;
            background-size: 100%;

          }

        }

      }

    }

    .header-top-menu {
      text-align: right;

      span {
        display: inline-block;
        font-family: $roboto-bold;
        font-weight: normal;
        color: $white;
        @include font-size(17px);
        position: relative;

        a {
          color: $white;
          @include transition(color .3s ease);

          &:hover {
            color: $yellow;

          }

        }

        &.nyomkovetes {
          margin-right: 70px;

          &::before {
            content: '';
            position: absolute;
            top: 2px;
            left: -37px;
            width: 30px;
            height: 29px;
            background: url('/images/nettogumi/yellow_nyomonkovetes_icon.png') no-repeat;
            background-size: 100%;

          }

        }

        &.contact-icon {

          &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: -30px;
            width: 20px;
            height: 18px;
            background: url('/images/nettogumi/yellow_comment_icon.png') no-repeat;
            background-size: 100%;

          }

        }

      }

    }

  }

  .header-bottom {
     overflow: hidden;
     padding-bottom: 28px;

    .header-logo {
      width: 351px;
      border-top: 4px solid $yellow;

      img {
        display: block;
        margin: 31px auto 0;

        &.logo-mobile{
          display: none;
        }

      }

    }

    .header-menu {
      margin: 60px 60px 0 50px;

    }

    .main-menu {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        list-style: none;
        margin: 0 25px;

        &:first-child {
          margin-left: 0;

        }

        &:last-child {
          margin-right: 0;

        }

        a {
          font-family: $roboto-condensed-regular;
          text-transform: lowercase;
          color: $black-1;
          @include font-size(21px);
          @include transition(color .3s ease);

          &:hover {
            color: $yellow;

          }

        }

        &.current {

          a {
            color: $yellow;

          }

        }
        .placeholder{
          width:350px;
        }

      }

    }

    .mobile-navigation {
      display: none;
      float: right;
      margin: 27px 0 0 25px;

        .hamburger-menu {
          margin-right: 17px;

          span {
            width: 30px;
            height: 5px;
            margin-left: 10px;
            background: $black-1;
            margin-bottom: 5px;
            display: block;

            &:last-child {
              margin-bottom: 0;

            }

          }

        }

    }

    .cart-wrapper {
      margin: 28px 0 0;

      .cart-outer-circle {
        width: 92px;
        height: 92px;
        @include border-radius(50%);
        background-color: $yellow;
        position: relative;

        a {
          display: block;

        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 43px;
          height: 92px;
          width: 1000%;
          background-color: $yellow;
          z-index: -1;

        }

        .inner-circle {
          width: 65px;
          height: 65px;
          border: 3px solid $white;
          @include border-radius(50%);
          margin-top: (92px - 65px) / 2;
          margin-left: (92px - 65px) / 2;
          background: url('/images/nettogumi/cart_big_head.png') center center no-repeat;
          background-size: 100%;
          position: relative;

          &:hover {
            border-color: $gray-6;

          }

          .count-product {
            position: absolute;
            top: -9px;
            left: 50%;
            transform: translateX(-50%);
            font-family: $roboto-bold;
            font-weight: normal;
            @include font-size(15px);
            color: $white;
            text-align: center;
            background-color: $gray-6;
            min-width: 24px;
            max-width: 35px;
            height: 24px;
            @include border-radius(50%);

          }

        }

      }

      .cart-text {
        margin-top: 24px;
        margin-right: -1px;

        a {
          @include font-size(16px);
          color: $black-1;
          line-height: 1.2em;

          strong {
            display: block;
            font-family: $roboto-bold;
            font-weight: normal;
            color: $white;
            @include font-size(18px);

          }

          &:hover strong {
            color: $gray-6;

          }

        }

      }

    }

  }

}

.mobile-menu-wrapper {
  display: none;
  background-color: $yellow;

  .mobile-menu {
    margin: 0;
    padding: 0;

    hr{
      background: #666;
      width: 90%;
      margin-bottom: 1em;
      margin-top: 1em;
    }

    li {
      list-style: none;
      display: block;
      text-align: center;
      padding: 10px 20px;

      &:first-child {
        padding-top: 24px;

      }

      &:last-child {
        padding-bottom: 24px;

      }

      a {
        font-family: $roboto-condensed-regular;
        text-transform: lowercase;
        color: $black-1;
        @include font-size(21px);
        @include transition(color .3s ease);

        &:hover {
          color: $white;

        }

      }

      &.current {

        a {
          color: $white;

        }

      }

    }

  }

}

@include screen($null, $xs) {

  header {

    .header-bottom {

      .mobile-navigation {
        margin: 27px 0 0 15px;

      }

    }

  }

}

@include screen(($xs+1), $sm) {

  header {

    .header-bottom {

      .mobile-navigation {
        margin: 27px 0 0 35px;

      }

    }

  }

}

@include screen($null, $sm) {

  header {

    .header-top {
      padding: 5px 0;

      .contact-info {

        span {
          @include font-size(13px);

          &.tel {
            margin-left: 5px;
            margin-right: 40px;

            &::before {
              top: 5px;
              left: -18px;
              width: 12px;
              height: 12px;

            }

          }

          &.envelop {

            &::before {
              top: 6px;
              left: -25px;
              width: 20px;
              height: 12px;

            }

          }

        }

      }

      .header-top-menu {
        display: none;

      }

    }

    .header-bottom {
       padding-bottom: 16px;

      .header-logo {
        padding-top: 5px;
        width: 160px;
        border-top: 2px solid $yellow;

        img {
          margin: 15px auto 0;

          &.logo-mobile{
            display: block;
            margin-top: 0px;
          }

          &.logo-desktop{
            display: none;
          }
        }

      }

      .header-menu {
        display: none;

      }

      .mobile-navigation {
        display: block;

      }

      .header-cart {
        float: right;
        width: 20%;

      }

      .cart-wrapper {
        margin: 12px 0 0;

        .cart-outer-circle {
          width: 52px;
          height: 52px;

          &::after {
            left: 26px;
            height: 52px;

          }

          .inner-circle {
            width: 35px;
            height: 35px;
            border: 2px solid $white;
            margin-top: (52px - 35px) / 2;
            margin-left: (52px - 35px) / 2;

            .count-product {
              top: -9px;
              @include font-size(11px);
              min-width: 20px;
              max-width: 30px;
              height: 20px;
              line-height: 1.5em;

            }

          }

        }

        .cart-text {
          display: none;

        }

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  header {

    .header-top {
      padding: 10px 0;

      .contact-info {

        span {
          @include font-size(14px);

          &.tel {
            margin-left: 20px;

            &::before {
              top: 4px;
              left: -25px;
              width: 14px;
              height: 14px;

            }

          }

          &.envelop {

            &::before {
              top: 5px;
              left: -30px;
              width: 20px;
              height: 12px;

            }

          }

        }

      }

      .header-top-menu {
        display: none;

      }

    }

    .header-bottom {
       padding-bottom: 20px;

      .header-logo {
        width: 260px;

        img {
          margin: 31px auto 0;

        }

      }

      .header-menu {
        display: none;

      }

      .mobile-navigation {
        display: block;
        width: 31%;
        margin: 42px 0 0 0;

        .hamburger-menu {
          margin-right: 30px;
          float: right;

          span {
            width: 40px;
            height: 7px;

          }

        }

      }

      .header-cart {
        float: right;
        width: 30%;

      }

      .cart-wrapper {
        margin: 10px 0 0;

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  header {

    .header-bottom {

      .header-logo {
        width: 260px;

        img {
          margin: 31px auto 0;

        }

      }

      .mobile-navigation {
        display: block;
        width: 31%;
        margin: 42px 0 0 0;

          .hamburger-menu {
            margin-right: 30px;
            float: right;

            span {
              width: 40px;
              height: 7px;

            }

          }

      }

      .header-cart {
        width: 20%;

      }

      .cart-wrapper {
        margin: 10px 0 0;

        .cart-text {
          display: none;

        }

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  header {

    .header-top {
      background-color: $gray-6;
      padding: 15px 0;

      .contact-info {

        span {
          @include font-size(16px);

        }

      }

      .header-top-menu {

        span {
          @include font-size(16px);

        }

      }

    }

    .header-bottom {
       padding-bottom: 20px;

      .header-logo {
        width: 200px;

      }

      .header-menu {
        margin: 40px 40px 0 40px;

      }

      .main-menu {

        li {
          margin: 0 20px;


          a {
            @include font-size(19px);

          }

        }

      }

      .cart-wrapper {
        margin: 18px 0 0;

        .cart-outer-circle {
          width: 74px;
          height: 74px;

          &::after {
            left: 37px;
            height: 74px;

          }

          .inner-circle {
            width: 50px;
            height: 50px;
            border: 2px solid $white;
            margin-top: (74px - 50px) / 2;
            margin-left: (74px - 50px) / 2;

          }

        }

        .cart-text {
          margin-top: 15px;

          a {
            @include font-size(15px);

            strong {
              @include font-size(17px);

            }

          }

        }

      }

    }

  }

}

@include screen(($xl+1), $xxl) {

  header {

    .header-bottom {

      .header-logo {
        width: 300px;

      }

    }

  }

}

