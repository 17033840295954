.cart-data-box{
    .radio-button-wrapper{
        position: relative;

        .tooltip{
            position: absolute;
            top: -33px;
            right: 106%;
            background: #ffa402;
            border-radius: 5px;
            z-index: 999;
            width: 500px;
            display: flex;
            padding: 0px 10px;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
            display: none;

            &:after{
                left: 100%;
                top: 46px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 164, 2, 0);
                border-left-color: #ffa402;
                border-width: 13px;
                margin-top: -13px;
            }

            .left{
                padding: 15px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img{
                    max-width: 100px;
                    max-height: 130px;
                }

                .tooltip-name{
                    font-weight: bolder;
                    font-family: "RobotoCondensedBold";
                    font-size: 1em;
                    line-height: 1em;
                    display: block;
                }

                .tooltip-name-subtitle{
                    font-weight: 100;
                    font-size: 0.9em;
                    display: block;
                }
            }

            .right{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 15px;
            

                .tooltip-title{
                    margin-bottom: 8px;
                    font-family: "RobotoCondensedBold";
                    font-size: 23px;
                    margin-top: 0px;
                }

                .tooltip-content{
                    margin-top: 5px;
                    font-size: 16px;
                    margin-bottom: 0px;
                    line-height: 1.4em;
                }
            }
        }
    }
}