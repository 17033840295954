.product-test {
  margin: 50px 0 0;

  .col-md-7 {
    padding-right: 0;

  }

  .text-container {
    padding: 15px 20px 0 30px;

    .tester-logo {
      display: inline-block;
      line-height: 0;

      img {
        line-height: 0;

      }

    }

    .title {
      margin: 10px 0 20px;
      padding: 0;
      font-family: $poppins-medium;
      font-weight: normal;
      @include font-size(37px);
      line-height: 1.2em;
      color: $black-1;

    }

    p {
      margin: 0 0 15px;
      padding: 0;
      @include font-size(17px);
      color: $black-1;
      line-height: 1.5em;

      &:last-child {
        margin-bottom: 0;

      }

    }

  }

}

@include screen($null, $lg) {

  .product-test {
    margin: 40px 0 0;

    .col-md-7,
    .col-md-5 {
      padding: 0;

    }

    .text-container {
      padding: 30px 20px 0;

      .tester-logo {

        img {
          max-width: 60%;

        }

      }

      .title {
        margin: 5px 0 10px;
        @include font-size(30px);

      }

      p {
        margin: 0 0 10px;
        @include font-size(16px);
        line-height: 1.4em;

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .product-test {
    margin: 40px 0 0;

    .text-container {
      padding: 10px 10px 0 20px;

      .tester-logo {

        img {
          max-width: 60%;

        }

      }

      .title {
        margin: 5px 0 10px;
        @include font-size(30px);

      }

      p {
        margin: 0 0 10px;
        @include font-size(16px);
        line-height: 1.4em;

      }

    }

  }

}
