.mfp-addedcart {

  .hide-on-mobile {
    display: block;

  }

  .show-on-mobile {
    display: none;

  }

  .mfp-content {
    max-width: 1222px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    background: $white;

    .mfp-popup-title-wrapper {
      text-align: center;
      padding: 40px 0 26px;
      border-bottom: 1px solid $gray-11;

      .title {
        font-family: $poppins-medium;
        font-weight: normal;
        @include font-size(48px);
        line-height: 1em;
        color: $gray-3;
        margin: 0;
        padding: 0;

      }

      .text {
        @include font-size(17px);
        color: #808080;
        line-height: 1.2em;
        margin: 0;
        padding: 0;

        strong {
          font-family: $roboto-bold;
          font-weight: normal;

        }

      }

    }

    .mfp-popup-content-wrapper {
      padding: 0 50px;

      .cart-items {
        padding-bottom: 20px;

        @include clearfix();

        .product-image {
          position: relative;

          .count-rpoduct {
            font-family: $roboto-bold;
            font-weight: normal;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include font-size(24px);
            color: $black-1;
            background: $yellow;
            @include border-radius(50%);
            padding: 15px;

          }

        }

        .text-container {
          margin: 30px 0 0 30px;

          .title {
            margin: 0;
            padding: 0;
            font-family: $poppins-medium;
            font-weight: normal;
            @include font-size(27px);
            color: $gray-16;
            line-height: 1.3em;

          }

          .price-cart-container {
            margin-top: 22px;

            @include clearfix();

            .low-price-package {
              width: 147px;

              .first {
                font-family: $roboto-condensed-bold;
                font-weight: normal;
                @include font-size(16px);
                color: $orange-1;
                background-color: $white;
                padding: 5px 10px;
                border: 1px solid $orange-1;
                text-align: center;

              }

              .last {
                font-family: $roboto-condensed-bold;
                font-weight: normal;
                @include font-size(19px);
                color: $white;
                padding: 7px 10px;
                text-align: center;
                background-color: $orange-1;

                small {
                  @include font-size(16px);

                }

              }

            }

            .price-cont {
              margin-left: 34px;

              .price {
                margin: -5px 0 0;

                @include clearfix();

                .price-text {
                  float: left;
                  font-family: $roboto-condensed-bold;
                  font-weight: normal;
                  @include font-size(24px);
                  color: $black-1;
                  line-height: 1.1em;
                  margin-top: 10px;
                  margin-right: 10px;

                  small {
                    display: block;
                    font-family: $roboto-bold;
                    font-weight: normal;
                    text-transform: uppercase;
                    @include font-size(11px);
                    line-height: .9em;

                  }

                }

                .price-price {
                  float: left;
                  font-family: $roboto-condensed-bold;
                  font-weight: normal;
                  @include font-size(44px);
                  color: $orange-1;
                  line-height: 1.1em;

                  small {
                    @include font-size(29px);

                  }

                }

              }

              .piece-price {
                display: block;
                padding: 3px 0 0;
                font-family: $roboto-bold;
                font-weight: normal;
                @include font-size(20px);
                color: $gray;
                
                span{
                  text-decoration: line-through;
                }

                &.hidden{
                  display: none;
                }

              }

            }

            .piece-button {
              margin-left: 33px;

              .button-wrapper {
                @include clearfix();

                .success-change-number{
                  display: inline-block;
                  background-color: $green;
                  margin-left: 10px;
                  padding: 23px 14px;
                  margin-top: -4px;
                  border-radius: 10px;
                  color: $white;
                  font-family: "WebSymbolsRegular";
                  float: right;
                }

                .select-wrapper {
                  position: relative;
                  margin-top: 0;
                  margin-right: 10px;

                  select {
                    padding: 17px 40px 17px 20px;
                    background-color: $gray-2;
                    border: 1px solid $gray-15;
                    @include border-radius(0);
                    width: 100%;
                    color: $gray-16;
                    @include font-size(21px);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                  }

                  &::after {
                    content: '';
                    height: 9px;
                    width: 9px;
                    background: url('/images/nettogumi/arrow_down.png') no-repeat;
pointer-events: none;
                    background-size: 100%;
                    position: absolute;
                    top: 25px;
                    right: 15px;
                    z-index: 1;

                  }

                }

                .product-button {
                  display: inline-block;
                  margin: -4px auto 0;
                  padding: 20px 55px;
                  font-family: $roboto-bold;
                  font-weight: normal;
                  @include font-size(19px);
                  color: $black-1;
                  @include transition(background .3s ease);
                  background-color: $yellow;
                  text-transform: uppercase;
                  @include border-radius(12px);

                  span {
                    display: inline-block;
                    width: 38px;
                    height: 30px;
                    background: url('/images/nettogumi/cart_icon_btn.png') center center no-repeat;
                    background-size: 100%;
                    margin: 0 10px -5px 0;

                  }

                  &:hover {
                    color: $white;
                    background-color: $black-1;

                  }


                }

              }

            }

          }

        }

      }

    }

    .mfp-popup-upsell-wrapper {
      padding: 25px 50px 44px;
      border-top: 1px solid $gray-11;
      background-color: $gray-20;
      text-align: center;

      .title {
        font-family: $poppins-medium;
        font-weight: normal;
        @include font-size(27px);
        line-height: 1em;
        color: $gray-3;
        margin: 0;
        padding: 0;

      }

      .mfp-popup-upsell-products {
        margin-top: 40px;

        @include clearfix();

        .col-4 {
          //float: left;
          display: inline-block;
          width: 33%;
        }

        .upsell-item {
          text-align: left;

          @include clearfix();

          .thumbnail {
            float: left;
            width: 38%;

          }

          .description {
            float: left;
            width: 62%;
            padding: 15px 10px 0 20px;

            .title {
              font-weight: normal;
              @include font-size(20px);
              line-height: 1.3em;
              color: $gray-3;
              margin: 0;
              padding: 0;

            }

            strong  {
              display: block;
              font-family: $roboto-bold;
              font-weight: normal;
              @include font-size(21px);
              line-height: 1em;
              color: $gray-3;
              margin: 4px 0 0;
              padding: 0;

            }

            .button-wrapper {
              margin-top: 21px;

              @include clearfix();

              .piece {
                display: block;
                padding: 10px 21px;
                background-color: $gray-2;
                border: 1px solid $gray-15;
                @include border-radius(0);
                color: $gray;
                @include font-size(16px);
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

              }

              .upsell-button {
                display: block;
                text-indent: -999999px;
                padding: 11px 36px;
                margin: 0 0 0 -3px;
                @include border-radius(5px);
                background: $gray-16 url('/images/nettogumi/upsell_btn_bg.png') no-repeat center center;
                @include transition(background .3s ease);

                &:hover {
                  background: $orange-1 url('/images/nettogumi/upsell_btn_bg.png') no-repeat center center;

                }

              }

            }

            .added-alert-text {
              margin-top: 21px;

              p {
                margin: 0;
                padding: 0;
                @include font-size(16px);
                color: $gray-3;
                line-height: 1.4em;

                span {
                  font-family: $roboto-bold;
                  font-weight: normal;
                  display: block;
                  color: $orange-1;
                  margin: 0;
                  padding: 0;
                  cursor: pointer;
                  @include transition(color .3s ease);

                  i {
                    font-family: $websymbol;
                    font-weight: normal;
                    font-style: normal;

                  }

                  &:hover {
                    color: $gray-3;

                  }

                }

              }

            }

          }

        }

      }

    }

    .mfp-popup-bottom-wrapper {
      padding: 22px 50px;
      background-color: $yellow;

      @include clearfix();

      .col-6 {
        float: left;

      }

      .return-shop {

        .return-shop-btn {
          @include font-size(17px);
          color: $gray-3;
          @include transition(color .3s ease);

          &:hover {
            color: $white;

          }

        }

      }

      .goto-cartpage {
        text-align: right;

        .go-cartpage {
          font-family: $roboto-bold;
          font-weight: normal;
          @include font-size(19px);
          color: $gray-3;
          text-transform: uppercase;
          @include transition(color .3s ease);
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: -3px;
            left: -48px;
            width: 38px;
            height: 30px;
            background: url('/images/nettogumi/cart_icon_btn.png') center center no-repeat;
            background-size: 100%;

          }

          &:hover {
            color: $white;

          }

        }

      }

    }

  }

}

@include screen($null, $sm) {

  .mfp-addedcart {

    .hide-on-mobile {
      display: none;

    }

    .show-on-mobile {
      display: block;

    }

    .mfp-content {
      max-width: 95%;

      .mfp-popup-title-wrapper {
        padding: 20px 30px;

        .title {
          @include font-size(25px);

        }

        .text {
          @include font-size(12px);
          margin: 6px 0 0;
        }

      }

      .mfp-popup-content-wrapper {
        padding: 0 15px;

        .cart-items {
          padding-bottom: 15px;

          .col-10 {
            width: 100%;

          }

          .product-image {
            display: none;

          }

          .text-container {
            margin: 10px 0 0 0;

            .title {
              @include font-size(17px);

            }

            .price-cart-container {
              margin-top: 5px;

              .low-price-package {
                float: left;
                width: 40%;
                margin-bottom: 0;

                .first {
                  @include font-size(14px);
                  padding: 3px 10px;
                  line-height: 1.2em;

                }

                .last {
                  @include font-size(14px);
                  padding: 4px 10px 4px;
                  line-height: 1.2em;

                  small {
                    @include font-size(12px);

                  }

                }

              }

              .price-cont {
                margin: 0;
                float: left;
                width: 60%;
                padding-left: 20px;

                .price {
                  margin: 0;

                  .price-text {
                    @include font-size(14px);
                    margin-top: 0;
                    margin-right: 0;

                    small {
                      display: inline-block;
                      text-transform: none;
                      @include font-size(14px);
                      margin-right: 3px;

                    }

                  }

                  .price-price {
                    @include font-size(30px);

                    small {
                      @include font-size(19px);

                    }

                  }

                }

                .piece-price {
                  display: none;

                }

              }

              .piece-button {
                margin-top: 5px;
                margin-left: 0;
                width: 100%;

                .success-change-number{
                  padding: 12px 14px !important;
                  margin-top: -2px !important;
                  float: right;
                }

                .button-wrapper {
                  width: 100%;

                  .select-wrapper {
                    margin-right: 10px;

                    select {
                      padding: 8px 32px 8px 16px;
                      @include font-size(18px);

                    }

                    &::after {
                      top: 17px;
                      right: 10px;

                    }

                  }

                  .product-button {
                    float: left;
                    margin: -1px auto 0;
                    padding: 8px 8px 10px;
                    @include font-size(17px);

                    span {
                      width: 31px;
                      height: 30px;
                      margin: 0 10px -8px 0;

                    }

                  }

                }

              }

            }

          }

        }

      }

      .mfp-popup-upsell-wrapper {
        padding: 10px 15px 0;

        .title {
          @include font-size(18px);

        }

        .mfp-popup-upsell-products {
          margin-top: 20px;

          .col-4 {
            width: 100%;

          }

          .upsell-item {
            display: none;

            &:nth-child(1) {
              display: block;
              margin-bottom: 15px;

            }

            .thumbnail {
              display: block;

            }

            .description {
              width: 62%;
              padding: 10px 0 0 20px;

              .mobil-helper,
              .button-wrapper,
              .added-alert-text {
                width: 100%;
                float: none;

              }

              .button-wrapper,
              .added-alert-text {
                padding-top: 20px;
                padding-left: 0;

              }

              .title {
                @include font-size(15px);

              }

              strong  {
                @include font-size(16px);
                margin: 2px 0 0;

              }

              .button-wrapper {
                margin-top: 0;

                .piece {
                  padding: 8px 18px;
                  @include font-size(14px);

                }

                .upsell-button {
                  padding: 9px 30px;
                  margin: 0 0 0 -6px;

                }

              }

              .added-alert-text {
                margin-top: 0;

                p {
                  @include font-size(14px);

                }

              }

            }

          }

        }

      }

      .mfp-popup-bottom-wrapper {
        padding: 10px 15px;


        .return-shop {
          width: 40%;

          .return-shop-btn {
            @include font-size(14px);

          }

        }

        .goto-cartpage {
          width: 60%;

          .go-cartpage {
            @include font-size(14px);

            &::before {
              content: none;

            }

          }

        }

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  .mfp-addedcart {

    .mfp-content {
      max-width: 95%;

      .mfp-popup-title-wrapper {
        padding: 25px 0 20px;

        .title {
          @include font-size(30px);

        }

        .text {
          @include font-size(15px);

        }

      }

      .mfp-popup-content-wrapper {
        padding: 0 20px;

        .cart-items {
          padding-bottom: 25px;

          .col-10 {
            width: 100%;

          }

          .product-image {
            display: none;

          }

          .text-container {
            margin: 20px 0 0;

            .title {
              @include font-size(24px);

            }

            .price-cart-container {
              margin-top: 15px;

              .low-price-package {
                width: 125px;

                .first {
                  @include font-size(14px);

                }

                .last {
                  @include font-size(16px);

                  small {
                    @include font-size(13px);

                  }

                }

              }

              .price-cont {
                margin-top: 5px;
                margin-left: 20px;

                .price {

                  .price-text {
                    @include font-size(22px);
                    margin-top: 2px;

                    small {
                      @include font-size(10px);

                    }

                  }

                  .price-price {
                    @include font-size(33px);

                    small {
                      @include font-size(22px);

                    }

                  }

                }

                .piece-price {
                  padding: 10px 0 0;
                  @include font-size(16px);

                }

              }

              .piece-button {
                margin-left: 15px;

                .button-wrapper {
                  .success-change-number{
                    margin-top:-3px !important;
                    padding: 15px 14px !important;
                  }
                  .select-wrapper {
                    margin-right: 5px;

                    select {
                      padding: 12px 32px 12px 16px;
                      @include font-size(18px);

                    }

                    &::after {
                      top: 21px;
                      right: 10px;

                    }

                  }

                  .product-button {
                    margin: -3px auto 0;
                    padding: 13px 42px;
                    @include font-size(17px);

                    span {
                      width: 31px;
                      height: 30px;
                      margin: 0 10px -8px 0;

                    }

                  }

                }

              }

            }

          }

        }

      }

      .mfp-popup-upsell-wrapper {
        padding: 20px 20px 30px;

        .title {
          @include font-size(20px);

        }

        .mfp-popup-upsell-products {
          margin-top: 25px;

          .upsell-item {
            text-align: center;

            .thumbnail {
              float: none;
              width: 100%;

              img {
                display: block;
                margin: 0 auto;
                max-width: 35%;

              }

            }

            .description {
              float: none;
              width: 100%;
              padding: 15px 0 0;
              text-align: center;

              .title {
                @include font-size(15px);

              }

              strong  {
                @include font-size(16px);
                margin: 2px 0 0;

              }

              .button-wrapper {
                margin-top: 15px;
                text-align: center;

                .piece {
                  display: inline-block;
                  float: none;
                  padding: 8px 18px;
                  @include font-size(14px);

                }

                .upsell-button {
                  float: none;
                  display: inline-block;
                  padding: 9px 30px;
                  margin: 0 0 0 -6px;

                }

              }

              .added-alert-text {
                margin-top: 15px;

                p {
                  @include font-size(14px);

                }

              }

            }

          }

        }

      }

      .mfp-popup-bottom-wrapper {
        padding: 15px 20px;

        .return-shop {

          .return-shop-btn {
            @include font-size(15px);

          }

        }

        .goto-cartpage {

          .go-cartpage {
            @include font-size(15px);

            &::before {
              top: -2px;
              left: -41px;
              width: 30px;
              height: 23px;

            }

          }

        }

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  body {
    overflow: auto!important;

  }

  .mfp-addedcart {

    .mfp-content {

      .mfp-popup-title-wrapper {
        padding: 25px 20px 20px;

        .text {
          margin-top: 5px;
          @include font-size(14px);

        }

      }

      .mfp-popup-content-wrapper {

        .cart-items {
          padding-bottom: 10px;

          .text-container {

            .price-cart-container {
              margin-top: 10px;

              .low-price-package {
                float: none;
                width: 100%;
                margin-bottom: 10px;

                @include clearfix();

                .first {
                  float: left;
                  width: 50%;
                  @include font-size(14px);
                  padding: 3px 10px;

                }

                .last {
                  float: left;
                  width: 50%;
                  @include font-size(16px);
                  padding: 4px 10px 3px;

                  small {
                    @include font-size(14px);

                  }

                }

              }

              .price-cont {
                margin-left: 0;

                .piece-price {
                  padding: 0;

                }

              }

              .piece-button {
                float: right;

              }

            }

          }

        }

      }

      .mfp-popup-upsell-wrapper {

        .mfp-popup-upsell-products {
          margin-top: 25px;

          .upsell-item {

            display: none;

            &:nth-child(1),
            &:nth-child(2) {
              display: block;
              width: 50%;
              margin-bottom: 0;

            }

            .thumbnail {
              float: left;
              width: 32%;

              img {
                display: block;
                margin: 0 auto;
                max-width: 100%;

              }

            }

            .description {
              float: left;
              width: 62%;
              padding: 0 0 0 15px;
              text-align: left;

              .mobil-helper,
              .button-wrapper,
              .added-alert-text {
                width: 100%;
                float: none;

              }

              .button-wrapper,
              .added-alert-text {
                padding-top: 15px;
                padding-left: 0;

              }

              .title {
                @include font-size(15px);

              }

              strong  {
                @include font-size(16px);
                margin: 2px 0 0;

              }

              .button-wrapper {
                margin-top: 0;
                text-align: left;

                .piece {
                  padding: 8px 18px;
                  @include font-size(14px);

                }

                .upsell-button {
                  padding: 9px 30px;
                  margin: 0 0 0 -6px;

                }

              }

              .added-alert-text {
                margin-top: 0;

                p {
                  @include font-size(14px);

                }

              }

            }

          }

        }

      }

      .mfp-popup-bottom-wrapper {
        padding: 15px 20px;

        .return-shop {

          .return-shop-btn {
            @include font-size(15px);

          }

        }

        .goto-cartpage {

          .go-cartpage {
            @include font-size(15px);

            &::before {
              top: -2px;
              left: -41px;
              width: 30px;
              height: 23px;

            }

          }

        }

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .mfp-addedcart {

    .mfp-content {
      max-width: 90%;

      .mfp-popup-title-wrapper {
        padding: 30px 0 20px;

        .title {
          @include font-size(35px);

        }

        .text {
          @include font-size(16px);

        }

      }

      .mfp-popup-content-wrapper {
        padding: 0 25px;

        .cart-items {
          padding-bottom: 20px;

          .text-container {
            margin: 20px 0 0 20px;

            .title {
              @include font-size(24px);

            }

            .price-cart-container {
              margin-top: 15px;

              .low-price-package {
                width: 125px;

                .first {
                  @include font-size(14px);

                }

                .last {
                  @include font-size(16px);

                  small {
                    @include font-size(13px);

                  }

                }

              }

              .price-cont {
                margin-top: 2px;
                margin-left: 20px;

                .price {

                  .price-text {
                    @include font-size(22px);
                    margin-top: 2px;

                    small {
                      @include font-size(10px);

                    }

                  }

                  .price-price {
                    @include font-size(33px);

                    small {
                      @include font-size(22px);

                    }

                  }

                }

                .piece-price {
                  padding: 10px 0 0;
                  @include font-size(16px);

                }

              }

              .piece-button {
                margin-left: 15px;

                .button-wrapper {

                  .select-wrapper {
                    margin-right: 5px;

                    select {
                      padding: 12px 32px 12px 16px;
                      @include font-size(18px);

                    }

                    &::after {
                      top: 21px;
                      right: 10px;

                    }

                  }
                  .success-change-number{
                    padding: 15px 14px;
                    margin-top: -2px;
                  }
                  .product-button {
                    margin: -3px auto 0;
                    padding: 13px 42px;
                    @include font-size(17px);

                    span {
                      width: 31px;
                      height: 30px;
                      margin: 0 10px -8px 0;

                    }

                  }

                }

              }

            }

          }

        }

      }

      .mfp-popup-upsell-wrapper {
        padding: 25px 25px 30px;

        .title {
          @include font-size(20px);

        }

        .mfp-popup-upsell-products {
          margin-top: 20px;

          .upsell-item {

            .thumbnail {
              float: left;
              width: 38%;

            }

            .description {
              float: left;
              width: 62%;
              padding: 10px 10px 0 10px;

              .title {
                @include font-size(16px);

              }

              strong  {
                @include font-size(18px);
                margin: 3px 0 0;

              }

              .button-wrapper {
                margin-top: 15px;

                .piece {
                  padding: 8px 18px;
                  @include font-size(14px);

                }

                .upsell-button {
                  padding: 9px 30px;

                }

              }

              .added-alert-text {
                margin-top: 15px;

                p {
                  @include font-size(15px);

                }

              }

            }

          }

        }

      }

      .mfp-popup-bottom-wrapper {
        padding: 20px 25px;

        .return-shop {

          .return-shop-btn {
            @include font-size(16px);

          }

        }

        .goto-cartpage {

          .go-cartpage {
            @include font-size(17px);

            &::before {
              top: -2px;
              left: -43px;
              width: 35px;
              height: 27px;

            }

          }

        }

      }

    }

  }

}

@include screen(($xl+1), $xxxl) {

  .mfp-addedcart {

    .mfp-content {
      max-width: 1100px;

      .mfp-popup-title-wrapper {
        padding: 30px 0 20px;

        .title {
          @include font-size(35px);

        }

        .text {
          @include font-size(16px);

        }

      }

      .mfp-popup-content-wrapper {
        padding: 0 25px;

        .cart-items {
          padding-bottom: 20px;

          .text-container {
            margin: 20px 0 0 20px;

            .title {
              @include font-size(24px);

            }

            .price-cart-container {
              margin-top: 15px;

              .low-price-package {
                width: 125px;

                .first {
                  @include font-size(14px);

                }

                .last {
                  @include font-size(16px);

                  small {
                    @include font-size(13px);

                  }

                }

              }

              .price-cont {
                margin-top: 2px;
                margin-left: 20px;

                .price {

                  .price-text {
                    @include font-size(22px);
                    margin-top: 2px;

                    small {
                      @include font-size(10px);

                    }

                  }

                  .price-price {
                    @include font-size(33px);

                    small {
                      @include font-size(22px);

                    }

                  }

                }

                .piece-price {
                  padding: 10px 0 0;
                  @include font-size(16px);

                }

              }

              .piece-button {
                margin-left: 15px;

                .button-wrapper {
                  .success-change-number{
                    margin-top:-3px !important;
                    padding: 15px 14px !important;
                  }
                  .select-wrapper {
                    margin-right: 5px;

                    select {
                      padding: 12px 32px 12px 16px;
                      @include font-size(18px);

                    }

                    &::after {
                      top: 21px;
                      right: 10px;

                    }

                  }

                  .product-button {
                    margin: -3px auto 0;
                    padding: 13px 42px;
                    @include font-size(17px);

                    span {
                      width: 31px;
                      height: 30px;
                      margin: 0 10px -8px 0;

                    }

                  }

                }

              }

            }

          }

        }

      }

      .mfp-popup-upsell-wrapper {
        padding: 25px 25px 30px;

        .title {
          @include font-size(20px);

        }

        .mfp-popup-upsell-products {
          margin-top: 20px;

          .upsell-item {

            .thumbnail {
              float: left;
              width: 38%;

            }

            .description {
              float: left;
              width: 62%;
              padding: 10px 10px 0 10px;

              .title {
                @include font-size(16px);

              }

              strong  {
                @include font-size(18px);
                margin: 3px 0 0;

              }

              .button-wrapper {
                margin-top: 15px;

                .piece {
                  padding: 8px 18px;
                  @include font-size(14px);

                }

                .upsell-button {
                  padding: 9px 30px;

                }

              }

              .added-alert-text {
                margin-top: 15px;

                p {
                  @include font-size(15px);

                }

              }

            }

          }

        }

      }

      .mfp-popup-bottom-wrapper {
        padding: 20px 25px;

        .return-shop {

          .return-shop-btn {
            @include font-size(16px);

          }

        }

        .goto-cartpage {

          .go-cartpage {
            @include font-size(17px);

            &::before {
              top: -2px;
              left: -43px;
              width: 35px;
              height: 27px;

            }

          }

        }

      }

    }

  }

}
