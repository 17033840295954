.product-list-wrapper {
  margin: 0 0 75px; //.filter-sidebar {}
  .product-list {
    padding-left: 0;
    padding-right: 0;
  }
  .product-loader {
    margin-top:30px;
    margin-bottom:30px;
    img {
      display: block;
      margin: auto;
      width: 30px;
      height: 30px;
    }
  }
}
