//
// Grid system - Bootsrtap 3.0
// --------------------------------------------------

@include make-grid-columns;

@include make-grid(xs);

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}
