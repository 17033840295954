.why-stars {
  background-color: $white;
  margin: 50px 0 60px;

  .col-4 {
    padding-left: 30px;
    
    &.mobile{
      display: none;
    }
  }

  .title {
    margin: 22px 0 20px;
    padding: 0;
    font-family: $poppins-medium;
    font-weight: normal;
    @include font-size(32px);
    color: $black-1;

  }

  .text {
    margin: 0;
    padding: 0;
    @include font-size(17px);
    color: $black-1;

  }

  .why-we-items-wrapper {
    width: 100%;

    .why-we-item {
      float: left;
      width: (100 / 4) + %;
      text-align: center;

      .circle {
        width: 126px;
        height: 126px;
        border: 3px solid $yellow;
        margin: 0 auto;
        @include border-radius(50%);

        img {
          display: block;
          @include border-radius(50%);

        }

      }

      .title {
        display: inline-block;
        margin: 26px auto 0;
        width: 90%;
        font-family: $roboto-condensed-bold;
        font-weight: normal;
        @include font-size(20px);
        color: $gray-3;
        line-height: 1.3em;

      }

      .stars-wrapper {
        width: 111px;
        height: 25px;
        margin: 6px auto 0;
        position: relative;

        .stars-mask {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: -1;
          z-index: 3;
          width: 112px;
          height: 25px;
          background: url('/images/nettogumi/csillag_mask_180.svg') no-repeat;
          background-size: cover;

        }

        .yellow-bg {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 111px;
          height: 25px;
          background: $yellow;

        }

        .gray-bg {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          background: $gray-12;
          width: 111px;
          height: 25px;

        }

      }

      &.item-1,
      &.item-2,
      &.item-3 {
        position: relative;

        &::after {
          content: '+';
          position: absolute;
          bottom: 3px;
          right: -12px;
          font-family: $roboto-light;
          @include font-size(35px);
          color: $gray;

        }

      }

      &.item-4 {
        position: relative;

        &::after {
          content: '=';
          position: absolute;
          bottom: 3px;
          right: -12px;
          font-family: $roboto-light;
          @include font-size(35px);
          color: $gray;

        }

      }

    }

  }

  .big-rating {
    margin-top: 26px;
    width: 256px;
    height: 47px;
    position: relative;

    .stars-mask {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -1px;
      z-index: 3;
      width: 258px;
      height: 47px;
      background: url('/images/nettogumi/csillag_mask_180.svg') no-repeat center center;
      background-size: 258px;

    }

    .yellow-bg {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 256px;
      height: 47px;
      background: $yellow;

    }

    .gray-bg {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: $gray-12;
      width: 256px;
      height: 47px;

    }

  }

}

@include screen($null, $sm) {

  .why-stars {

    .col-8,
    .col-4 {
      width: 100%;

    }

    .col-4 {
      padding-left: 0;
      padding-right: 0;
      text-align: center;

      display: none;

      &.mobile{
        display: block;

        .title{
          margin-top: 0px;
        }

        .big-rating{
          margin-bottom: 50px;
        }
      }
    }

    .title {
      margin: 30px 0 10px;
      @include font-size(25px);

    }


    .rating-subtitle{
      font-size: 14px;
      line-height: 1.2em;
    }

    .text {
      @include font-size(14px);
      line-height: 1.2em;

    }

    .why-we-items-wrapper {

      .why-we-item {
        width: 50%;
        margin: 0 auto 20px;

        &:nth-child(3),
        &:nth-child(4) {
          margin: 0 auto 0;

        }

        .circle {
          width: 50px;
          height: 50px;
          border: 2px solid $yellow;

        }

        .title {
          margin: 10px auto 0;
          @include font-size(15px);

        }

        .stars-wrapper {
          width: 90px;
          height: 20px;
          margin: 6px auto 0;

          .stars-mask {
            width: 90px;
            height: 20px;

          }

          .yellow-bg {
            width: 90px;
            height: 20px;

          }

          .gray-bg {
            width: 90px;
            height: 20px;

          }

        }

        &.item-1,
        &.item-2,
        &.item-3,
        &.item-4 {

          &::after {
            content: none;

          }

        }

      }

    }

    .big-rating {
      margin: 20px auto 0;
      width: 200px;
      height: 38px;

      .stars-mask {
        width: 204px;
        height: 38px;
        background-size: 204px;

      }

      .yellow-bg {
        width: 200px;
        height: 38px;

      }

      .gray-bg {
        width: 200px;
        height: 38px;

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  .why-stars {

    .col-4 {
      padding-left: 15px;
      padding-right: 0;

      &.mobile{
        display: none;
      }

    }

    .title {
      margin: 8px 0 10px;
      @include font-size(18px);

    }

    .text {
      @include font-size(14px);
      line-height: 1.2em;

    }

    .why-we-items-wrapper {

      .why-we-item {

        .circle {
          width: 90px;
          height: 90px;

        }

        .title {
          margin: 22px auto 0;
          @include font-size(15px);

        }

        .stars-wrapper {
          width: 90px;
          height: 20px;
          margin: 6px auto 0;

          .stars-mask {
            width: 90px;
            height: 20px;

          }

          .yellow-bg {
            width: 90px;
            height: 20px;

          }

          .gray-bg {
            width: 90px;
            height: 20px;

          }

        }

        &.item-1,
        &.item-2,
        &.item-3,
        &.item-4 {

          &::after {
            content: none;

          }

        }

      }

    }

    .big-rating {
      margin-top: 15px;
      width: 200px;
      height: 38px;

      .stars-mask {
        width: 200px;
        height: 38px;
        background-size: 201px;

      }

      .yellow-bg {
        width: 200px;
        height: 38px;

      }

      .gray-bg {
        width: 200px;
        height: 38px;

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  .why-stars {

    .col-4 {
      padding-left: 15px;
      padding-right: 0;

      &.mobile{
        display: none;
      }

    }

    .title {
      margin: 0 0 10px;
      @include font-size(18px);

    }

    .text {
      @include font-size(12px);
      line-height: 1.2em;

    }

    .why-we-items-wrapper {

      .why-we-item {

        .circle {
          width: 80px;
          height: 80px;

        }

        .title {
          margin: 15px auto 0;
          @include font-size(12px);

        }

        .stars-wrapper {
          width: 70px;
          height: 16px;
          margin: 6px auto 0;

          .stars-mask {
            width: 70px;
            height: 16px;

          }

          .yellow-bg {
            width: 70px;
            height: 16px;

          }

          .gray-bg {
            width: 70px;
            height: 16px;

          }

        }

        &.item-1,
        &.item-2,
        &.item-3,
        &.item-4 {

          &::after {
            content: none;

          }

        }

      }

    }

    .big-rating {
      margin-top: 15px;
      width: 170px;
      height: 32px;

      .stars-mask {
        width: 170px;
        height: 32px;
        background-size: 171px;

      }

      .yellow-bg {
        width: 170px;
        height: 32px;

      }

      .gray-bg {
        width: 170px;
        height: 32px;

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .why-stars {

    .col-4 {
      padding-left: 30px;
      padding-right: 15px;

      &.mobile{
        display: none;
      }

    }

    .title {
      margin: 0 0 10px;
      @include font-size(22px);

    }

    .text {
      @include font-size(15px);

    }

    .why-we-items-wrapper {

      .why-we-item {

        .circle {
          width: 120px;
          height: 120px;

        }

        .title {
          margin: 22px auto 0;
          @include font-size(18px);

        }

        .stars-wrapper {
          width: 90px;
          height: 20px;
          margin: 6px auto 0;

          .stars-mask {
            width: 90px;
            height: 20px;

          }

          .yellow-bg {
            width: 90px;
            height: 20px;

          }

          .gray-bg {
            width: 90px;
            height: 20px;

          }

        }

        &.item-1,
        &.item-2,
        &.item-3 {

          &::after {
            right: -8px;
            bottom: 0;
            @include font-size(30px);

          }

        }

        &.item-4 {
          position: relative;

          &::after {
            right: -8px;
            bottom: 0;
            @include font-size(30px);

          }

        }

      }

    }

    .big-rating {
      margin-top: 15px;
      width: 256px;
      height: 47px;

      .stars-mask {
        width: 256px;
        height: 48px;
        background-size: 257px;

      }

      .yellow-bg {
        width: 256px;
        height: 47px;

      }

      .gray-bg {
        width: 256px;
        height: 47px;

      }

    }

  }

}
