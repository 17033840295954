.section-title {
  margin: 0;
  padding: 0;
  font-family: $poppins-medium;
  font-weight: normal;
  line-height: 1.2em;
  @include font-size(53px);
  color: $gray-3;

  &.section-title-center {
    display: block;
    text-align: center;
    margin-right: auto;
    margin-left: auto;

  }

}

.subtittle {
  margin: 0;
  padding: 0;
  font-family: $poppins-medium;
  font-weight: normal;
  line-height: 1.2em;
  @include font-size(32px);
  color: $yellow;

}

@include screen(($lg+1), $xl) {

  .subtittle {
    @include font-size(25px);

  }

}

@include screen($null, $sm) {

  .section-title {
    @include font-size(32px);

  }

}

@include screen(($sm + 1), $lg) {

  .section-title {
    @include font-size(40px);

  }

}

@include screen(($sm + 1), $md, landscape) {

  .section-title {
    @include font-size(32px);

  }

}

@include screen(($lg+1), $xl) {

  .section-title {
    @include font-size(43px);

  }

}
