.mfp-carselect{
    .mfp-content{
        max-width: 800px;

        #popup-carselect{
            .mfp-popup-content-wrapper{
                padding: 0px 25px;
            }
            button.mfp-close{
                background: #000;
                position: absolute;
                right: 10px;
                top: 10px;
                line-height: 1em;
                border-radius: 50px;
                width: 25px;
                height: 25px;
                color: #fff;
                font-size: 20px;
            }
        
            .felnik-filters-wrapper {
              padding-top: 20px;
              padding-bottom: 20px;
              margin: 0;
              position: relative;
                

              .notFoundCar{
                display: none;
                padding-left: 160px;

                  label{
                    font-family: "Roboto";
                    font-size: 16px;
                    display: block;
                    margin-bottom: 5px;
                    margin-top: 10px;
                  }

                  input{
                    display: block;
                    background-color: #f3f4f4;
                    border: 1px solid #c4c4c4;
                    width: 80%;
                    padding: 6px 10px;
                  }
              }
                #filterLeftIcon{
                    height: 100%;
                    float: left;
                    margin-left: -25px;
                    margin-right: 70px;
                    width: 115px;
                }
            
                #vechicleNotFoundToggle{
                    font-family: "RobotoBold";
                    font-weight: normal;
                    color: #ff5105;
                    transition: color 0.5s linear;
                    line-height: 50px;
                    display: block;
                    padding-left: 5px;
                    font-size: 16px;
            
                    &:hover{
                        color: #030000;
                    }        
                }
                select:disabled {
                    background-color: $gray-10;
                }
                
                .select-warpper.cut-long select{
                    padding-right: 60px;
                }
            
                .szezon {
                    width: 248px;
                    margin: 0 30px 0 22px;
            
                    .category-name{
                        display: none;
                    }
            
                    p {
                        font-family: $roboto-bold;
                        font-weight: normal;
                        @include font-size(16px);
                        color: $black-1;
                    }
                }
                
                .sizes {
                    width: 225px;
                    margin-right: 30px;
                
                    p {
                        font-family: $roboto-bold;
                        font-weight: normal;
                        @include font-size(16px);
                        color: $black-1;
                    }
                
                    .select-wrapper {
                        position: relative;
                        margin-bottom: 10px;
                        
                        &:last-child {
                            margin-bottom: 0;
                        }
                        
                        select {
                            padding: 12px 15px;
                            background-color: $gray-2;
                            border: 1px solid $gray-15;
                            @include border-radius(0px);
                            width: 100%;
                            color: $gray-16;
                            @include font-size(16px);
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                        }
                        
                        &::after {
                            content: '';
                            height: 9px;
                            width: 9px;
                            background: url('/images/nettogumi/arrow_down.png') no-repeat;
pointer-events: none;
                            background-size: 100%;
                            position: absolute;
                            top: 20px;
                            right: 15px;
                            z-index: 1;
                        }

                        &.cut-long{
                            select{
                                padding-right: 60px;
                            }
                        }
                
                        .cancelSelect {
                            font-family: "WebSymbolsRegular";
                            font-weight: normal;
                            font-style: normal;
                            color: #ff7b05;
                            display: inline-block;
                            margin-right: 5px;
                            border: 0px;
                            background: transparent;
                            position: absolute;
                            line-height: 44px;
                            right: 20px;
                            font-size: 24px;
                            top: 0;
                        }
                    }
                }
            
                .discount{
                    display: none;
                }
            .result {
              width: 220px;
              padding-top: 0px;
              text-align: center;
              p {
                color: $black-1;
                @include font-size(16px);
                line-height: 1.3em;
            
                &.result-text-mobile{
                  display: none;
                }
            
                strong {
                  font-family: $roboto-bold;
                  font-weight: normal;
                }
              }
              button {
                font-family: $roboto-bold;
                font-weight: normal;
                border: 0;
                @include border-radius(6px);
                background-color: $yellow;
                color: $black-1;
                text-transform: uppercase;
                cursor: pointer;
                @include font-size(21px);
                padding: 17px 45px;
                @include transition(background .3s ease);
                i {
                  font-style: normal;
                  font-family: $websymbol;
                  color: $white;
                }
                &:hover {
                  background-color: $black-1;
                  color: $white;
                }
              }
            }
            }

            .content-bottom{
                border-top: 1px solid #ddd;
                padding: 20px 0px;
                padding-left: 160px;
                position: relative;

                .label{
                    padding-left: 45px;
                    font-size: .9em;
                    font-family: "Roboto";
                    display: block;
                }

                input{
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }

                .checkmark{
                    position: absolute;
                    top: 15px;
                    left: 160px;
                    height: 35px;
                    width: 35px;
                    background-color: #f3f4f4;
                    border: 1px solid #c4c4c4;
                    border-radius: 0px;

                    &:after{
                        content: "";
                        position: absolute;
                        display: none;
                        left: 12px;
                        top: 6px;
                        width: 10px;
                        height: 18px;
                        border: solid #555;
                        border-width: 0 4px 4px 0;
                        transform: rotate(45deg);
                    }
                }

                input:checked ~ .checkmark:after {
                    display: block;
                }
            }
        }
    }
}



@include screen($null, $sm) {
    .mfp-carselect{
        .mfp-content{
            #popup-carselect{
                .mfp-popup-content-wrapper{
                    .felnik-filters-wrapper{
                        img#filterLeftIcon{
                            margin-right: 20px;
                            margin-bottom: 20px;
                        }
        
                        h1{
                            font-size: 2em;
                            line-height: 1.2em;
                        }
        
                        .sizes{
                            width: 100%;
        
                            &.last{
                                margin-top: 10px;
                            }
        
                            #vechicleNotFoundToggle{
                                line-height: 30px;
                                padding-left: 0px;
                            }
                        }
        
                        .notFoundCar{
                            padding: 0px;
        
                            label{
                                font-size: .9em;
                                line-height: 1.3em;
                            }
        
                            input{
                                width: 100%;
                            }
                        }
        
                    }

                    .content-bottom{
                        padding-left: 0px;
    
                        span.checkmark{
                            left: 0px;
                        }
                    }
                }
            }
        }
    }    
}
    
