#floatedWheelBottomBar{
    display: none;
}
@include screen($null, $sm) {
    #floatedWheelBottomBar {
        display: block;
        position: fixed;
        bottom: -100px;
        left: 0px;
        width: 100%;
        background: #ff470a;
        box-shadow: 0px -3px 20px 0px rgba(0,0,0,.3);
        z-index: 998;
        padding: 0px 15px;
        font-family: $roboto-condensed-regular;
        font-weight: normal;

        .popup-image{
            float: right;
            margin-top: 5px;

        }

        .title{
            margin-top: 7px;
            margin-bottom: 0px;
            font-size: 18px;
            color: #fff;
        }

        .subtitle{
            margin-top: 2px;
            margin-bottom: 5px;
            color: #000;
            font-size: 16px;

            a{
                color: #000;
                text-decoration: underline;
            }
        }
    }   
}

@include screen(($sm + 1), $lg) {
    #floatedWheelBottomBar{
        display: none;
    }
}

@include screen(($sm + 1), $md, landscape) {
    #floatedWheelBottomBar{
        display: none;
    }
}

@include screen(($lg+1), $xl) {
    #floatedWheelBottomBar{
        display: none;
    }
}

@include screen(($xl+1), $xxxl) {
    #floatedWheelBottomBar{
        display: none;
    }
}
