.mainpage-rims-filters {
    padding: 60px 0 50px;
    background: url('/images/nettogumi/filters_background.jpg') center center no-repeat;
    background-size: cover;
    text-align: center;
  
    .title {
        font-family: $poppins-bold;
        font-weight: normal;
        color: $yellow;
        @include font-size(48px);
        line-height: 1.1em;
        margin: 0;
        padding: 0;
        
        span {
            font-family: $poppins-medium;
            @include font-size(29px);
            color: $white;
            display: block;
        }
    }

    .filter-tabs-header{
      display: none;
    }

    .filter-tabs-wrapper {
        margin-top: 30px;
        text-align: left;
        background: #fff;
        padding: 15px;

        &.tab-container{
          display: none;
        }

        .felnik-filters-wrapper {
          padding: 0;
          margin: 0;
          position: relative;

            #filterLeftIcon{
                height: 100%;
                float: left;
                margin-left: -15px;
                margin-right: 70px;
            }

            #vechicleNotFoundLink{
                font-family: "RobotoBold";
                font-weight: normal;
                color: #ff5105;
                transition: color 0.5s linear;
                line-height: 50px;
                display: block;
                padding-left: 5px;
                font-size: 16px;

                &:hover{
                    color: #030000;
                }        
            }
            select:disabled {
                background-color: $gray-10;
            }

            .szezon {
                width: 248px;
                margin: 0 30px 0 22px;

                .category-name{
                    display: none;
                }

                p {
                    font-family: $roboto-bold;
                    font-weight: normal;
                    @include font-size(16px);
                    color: $black-1;
                }
            }
            
        .sizes {
            width: 225px;
            margin-right: 30px;

            p {
                font-family: $roboto-bold;
                font-weight: normal;
                @include font-size(16px);
                color: $black-1;
            }

            .select-wrapper {
                position: relative;
                margin-bottom: 10px;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
                select {
                    padding: 12px 15px;
                    background-color: $gray-2;
                    border: 1px solid $gray-15;
                    @include border-radius(6px);
                    width: 100%;
                    color: $gray-16;
                    @include font-size(16px);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                
                &::after {
                    content: '';
                    height: 9px;
                    width: 9px;
                    background: url('/images/nettogumi/arrow_down.png') no-repeat;
pointer-events: none;
                    background-size: 100%;
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    z-index: 1;
                }

                &.cut-long{
                  select{
                    padding-right: 60px;
                  }
                }

                .cancelSelect {
                    font-family: "WebSymbolsRegular";
                    font-weight: normal;
                    font-style: normal;
                    color: #ff7b05;
                    display: inline-block;
                    margin-right: 5px;
                    border: 0px;
                    background: transparent;
                    position: absolute;
                    line-height: 44px;
                    right: 20px;
                    font-size: 24px;
                    top: 0;
                }
            }
        }
        .discount{
          display: none;
        }
        .result {
          width: 220px;
          padding-top: 0px;
          text-align: center;
          p {
            color: $black-1;
            @include font-size(16px);
            line-height: 1.3em;
    
            &.result-text-mobile{
              display: none;
            }
    
            strong {
              font-family: $roboto-bold;
              font-weight: normal;
            }
          }
          button {
            font-family: $roboto-bold;
            font-weight: normal;
            border: 0;
            @include border-radius(6px);
            background-color: $yellow;
            color: $black-1;
            text-transform: uppercase;
            cursor: pointer;
            @include font-size(21px);
            padding: 17px 45px;
            @include transition(background .3s ease);
            i {
              font-style: normal;
              font-family: $websymbol;
              color: $white;
            }
            &:hover {
              background-color: $black-1;
              color: $white;
            }
          }
        }
        &::after {
          content: '';
          width: 230px;
          height: 341px;
          background: url('/images/nettogumi/filters_dog.png') no-repeat;
          background-size: 100%;
          position: absolute;
          right: -45px;
          bottom: -15px;
        }
    }
  }
}

@include screen($null,
$sm) {
  .mainpage-rims-filters {
    padding: 30px 0;
    overflow-x: hidden;
    .title {
      display: none;
    }
    .filter-tabs-header{
      display: block !important;
      text-align: left;
      padding-top: 10px;
      background-color: #F74E05;

      #rims-filter-image{
        height: 100%;
        float: left;
        
      }

      .header-text{
        color: #fff;
        font-size: 28px;
        font-weight: bold;
        padding-left: 20px;
        display: inline-block;

        p{
          line-height: .9em;
          margin: 20px auto;
          span{
            display: block;
            font-size: .7em;
          }
        }
      }
    }
    .filter-tabs-wrapper {
      margin-top: 0;

      &.tab-container{
        display: block !important;
        background: transparent !important;
        padding: 0px !important;

        .filter-tab {
          display: none;
          margin-top: 10px;
          padding: 30px 0 0;
          background: $white;
        }
        .filter-label {
          font-family: $roboto-condensed-bold;
          font-weight: normal;
          @include font-size(17px);
          display: inline-block;
          width: 48%;
          margin-right: 2%;
          padding: 20px 27px 15px;
          font-weight: normal;
          text-align: center;
          color: $gray-3;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          background: $gray-14;
          margin-bottom: -1px;

          &:last-child{
            margin-right: 0px;
            float: right;
          }
          cursor: pointer;

          a{
            color: #000 !important;
          }
          
          i {
            font-style: normal;
          }
          &:nth-of-type(2) {
            margin-right: 0;
          }

          &.active{
            background: #F74E05;
            color: #fff;
          }
        }
      }
    }
    .felnik-filters-wrapper {
      padding: 0;
      margin: 0;

      #filterLeftIcon{
        display: none;
      }

      &::after{
        display: none;
      }
      .sizes {
        width: 100% !important;
        margin: 0px !important;

        .clearfixer{
          display: none;
        }

        .select-wrapper{
          &:last-child {
              margin-bottom: 10px !important;
          }

          
        }
      }
      #vechicleNotFoundLink{
        display: none !important;
      }

      .discount{
        padding: 0px 20px;
        margin-bottom: 20px;
        display: block;

        .container{
          display: block;
          position: relative;
          padding-left: 50px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 18px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .label{
            font-size: .9em;
            font-family: "RobotoBold";
            display: block;
            padding-top: 5px;
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          &:hover input ~ .checkmark {
            background-color: #f3f4f4;
          }

          input:checked ~ .checkmark {
            background-color: #f3f4f4;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }

          .checkmark:after {
            left: 12px;
            top: 6px;
            width: 10px;
            height: 18px;
            border: solid #555;
            border-width: 0 4px 4px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 35px;
          width: 35px;
          background-color: #f3f4f4;
          border: 1px solid #c4c4c4;
          border-radius: 6px;
        }


        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

      }
      .result {
        width: 100% !important;
        padding: 0 20px;
        margin: 0 0 30px;
        text-align: center;
        margin-top: 15px !important;
        p {
          @include font-size(15px);
          margin-top: 0;
          padding-top: 0;

          &.result-text-mobile{
            display: block !important;
            margin-top: 10px;
          }

          &.result-text-desktop{
            display: none;
          }
        }
        button {
          @include font-size(21px);
          padding: 17px 45px;
        }
      }
      .help {
        display: none;
      }
      &::after {
        content: none;
      }
    }
  }
}

@include screen(($sm+1),
$lg) {
  .mainpage-rims-filters {
    padding: 50px 0;
    overflow: hidden;
    .title {
      @include font-size(48px);
      span {
        @include font-size(29px);
      }
    }
    .filter-tabs-wrapper {
      .filter-label {
        i {
          display: none;
        }
        span {
          margin-right: 0 !important;
        }
      }
    }
    .felnik-filters-wrapper {
      padding-bottom: 30px;
      .szezon {
        width: 33%;
        margin: 0;
        padding: 0 15px 0 15px;
        p {
          @include font-size(15px);
        }
      }
      .sizes {
        width: 33%;
        margin-right: 0;
        padding-right: 15px;
        p {
          @include font-size(15px);
        }
        .select-wrapper {
          select {
            padding: 10px 12px;
            @include font-size(15px);
          }
        }
      }
      .result {
        width: 33%;
        padding-top: 30px;
        p {
          @include font-size(15px);
        }
        button {
          @include font-size(19px);
          padding: 15px 35px;
        }
      }
      .help {
        display: none;
      }
      &::after {
        content: none;
      }
    }
  }
}

@include screen(($sm+1),
$md,
landscape) {
  .mainpage-rims-filters {
    .title {
      @include font-size(32px);
      span {
        @include font-size(20px);
      }
    }
    .felnik-filters-wrapper {
      padding-bottom: 20px;
      .szezon {
        width: 44%;
        margin: 0;
        padding: 0 10px 0 10px;
      }
      .sizes {
        width: 28%;
        padding-right: 10px;
      }
      .result {
        width: 28%;
        padding-top: 30px;
        p {
          @include font-size(13px);
        }
        button {
          @include font-size(15px);
          padding: 10px 25px;
        }
      }
    }
  }
}

@include screen(($lg+1),
$xl) {
  .mainpage-rims-filters {
    padding: 60px 0 50px;
    overflow: hidden;
    .title {
      @include font-size(42px);
      span {
        @include font-size(23px);
      }
    }
    .filter-tabs-wrapper {
      margin-top: 30px;
      .filter-tab {
        margin-top: 10px;
        padding: 30px 0 0;
      }
      .filter-label {
        i {
          display: none;
        }
        span {
          margin-right: 0 !important;
        }
      }
    }
    .felnik-filters-wrapper {
      padding-bottom: 15px;
      .szezon {
        width: 195px;
        margin: 0 0 0 15px;
        p {
          @include font-size(15px);
        }
      }
      .sizes {
        width: 200px;
        margin-right: 15px;
        p {
          @include font-size(15px);
        }
        .select-wrapper {
          select {
            padding: 10px 13px;
            @include font-size(15px);
          }
        }
      }
      .result {
        width: 200px;
        padding-top: 30px;
        p {
          @include font-size(15px);
        }
        button {
          @include font-size(19px);
          padding: 17px 35px;
        }
      }
      .help img {
        width: 280px;
      }
      &::after {
        width: 200px;
        height: 311px;
        right: -45px;
        bottom: -15px;
      }
    }
  }
}

@include screen(($xl+1),
1366px) {
  .mainpage-rims-filters {
    .felnik-filters-wrapper {
      &::after {
        width: 210px;
        height: 301px;
        right: -22px;
      }
    }
  }
}
