* {
  box-sizing: inherit;

  &::after,
  &::before {
    box-sizing: inherit;

  }

  &:focus {
    outline: none;

  }

}

html {
	box-sizing: border-box;

}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;

}

body {
  background: $white;
  color: $black;
  font-family: $base-font-family;
  font-weight: normal;
  line-height: 1.5em;
  @include font-size($base-fontsize);

}

.clear {
  float: none;
  clear: none;

  &::before,
  &::after {
    content: '';
    display: table;
    line-height: 0;

  }

  &::after {
    clear: both;

  }

}

blockquote, q {
	quotes: "" "";

	&:before,
	&:after {
		content: "";

	}

}

hr {
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;

}

img {
	height: auto;
	max-width: 100%;

}

figure {
	margin: 2em 0;

}

a {
  text-decoration: none;
  outline: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: none!important;

  }

}

.display-table {
  display: table;
  width: 100%;

}

.display-table-row {
  display: table-row;

}

.display-table-cell {
  display: table-cell;
  vertical-align: top;

}

.t-align-left {
  text-align: left;

}

.t-align-right {
  text-align: right;

}


.show-on-mobile {
  display: none;

}

@include screen(($sm + 1), $lg) {

  .show-on-mobile {
    display: block;

  }

}

embed,
iframe,
object {
  max-width: 100%;

}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }

}
