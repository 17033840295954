.producImage {
    .mfp-content {
        max-width: 1222px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        background: $white;
        .mfp-figure {
            background: $white;
            .mfp-close {
                width: auto;
                width: auto;
                color: black;
                padding: 10px;
                text-align: center;
            }
            .info-bar {
                a {
                    display: inline-block;
                    font-family: RobotoBold;
                    font-weight: 400;
                    font-size: 17px;
                    color: #ff7b05;
                    transition: color .3s ease;
                }
                img {
                    height: auto;
                    max-width: 100%;
                }
                strong {
                    display: inline-block;
                    font-family: RobotoBold;
                    font-weight: 400;
                    font-size: 17px;
                    color: grey;
                    line-height: 1.3em;
                    margin: 0 10px 0 5px;
                    padding: 0;
                }
                table {
                    width: 100%;
                    border: 1px solid #ccc;
                    border-collapse: collapse;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    table-layout: fixed;
                    h2 {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 100%;
                        line-height: 35px;
                        white-space: nowrap;
                    }
                }
                table caption {
                    font-size: 1.5em;
                    margin: .5em 0 .75em;
                }
                table tr {
                    background-color: #f8f8f8;
                    border: 1px solid #ddd;
                    padding: .35em;
                }
                table th,
                table td {
                    padding: .625em;
                    text-align: center;
                }
                table td.title{
                    width: 50%;
                }
                table th {
                    font-size: .85em;
                    letter-spacing: .1em;
                    text-transform: uppercase;
                }
                @media screen and (max-width: 600px) {
                    table {
                        border: 0;
                    }
                    table caption {
                        font-size: 1.3em;
                    }
                    table thead {
                        border: none;
                        clip: rect(0 0 0 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }
                    table tr {
                        border-bottom: 3px solid #ddd;
                        display: block;
                        margin-bottom: .625em;
                    }
                    table td {
                        border-bottom: 1px solid #ddd;
                        display: block;
                        font-size: .8em;
                        text-align: left;
                    }
                    table td.title{
                        width:100%
                    }
                    table td::before {
                        /*
                      * aria-label has no advantage, it won't be read inside a table
                      content: attr(aria-label);
                      */
                        content: attr(data-label);
                        float: left;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    table td:last-child {
                        border-bottom: 0;
                    }
                }
            }
            img.mfp-image {
                line-height: 0;
                display: block;
                box-sizing: border-box;
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100% !important;
                margin: 0 auto;
                padding: 0;
            }

            .mfp-nav{
                position: absolute;
                width: 50%;
                height: calc(100% - 82px);
                top: 82px;
                z-index: 9999999;
                cursor: pointer;

                &.mfp-prev{
                    left: 0px;
                    background-image: url('/images/nettogumi/arrow-left.png');
                    background-position: 10% center;
                    background-repeat: no-repeat;
                    background-size: 60px;
                    opacity: .5;
                }

                &.mfp-next{
                    right: 0px;
                    background-image: url('/images/nettogumi/arrow-right.png');
                    background-position: 90% center;
                    background-repeat: no-repeat;
                    background-size: 60px;
                    opacity: .5;
                }
            }
        }
    }
}

@include screen($null, $sm) {
    .info-bar{
        td{
            &:last-child{
                border-bottom: none;
            }
        }
    }
    .mfp-img{
        max-width: 80% !important;
        margin: 0px 10% !important;
    }
    .mfp-nav{
        height: calc(100% - 191px) !important;
        top: 191px !important;
        
        &.mfp-prev{
            left: 0px;
            background-image: url('/images/nettogumi/arrow-left-xs.png') !important;
            background-position: 0% center !important;
            background-size: 40px !important;
            opacity: .8 !important;
        }

        &.mfp-next{
            right: 0px;
            background-image: url('/images/nettogumi/arrow-right-xs.png') !important;
            background-position: 100% center !important;
            opacity: .8 !important;
            background-size: 40px !important;
        }
    }
}