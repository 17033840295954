.testimonial-section {
  padding: 70px 0 80px;

  .section-title {
    margin-bottom: 90px;

  }

  .arukereso-infoes {
    margin-left: 35px;
    width: 85%;

    .arukereso-logo {
      vertical-align: middle;
      width: 80%;
      margin: 0 auto 25px;

      p {
        font-family: $roboto-bold;
        font-weight: normal;
        text-align: left;
        @include font-size(17px);
        margin: 5px 0 0 20px;
        padding: 0;
        line-height: 1.3em;

      }

    }

    .yellow-box {
      background-color: $yellow;
      @include border-radius(12px);
      vertical-align: middle;
      padding: 20px 60px;

      .bigtext {
        text-align: right;

        p {
          font-family: $roboto-bold;
          font-weight: normal;
          text-align: right;
          @include font-size(45px);
          margin: 0;
          padding: 0;
          line-height: 1.05em;

        }

      }

      .smalltext {

        p {
          font-family: $roboto-bold;
          font-weight: normal;
          text-align: left;
          @include font-size(17px);
          margin: 0 0 0 20px;
          padding: 0;
          line-height: 1.3em;

        }

      }

    }

    .arukereso-rating {
      padding: 25px 0 40px 10px;
      vertical-align: middle;

      .first-row,
      .last-row {
        padding: 12px 10px;
        border-bottom: 1px dotted $gray-13;

      }

      p {
        margin: 0;
        padding: 0;
        font-family: $roboto-bold;
        font-weight: normal;
        @include font-size(17px);
        line-height: 1.3em;
        color: $black-1;

      }

      .big-star {
        font-family: $websymbol;
        color: $yellow;
        @include font-size(29px);

      }

      .small-star {
        font-family: $websymbol;
        color: $yellow;
        @include font-size(21px);

      }

    }

    .descr {
      margin: 0;
      padding: 0 0 0 17px;
      @include font-size(15px);
      color: $black-1;
      line-height: 1.3em;

    }

  }

  .testimonials {

    .testimonials-slider {
      margin: 0;
      padding: 0;

      li {
        list-style: none;

      }

    }

    .testimonials-wrapper {

      .testimonials-slider--wrapper {

        .testimonials-slider--wrapper__text {
          background-color: $gray-2;
          padding: 60px 55px 70px;
          @include border-radius(6px);
          position: relative;

          &::before {
            content: '';
            position: absolute;
            bottom: -30px;
            left: 70px;
            width: 39px;
            height: 30px;
            background: url('/images/nettogumi/testimonial_slider_arrow.png') center top no-repeat;
            background-size: 100%;

          }

          p {
            font-family: $roboto-light;
            @include font-size(19px);
            line-height: 1.3em;

            &:first-child {
              margin-top: 0;
              padding-top: 0;

            }

            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;

            }

          }

        }

        .testimonials-slider--wrapper__who {

          p {
            @include font-size(17px);
            line-height: 1.3em;
            color: $black-1;
            margin: 15px 0 20px 130px;
            padding: 0;

            strong {
              font-family: $roboto-bold;
              font-weight: normal;

            }

          }

        }

      }

      .bx-controls {
        position: realtive;

      }

      .bx-wrapper {

        .bx-viewport {
          height: 100%!important;

        }

        .bx-pager {
          bottom: -70px;
          padding-top: 0;

          &.bx-default-pager a {
            background: $gray-13;

            &:hover,
            &.active {
              background: $yellow;
            }

          }

        }

      }

    }

  }

}

@include screen($null, $sm) {

  .testimonial-section {
    padding: 50px 0 80px;

    .container {
      margin: 0;
      padding: 0;

    }

    .section-title {
      margin-bottom: 45px;

    }

    .arukereso-infoes {
      margin-left: 0;
      width: 100%;

      .arukereso-logo {
        width: 100%;
        margin: 0 auto 25px;

        img {
          max-width: 70%;
          float: right;

        }

        p {
          @include font-size(12px);
          margin: 5px 0 0 20px;

        }

      }

      .yellow-box {
        @include border-radius(6px);
        padding: 20px 10px;
        text-align: center;

        &.display-table,
        .display-table-row,
        .display-table-cell {
          display: block;

        }

        .bigtext {
          margin-bottom: 10px;

          p {
            @include font-size(40px);
            text-align: center;

          }

        }

        .smalltext {

          p {
            text-align: center;
            @include font-size(15px);
            margin: 0;

          }

        }

      }

      .arukereso-rating {
        padding: 25px 10px 30px 10px;

        &.display-table,
        .display-table-row,
        .display-table-cell {
          display: block;
          width: 100%;

        }

        .first-row,
        .last-row {
          padding: 12px 10px;
          border-bottom: 1px dotted $gray-13;

        }

        .first-row {
          padding: 12px 10px 0;
          border-bottom: 0;

        }

      }

    }

    .testimonials {
      margin-top: 60px;

      .testimonials-wrapper {

        .testimonials-slider--wrapper {

          .testimonials-slider--wrapper__text {
            padding: 30px;

            &::before {
              left: 20px;

            }

            p {
              @include font-size(17px);

            }

          }

          .testimonials-slider--wrapper__who {

            p {
              @include font-size(14px);
              margin: 5px 0 0 70px;

            }

          }

        }

        .bx-wrapper {

          .bx-pager {
            bottom: -50px;

          }

        }

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  .testimonial-section {
    padding: 70px 0 110px;

    .section-title {
      margin-bottom: 60px;

    }

    .arukereso-infoes {
      margin-left: 0;
      margin-bottom: 55px;
      width: 100%;

      .arukereso-logo {
        width: 70%;

      }

      .yellow-box {
        padding: 20px 90px;

        .bigtext {
          padding-left: 30px;

        }

        .smalltext {

          p {
            @include font-size(22px);
            margin: -3px 0 0 30px;
            line-height: 1.1em;

          }

        }

      }

    }

    .testimonials {

      .testimonials-slider {
        margin: 0;
        padding: 0;

        li {
          list-style: none;

        }

      }

      .testimonials-wrapper {

        .testimonials-slider--wrapper {

          .testimonials-slider--wrapper__text {
            background-color: $gray-2;
            padding: 60px 55px 70px;
            @include border-radius(6px);
            position: relative;

            &::before {
              content: '';
              position: absolute;
              bottom: -30px;
              left: 70px;
              width: 39px;
              height: 30px;
              background: url('/images/nettogumi/testimonial_slider_arrow.png') center top no-repeat;
              background-size: 100%;

            }

            p {
              font-family: $roboto-light;
              @include font-size(19px);
              line-height: 1.3em;

              &:first-child {
                margin-top: 0;
                padding-top: 0;

              }

              &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;

              }

            }

          }

          .testimonials-slider--wrapper__who {

            p {
              @include font-size(17px);
              line-height: 1.3em;
              color: $black-1;
              margin: 15px 0 0 130px;
              padding: 0;

              strong {
                font-family: $roboto-bold;
                font-weight: normal;

              }

            }

          }

        }

        .bx-controls {
          position: realtive;

        }

        .bx-wrapper {

          .bx-viewport {
            height: 100%!important;

          }

          .bx-pager {
            bottom: -70px;
            padding-top: 0;

            &.bx-default-pager a {
              background: $gray-13;

              &:hover,
              &.active {
                background: $yellow;
              }

            }

          }

        }

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  .testimonial-section {

    .arukereso-infoes {

      .yellow-box {
        @include border-radius(6px);
        padding: 20px 10px;
        text-align: center;

        &.display-table,
        .display-table-row,
        .display-table-cell {
          display: block;

        }

        .bigtext {
          margin-bottom: 10px;

          p {
            @include font-size(40px);
            text-align: center;

          }

        }

        .smalltext {

          p {
            text-align: center;
            @include font-size(15px);
            margin: 0;

          }

        }

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .testimonial-section {

    .section-title {
      margin-bottom: 70px;

    }

    .arukereso-infoes {
      margin-left: 0;
      width: 100%;

      .arukereso-logo {
        width: 100%;

      }

      .yellow-box {
        padding: 20px 60px;

        .bigtext {

          p {
            font-family: $roboto-bold;
            font-weight: normal;
            text-align: right;
            @include font-size(45px);
            margin: 0;
            padding: 0;
            line-height: 1.05em;

          }

        }

        .smalltext {

          p {
            @include font-size(15px);
            margin: 5px 0 0 20px;

          }

        }

      }

    }

    .testimonials {


      .testimonials-wrapper {

        .testimonials-slider--wrapper {

          .testimonials-slider--wrapper__text {
            padding: 50px;

            p {
              @include font-size(17px);

            }

          }

          .testimonials-slider--wrapper__who {

            p {
              @include font-size(14px);

            }

          }

        }

      }

    }

  }

}
