#form-container{
    padding-top: 35px;
    padding-bottom: 50px;

    .callout{
        background: #ffa402;
        padding: 1px 24px;
        color: #fff;
        margin-bottom: 25px;
        border-left: 5px solid #e09105;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    form{
        margin-top: 30px;

        .form-control{
            width: 100%;
            padding: 10px 20px;

        }
        
        label.error{
            color: #DC143C;
            margin-bottom: 10px;
            display: block;
            font-size: .95em;
        }

        button[type="submit"]{
            margin-top: 15px;
        }
    }
}