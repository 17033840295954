.need-help {
  padding: 70px 0 80px;
  background-color: $gray-2;
  
  .formSendSuccess {
    color: $white;
    font-size: 18px;
    padding: 10px;
    margin-top: 10px;
    background-color: $green;
    border-radius: 10px;
    text-align: center;
  }
  .section-title {
    margin-bottom: 55px;
  }
  .dog-placeholder {
    text-align: right;
    margin-right: 32px;
    img {
      display: block;
      float: right;
    }
    .pure-link {
      display: inline-block;
      margin-top: 38px;
      font-family: $roboto-bold;
      font-weight: normal;
      color: $orange;
      @include font-size(19px);
      @include transition(color .3s ease);
      &:hover {
        color: $gray-3;
      }
    }
  }
  .descr-container {
    margin-left: 35px;
    margin-right: 80px;
    .text {
      @include font-size(17px);
      color: $gray-3;
      line-height: 1.3em;
      margin: 30px 0 31px;
      padding: 0;
    }
    .descr-container-bottom {
      margin-top: 20px;
      label {
        display: block;
        margin-top: 18px;
      }
    }
  }
}

@include screen($null, $sm) {
  .need-help {
    padding: 50px 0;
    .container {
      margin: 0;
      padding: 0;
    }
    .section-title {
      margin-bottom: 40px;
    }
    .dog-placeholder {
      text-align: center;
      margin-right: 0;
      img {
        float: none;
        margin: 0 auto;
        width: 200px;
      }
      .pure-link {
        margin-top: 30px;
        @include font-size(14px);
      }
    }
    .descr-container {
      margin-left: 0;
      margin-right: 0;
      .text {
        @include font-size(15px);
        margin: 30px 0;
      }
      .descr-container-bottom {
        margin-top: 5px;
        .col-8,
        .col-4 {
          width: 100%;
          float: none;
        }
        label {
          display: block;
          margin-top: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@include screen(($sm + 1), $lg) {
  .need-help {
    padding: 50px 0 60px;
    .section-title {
      margin-bottom: 40px;
    }
    .col-md-5 {
      width: 40%;
      float: left;
    }
    .col-md-7 {
      width: 60%;
      float: left;
    }
    .dog-placeholder {
      text-align: center;
      margin-right: 20px;
      img {
        float: none;
        margin: 0 auto;
      }
      .pure-link {
        display: inline-block;
        margin-top: 30px;
        @include font-size(14px);
        line-height: 1.3em;
      }
    }
    .descr-container {
      margin-left: 10px;
      margin-right: 0;
      .text {
        @include font-size(15px);
        margin: 30px 0 31px;
      }
      .descr-container-bottom {
        margin-top: 5px;
        .col-8,
        .col-4 {
          width: 100%;
          float: none;
        }
        label {
          display: block;
          margin-top: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@include screen(($lg+1), $xl) {
  .need-help {
    padding: 50px 0 60px;
    .dog-placeholder {
      margin-right: 0;
      text-align: center;
      img {
        float: none;
      }
      .pure-link {
        margin-top: 38px;
        @include font-size(17px);
      }
    }
    .descr-container {
      margin-left: 20px;
      margin-right: 0;
      .descr-container-bottom {
        margin-top: 10px;
      }
    }
  }
}