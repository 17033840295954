label {
  font-family: $roboto-condensed-regular;
  @include font-size(17px);
  color: $black-1;

}

@include screen($null, $sm) {

  label {
    @include font-size(13px);

  }

}

.form-control {
  -webkit-appearance: none;
  @include border-radius(0);
  border: 1px solid $gray-5;
  background-color: $white;
  @include font-size(17px);
  color: $gray;
  padding: 20px 29px;
  margin-bottom: 12px;

  &:focus {
    outline: none;
    border-color: lighten($orange, 30%);

  }

  &.error {
    border-color: $error-color;
    margin-bottom: 0;

  }

  &.form-textarea {
    height: 120px;

  }

}

@include screen($null, $lg) {

  .form-control {
    @include font-size(15px);
    padding: 15px 20px;
    margin-bottom: 10px;

  }

}

.form-checkbox {
  display: none;

}

.form-checkbox + label span {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: -1px 10px 0 0;
  vertical-align: middle;
  background: url('/images/nettogumi/checkbox_empty.png') no-repeat;
  cursor: pointer;

}

.form-checkbox:checked + label span {
  background: url('/images/nettogumi/checkbox_checked.png') no-repeat;

}

.form-radio {
  display: none;

}

.form-radio + label {
  float: left;
  margin-right: -5px;
  text-align: center;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &:last-child {
    margin-right: 0;

  }

}

.form-radio + label span {
  display: block;
  width: 76px;
  height: 76px;
  margin: -1px 10px 0 0;
  cursor: pointer;

}

.form-radio + label i {
  font-style: normal;
  display: block;
  font-family: $roboto-condensed-regular;
  font-weight: normal;
  @include font-size(16px);
  color: $black-1;
  margin-top: 11px;
  margin-left: -12px;
  line-height: 1.2em;

}

.form-radio.nyari + label span {
  background: url('/images/nettogumi/input_nyari_default_big.png') no-repeat;

}

.form-radio.nyari:checked + label span {
  background: url('/images/nettogumi/input_nyari_selected_big.png') no-repeat;

}

.form-radio.teli + label span {
  background: url('/images/nettogumi/input_teli_default_big.png') no-repeat;

}

.form-radio.teli:checked + label span {
  background: url('/images/nettogumi/input_teli_selected_big.png') no-repeat;

}

.form-radio.negyevszak + label span {
  background: url('/images/nettogumi/input_negyevszak_default_big.png') no-repeat;

}

.form-radio.negyevszak:checked + label span {
  background: url('/images/nettogumi/input_negyevszak_selected_big.png') no-repeat;

}

.form-radio.nyari + label span,
.form-radio.teli + label span,
.form-radio.negyevszak + label span,
.form-radio.nyari:checked + label span,
.form-radio.teli:checked + label span,
.form-radio.negyevszak:checked + label span {
  background-size: 100%;

}

@include screen($null, $xs) {

  .form-radio + label {
    float: left;
    width: 76px;
    margin-right: 11px;

  }

  .form-radio + label span {
    margin: 0 10px 0 0;
    width: 76px;
    height: 76px;

  }

  .form-radio + label i {
    @include font-size(15px);
    margin-top: 10px;
    margin-left: 0;

  }

}

@include screen(($xs+1), $sm) {

  .form-radio + label {
    float: left;
    width: 88px;
    margin-right: 11px;

  }

  .form-radio + label span {
    margin: 0 10px 0 0;
    width: 88px;
    height: 88px;

  }

  .form-radio + label i {
    @include font-size(15px);
    margin-top: 10px;
    margin-left: 0;

  }

}

@include screen(($sm + 1), $lg) {

  .form-radio + label span {
    width: 64px;
    height: 64px;

  }

  .form-radio + label i {
    @include font-size(12px);
    margin-top: 9px;

  }

}

@include screen(($lg+1), $xl) {

  .form-radio + label span {
    width: 56px;
    height: 56px;

  }

  .form-radio + label i {
    @include font-size(12px);
    margin-top: 9px;

  }

}

.form-submit {
  font-family: $roboto-bold;
  font-weight: normal;
  @include font-size(21px);
  padding: 14px 33px 16px;
  line-height: 1.3em;
  @include transition(background .3s ease);
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border: 0;
  @include border-radius(5px);
  text-transform: uppercase;
  color: $black-1;
  background-color: $yellow;

  &:hover {
    color: $white;
    background-color: $black-1;

  }

}

@include screen(($lg+1), $xxl) {

  .form-submit {
    @include font-size(20px);
    padding: 14px 28px 16px;

  }

}

.error-place {
  display: block;
  margin-bottom: 12px;
  font-family: $roboto-condensed-regular;
  color: $error-color;
  @include font-size(13px);

}

@include screen($null, $lg) {

  .error-place {
    margin-bottom: 10px;
    @include font-size(12px);

  }

}


.form-element {
  margin-top: 50px;
  width: 90%;

  &.rims{
    margin-top: 10px !important;
  }

  &.new-search{
    margin-top: 20px !important;
  }

  label {
    display: inline-block;
    font-family: $roboto-bold;
    font-weight: normal;
    @include font-size(16px);
    color: $black-1;
    line-height: 1.3em;
    margin-bottom: 17px;

  }

  .select-wrapper {
    position: relative;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;

    }

    select {
      padding: 12px 15px;
      background-color: $gray-2;
      border: 1px solid $gray-15;
      @include border-radius(6px);
      width: 100%;
      color: $gray-16;
      @include font-size(16px);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding-right: 30px;
    }

    &::after {
      content: '';
      height: 9px;
      width: 9px;
      background: url('/images/nettogumi/arrow_down.png') no-repeat;
pointer-events: none;
      background-size: 100%;
      position: absolute;
      top: 20px;
      right: 15px;
      z-index: 1;

    }

  }

  .checkbox-wrapper {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;

    }

    label {
      display: inline-block;
      font-family: $roboto-regular;
      font-weight: normal;
      @include font-size(17px);
      color: $black-1;
      line-height: 1.3em;
      margin-bottom: 0;

      .label-image{
        margin-left: 6px;
        vertical-align: middle;
        height: 25px;
      }

    }

  }

  .star {
    display: inline-block;
    font-family: $websymbol;
    @include font-size(19px);
    color: $yellow;
    margin: -10px 0 0 1px!important;
    background: none!important;
    cursor: default;

    &.first {
      margin: -10px 0 0 0;

    }

    &.empty {
      color: $gray-12;

    }

  }

  .do-filter {
    border: none;
    font-family: $roboto-bold;
    font-weight: normal;
    @include font-size(17px);
    background-color: $orange;
    color: $white;
    text-transform: uppercase;
    padding: 4px 12px;

    &:hover {
      background-color: $orange-1;

    }

  }

  .reset-form {
    cursor: pointer;
    font-family: $roboto-bold;
    font-weight: nromal;
    @include font-size(17px);
    color: $yellow;
    display: table-cell;

    i {
      font-family: $websymbol;
      font-weight: normal;
      font-style: normal;
      color: $orange;
      display: inline-block;
      margin-right: 5px;

    }

  }

}

@include screen($null, $lg) {

  .form-element {
    margin-top: 25px;
    width: 100%;

    label {
      @include font-size(17px);
      margin-bottom: 10px;
      color: $yellow;

    }

    .select-wrapper {

      select {
        padding: 6px 12px;
        @include font-size(16px);

      }

      &::after {
        height: 7px;
        width: 7px;
        top: 16px;
        right: 10px;

      }

    }

    .checkbox-wrapper {
      margin-bottom: 10px;

      label {
        @include font-size(16px);
        color: $white;

      }

    }

    .star {
      @include font-size(16px);
      margin: -1px 0 0 -5px!important;

      &.first {
        margin: -1px 0 0 0!important;

      }

    }

    .reset-form {
      @include font-size(15px);
      line-height: 1.2em;

      i {
        margin-right: 5px;
        display: block;
        float: left;

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .form-element {
    margin-top: 25px;
    width: 90%;

    label {
      @include font-size(14px);
      margin-bottom: 10px;

    }

    .select-wrapper {

      select {
        padding: 6px 12px;
        @include font-size(13px);

      }

      &::after {
        height: 7px;
        width: 7px;
        top: 16px;
        right: 10px;

      }

    }

    .checkbox-wrapper {
      margin-bottom: 10px;

      label {
        @include font-size(13px);

      }

    }

    .star {
      @include font-size(14px);
      margin: -1px 0 0 -5px!important;

      &.first {
        margin: -1px 0 0 0!important;

      }

    }

    .reset-form {
      @include font-size(13px);
      line-height: 1.2em;

      i {
        margin-right: 5px;
        display: block;
        float: left;

      }

    }

  }

}
