.why-we {
  background-color: $yellow;
  padding: 50px 0 70px;

  .section-title{
    &.title-desktop{
      display: block;
    }

    &.title-mobile{
      display: none;
    }
  }

  .collapse-arrow{
    display: none;
  }
  

  &.in-subpage {
    margin: 100px 0 40px;

  }

  .why-we-items-wrapper {
    margin: 60px auto 0;
    width: 100%;

    .why-we-item {
      float: left;
      width: (100 / 4) + %;
      text-align: center;

      .circle {
        width: 198px;
        height: 198px;
        border: 6px solid $white;
        margin: 0 auto;
        @include border-radius(50%);
        position: relative;

        img {
          display: block;
          @include border-radius(50%);
          position: absolute;
          left: 0px;
          top: 0px;
          z-index: 3;
        }

      }

      .title,
      h1, h2, h3, h4, h5, h6 {
        display: inline-block;
        margin: 26px auto 0;
        width: 90%;
        font-family: $roboto-condensed-bold;
        font-weight: normal;
        @include font-size(24px);
        color: $gray-3;
        line-height: 1.3em;

      }

      .text,
      p {
        display: inline-block;
        margin: 18px 0 0;
        padding: 0;
        width: 90%;
        @include font-size(17px);
        color: $gray-3;
        line-height: 1.3em;

      }

    }

  }

}

@include screen($null, $sm) {

  .why-we {
    padding: 30px 0;

    .section-title{
      &.title-desktop{
        display: none;
      }

      &.title-mobile{
        display: block;
        line-height: 1em;

        small{
          font-size: 0.5em;
          font-weight: bold;
          display: block;
        }
      }
    }

    .collapse-arrow{
      display: block;
      width: 30px;
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    .collapse{
      height: auto;
      display: none;
    }

    .why-we-items-wrapper {
      margin: 40px auto 0;

      .why-we-item {
        float: none;
        width: 100%;
        margin-bottom: 35px;

        &:last-child {
          margin-bottom: 0;

        }

        .circle {
          border: 4px solid $white;

        }

        .title {
          margin: 20px auto 0;
          width: 70%;
          @include font-size(22px);

        }

        .text {
          margin: 15px 0 0;
          width: 80%;
          @include font-size(16px);

        }

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  .why-we {

    .why-we-items-wrapper {
      margin: 50px auto 0;

      .why-we-item {

        .circle {
          width: 158px;
          height: 158px;
          border: 4px solid $white;

        }

        .title {
          margin: 20px auto 0;
          width: 85%;
          @include font-size(21px);

        }

        .text {
          margin: 18px 0 0;
          @include font-size(14px);

        }

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  .why-we {

    .why-we-items-wrapper {
      margin: 50px auto 0;

      .why-we-item {

        .circle {
          width: 110px;
          height: 110px;
          border: 2px solid $white;

        }

        .title {
          @include font-size(19px);

        }

        .text {
          @include font-size(14px);

        }

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .why-we {

    .why-we-items-wrapper {

      .why-we-item {

        .circle {
          width: 190px;
          height: 190px;

        }

        .title {
          @include font-size(22px);
        }

        .text {
          @include font-size(16px);

        }

      }

    }

  }

}
