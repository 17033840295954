// Base config
$base-fontsize:              16px;
$base-color:                 #000;
$base-background-color:      #fff;


// Site content max width
$max-width-content:          1210;
$max-width-textpage:         1000px;

// Poppins fonts
$poppins-bold:               'PoppinsBold';
$poppins-medium:             'PoppinsMedium';

// Roboto fonts
$roboto-regular:             'RobotoRegular';
$roboto-bold:                'RobotoBold';
$roboto-bold-italic:         'RobotoBoldItalic';
$roboto-black:               'RobotoBlack';
$roboto-black-italic:        'RobotoBlackItalic';
$roboto-medium:              'RobotoMedium';
$roboto-medium-italic:       'RobotoMediumItalic';
$roboto-italic:              'RobotoItalic';
$roboto-light:               'RobotoLight';
$roboto-light-italic:        'RobotoLightItalic';
$roboto-thin:                'RobotoThin';
$roboto-thin-italic:         'RobotoThinItalic';

// Roboto Condensed fonts
$roboto-condensed-bold:         'RobotoCondensedBold';
$roboto-condensed-bold-italic:  'RobotoCondensedBoldItalic';
$roboto-condensed-italic:       'RobotoCondensedItalic';
$roboto-condensed-light:        'RobotoCondensedLight';
$roboto-condensed-light-italic: 'RobotoCondensedLightItalic';
$roboto-condensed-regular:      'RobotoCondensedRegular';

// Social icon font
$socicon:                    'socicon';

// Websymbols
$websymbol:                  'WebSymbolsRegular';

// Cartographer Webfont
$cartographer:                'cartographer-webfont';

// Set font family list
$font-family-list:           $poppins-bold $poppins-medium $roboto-regular $roboto-bold $roboto-bold-italic $roboto-black $roboto-black-italic $roboto-medium $roboto-medium-italic $roboto-italic $roboto-light $roboto-light-italic $roboto-thin $roboto-thin-italic $roboto-condensed-bold $roboto-condensed-bold-italic $roboto-condensed-italic $roboto-condensed-light $roboto-condensed-light-italic $roboto-condensed-regular;

// Set Base font family
$base-font-family:           $roboto-regular;

// Colors
$white:                      #ffffff;

$black:                      #000000;
$black-1:                    #030000;

$gray:                       #808080;
$gray-1:                     #2b2a29;
$gray-2:                     #f3f4f4;
$gray-3:                     #202020;
$gray-4:                     #e5e5e5;
$gray-5:                     #ebeded;
$gray-6:                     #2b2a29;
$gray-7:                     #434242;
$gray-8:                     #2b2a29;
$gray-9:                     #cacaca;
$gray-10:                    #e9e9e9;
$gray-11:                    #cacaca;
$gray-12:                    #e2e2e2;
$gray-13:                    #c0c0c0;
$gray-14:                    #b2b3b3;
$gray-15:                    #c4c4c4;
$gray-16:                    #404040;
$gray-17:                    #202020;
$gray-18:                    #f8f8f8;
$gray-19:                    #545454;
$gray-20:                    #ececec;

$yellow:                     #ffa402;
$yellow-1:                   #ffdea1;
$yellow-2:                   #fded54;
$yellow-3:                   #fce500;

$orange:                     #ff7b05;
$orange-1:                   #ff5105;

$green:                      #009933;
$green-1:                    #669933;

$class-A:                    #00A849;
$class-B:                    #42B941;
$class-C:                    #BFD916;
$class-D:                    #FFF606;
$class-E:                    #FFBC01;
$class-F:                    #F27314;
$class-G:                    #F1251C;

$blue:                       #27509b;

$error-color:                #DC143C;

$bc-facebook:                #3b5998;
$bc-spotify:                 #2ebd59;
$bc-youtube:                 #cd201f;
$bc-google-plus:             #dd4b39;
$bc-twitter:                 #1da1f2;
$bc-tripadvisor:             #589442;
$bc-instagram:               #405de6;
$bc-pinterest:               #bd081c;
$bc-linkedin:                #0077b5;


// Grid variables - Based on Bootstrap 3 grid system
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
$grid-columns:               12 !default;
$grid-gutter-width:          30px !default;
$container-tablet:           (720px + $grid-gutter-width) !default;
$container-sm:               $container-tablet !default;
$container-desktop:          (940px + $grid-gutter-width) !default;
$container-md:               $container-desktop !default;
$container-large-desktop:    (($max-width-content - $grid-gutter-width) + $grid-gutter-width) !default;
$container-lg:               $container-large-desktop !default;


// Breakpoints for media queries
$null:                       0px;
$xs:                         320px;
$sm:                         480px;
$md:                         768px;
$lg:                         992px;
$xl:                         1200px;
$xxl:                        1400px;
$xxxl:                       1775px;
