.mfp-savecart {
  .mfp-popup-title-wrapper {
    .title {
      text-align: left;
      padding-left: 20px !important;
    }
  }
  .mfp-popup-bottom-wrapper {
    font-family: "RobotoBold";
    background: #ececec !important;
    border-top: 2px solid #e5e5e5 !important;

    h3 {
      margin-bottom: 0px;
    }

    p {
      margin-top: 6px;
    }

    #saveCartForm {
      input[type="text"] {
        padding: 7px 11px;
        width: 100%;
      }

      button.btn-save-cart {
        width: 100%;
        border-radius: 4px;
        background: #404040;
        text-transform: uppercase;
        padding: 7px;
        color: #bc9c3e;
        cursor: pointer;
      }
    }
  }
}
