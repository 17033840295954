.modal{
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    background: #fff;
    display: none;
    min-width: 600px;
    min-height: 400px;

    #closeModal{
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 4px 10px;
        font-weight: bold;
        z-index: 9999;
        display: block;
        cursor: pointer;
        font-size: 28px;
        color: #333;
    }

    .modal-content{
        padding: 20px;
    }
}

#popupContentModalOverlay{
    width: 100%;
    height: 100%;
    position: fixed;
    background: #0b0b0b;
    z-index: 99;
    opacity: 0.8;
}