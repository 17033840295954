.order-data {
    .checkoutErrorTexts {
        font-size: 14px;
        color: $class-G;
    }
    .errorInQuery {
        font-size: 24px;
        color: $class-G;
        margin-top: 20px;
    }
    hr {
        margin: 20px 30px;
        background-color: $gray-9;
    }
    .order-data-box {
        padding: 25px;
        margin-top: 48px;
        border: 1px solid $gray-9;
        h3 {
            margin-top: 8px;
            font-weight: bold;
            @include font-size(27px);
        }
        .form-group {
            hr {
                margin-left: 0;
                margin-right: 0;
                margin-top: 6px;
                margin-bottom: 10px;
            }
            label {
                display: block;
                margin-bottom: 3px;
            }
            .overide-bootstrap-padding-in-address-left {
                padding-right: 4px;
            }
            .overide-bootstrap-padding-in-address-right {
                padding-left: 4px;
            }
            .payment-public-description {
                margin-top: 25px; // padding-top: 25px;
                // border-radius: 1px solid $gray-9;
                margin-bottom: 16px;
                margin-left: 0;
                font-size: 0.9rem;
                display: block;
            }
            .form-control {
                width: 100%;
                height: 36px;
                border-radius: 6px;
                padding-top: 4px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 4px;
                line-height: 1;
                @include font-size(16px);
                background-color: $gray-2;
                border: 1px solid $gray-9;
                &.postcode {
                    width: 68px;
                    margin-bottom: 4px;
                }
                &.city {
                    width: 259px;
                    margin-bottom: 4px;
                }
            }
            .radio-button-wrapper {
                margin-bottom: 0px;
                padding-bottom: 8px;
                margin-top: 25px; //border-bottom: 1px solid $gray-9;
                input[type="radio"] {
                    display: none;
                }
                .radio-button {
                    display: block;
                    position: relative;
                    padding-left: 30px;
                    span {
                        color: $orange-1;
                    }
                    strong {
                        color: $yellow;
                        display: inline-block;
                    }
                    &:before {
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        top: 8px;
                        left: 4px;
                        z-index: 1;
                        border-radius: 50%;
                        background-color: $yellow;
                    }
                    &:after {
                        position: absolute;
                        content: "";
                        width: 18px;
                        height: 18px;
                        top: 4px;
                        left: 0;
                        z-index: 0;
                        border-radius: 50%;
                        background-color: $white;
                        border: 2px solid $black;
                    }
                }
                input[type="radio"]:checked+.radio-button {
                    &:before {
                        content: "";
                    }
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
        textarea {
            max-width: 100%;
            margin-bottom: 0;
            min-height: 138px;
            margin-top: 3px;
        }
        .product-item {
            position: relative;
            width: 100%;
            border: 0;
            float: none;
            padding-left: 77px;
            background-color: transparent;
            border-bottom: 1px solid $gray-9;

            &.delivery-item{
                height: autog;
                display: flex;
                align-items: center;
            }

            .size-info {
                color: $gray;
                margin-top: 0;
                margin-bottom: 0;
                @include font-size(16px);
            }
            .price {
                margin-top: 7px;
                span {
                    color: $orange-1;
                }
            }
            h4 {
                margin-top: 0;
                margin-bottom: 0;
            }
            img {
                position: absolute;
                left: -19px;
                top: 0;
                width: 95px;

                &.delivery-item{
                    width: 75px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .product-description {
                .manufacturer-logo {
                    margin-top: 0;
                    margin-bottom: 0;
                    height: auto;
                    img {
                        position: relative;
                        width: 100px;
                        left: 0;
                        @include screen(($sm + 1), $lg) {
                            width: 100px;
                        }
                        @include screen(($sm + 1), $lg) {
                            width: 100px;
                        } //Mobile
                        @include screen($null, $sm) {
                            width: 100px;
                            display: block;
                            margin: 10px auto;
                        }
                    }
                }
                h4 {
                    padding-top: 0px;
                }
                .manufacturer-logo+h4 {
                    padding-top: 0;
                }
            }
            &:first-child {}
            &:last-child {
                border-bottom: 0;
            }
            @include screen(($sm + 1), $lg) {
                padding-left: 77px;
            }
            @include screen(($sm + 1), $lg) {
                padding-left: 77px;
            } //Mobile
            @include screen($null, $sm) {
                padding-left: 0;
            }
        }
    }
    .checkbox-button-wrapper {
        input[type="checkbox"] {
            display: none;
        }
        .checkbox-button {
            display: block;
            position: relative;
            padding-left: 30px;
            padding-top: 2px;
            line-height: 1.3;
            span {
                color: $orange-1;
            }
            &:before {
                position: absolute;
                width: 16px;
                height: 16px;
                top: 5px;
                left: 1px;
                z-index: 1;
                border-radius: 3px;
                background: url('/images/nettogumi/done.png') no-repeat;
                background-size: 100%;
            }
            &:after {
                position: absolute;
                content: "";
                width: 18px;
                height: 18px;
                top: 4px;
                left: 0;
                z-index: 0;
                border-radius: 3px;
                background-color: $white;
                border: 2px solid $black;
            }
        }
        input[type="checkbox"]:checked+.checkbox-button {
            &:before {
                content: "";
            }
        }
        @include screen(($sm + 1),
        $lg) {
            .checkbox-button {
                padding-top: 5px;
                margin-top: 8px;
            }
        }
        @include screen(($sm + 1),
        $lg) {
            .checkbox-button {
                padding-top: 5px;
                margin-top: 8px;
            }
        } //Mobile
        @include screen($null,
        $sm) {
            .checkbox-button {
                padding-top: 5px;
                margin-top: 8px;
            }
        }
    }
    .price-summary {
        @include font-size(27px);
        color: $orange-1;
        float: right;
        margin-top: 32px;
        @include screen(($sm + 1),
        $lg) {
            @include font-size(27px);
        }
        @include screen(($sm + 1),
        $lg) {
            @include font-size(27px);
        } //Mobile
        @include screen($null,
        $sm) {
            @include font-size(20px);
        }
    }
    .price-summary-text {
        float: left;
        @include font-size(27px);
        margin-top: 32px;
        font-weight: bold;
        @include screen(($sm + 1),
        $lg) {
            @include font-size(27px);
        }
        @include screen(($sm + 1),
        $lg) {
            @include font-size(27px);
        } //Mobile
        @include screen($null,
        $sm) {
            @include font-size(20px);
        }
    }
    .button-wrapper {
        margin-top: 32px;
        .product-button {
            display: block;
            text-align: center;
            font-weight: bold;
            font-size: 1.1875rem;
            color: $black;
            background-color: $yellow;
            text-transform: uppercase;
            background-clip: padding-box;
            margin: 0px auto;
            padding: 15px 35px;
            transition: background 0.3s ease;
            border-radius: 12px;
            border: 0px;
            width: 100%;
            &:hover {
                color: $white;
                background-color: $black;
            }
        }
    }

    .form-group label{
        @include screen($null, $sm) {
            @include font-size(16px);
        }
    }
}

.visszavesszuk-csereljuk {
    margin: 52px 0;
    .visszavesszuk-csereljuk-container {
        border: 1px solid $gray-9;
        @include clearfix();
        .with-banner {
            padding-bottom: 21px + 17px;
            .ribbon {
                width: 130px;
                margin: -5px auto 0;
                .top {
                    height: 105px;
                    background-color: $orange-1;
                    position: relative;
                    .badge-wrapper {
                        padding: 25px 5px 0;
                        text-align: center;
                        strong {
                            display: block;
                            font-family: $roboto-condensed-bold;
                            font-weight: normal;
                            @include font-size(19px);
                            line-height: 1em;
                            color: $black-1;
                            text-transform: uppercase;
                        }
                        small {
                            display: block;
                            font-family: $roboto-condensed-bold;
                            font-weight: normal;
                            @include font-size(43px);
                            line-height: 1.1em;
                            color: $white;
                            text-transform: uppercase;
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -21px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 65px 21px 65px;
                        border-color: transparent transparent $orange-1 transparent;
                        -webkit-transform: rotate(-180deg);
                        z-index: 1;
                    }
                }
                .bottom {
                    height: 90px;
                    background-color: $yellow;
                    position: relative;
                    .badge-wrapper {
                        padding: 35px 5px 0;
                        text-align: center;
                        small {
                            display: block;
                            font-family: $roboto-condensed-bold;
                            font-weight: normal;
                            @include font-size(16px);
                            line-height: 1em;
                            color: $black-1;
                        }
                        strong {
                            display: block;
                            font-family: $roboto-condensed-bold;
                            font-weight: normal;
                            @include font-size(20px);
                            line-height: 1.1em;
                            color: $black-1;
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -21px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 65px 21px 65px;
                        border-color: transparent transparent $yellow transparent;
                        -webkit-transform: rotate(-180deg);
                        z-index: 2;
                    }
                }
            }
        }
        .with-texts {
            padding: 40px 40px 40px 0;
            .title,
            h1, h2, h3, h4, h5, h6 {
                margin: 0;
                padding: 0 0 0 0;
                font-family: $poppins-medium;
                font-weight: normal;
                @include font-size(36px);
                line-height: 1.2em;
                color: $black-1;
                position: relative;
            }
            p {
                margin: 0 0 15px;
                padding: 0;
                @include font-size(17px);
                line-height: 1.3em;
                color: $black-1;
                &:nth-of-type(1) {
                    margin-top: 23px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@include screen($null,
$sm) {
    .visszavesszuk-csereljuk {
        margin: 40px 0 0;
        .visszavesszuk-csereljuk-container {
            .with-banner {
                padding-bottom: 21px;
                @include clearfix();
                .ribbon {
                    width: 100%;
                    margin: -5px auto 0;
                    .top {
                        width: 48%;
                        float: left;
                        height: 100px;
                        .badge-wrapper {
                            padding: 20px 5px 0;
                        }
                        &::after {
                            bottom: inherit;
                            top: 0;
                            right: -21px;
                            border-width: 50px 21px 50px 0;
                            border-color: transparent $orange-1 transparent transparent;
                        }
                    }
                    .bottom {
                        width: 46.5%;
                        float: left;
                        height: 100px;
                        .badge-wrapper {
                            padding: 30px 5px 0;
                        }
                        &::after {
                            bottom: inherit;
                            top: 0;
                            right: -21px;
                            border-width: 50px 21px 50px 0;
                            border-color: transparent $yellow transparent transparent;
                        }
                    }
                }
            }
            .with-texts {
                padding: 0 20px 20px;
                .title {
                    padding: 0 0 0 50px;
                    @include font-size(27px);
                    &::before {
                        top: 1px;
                        width: 43px;
                        height: 40px;
                    }
                }
                p {
                    @include font-size(16px);
                }
            }
        }
    }
}

// @include screen((0),
// $sm) {

//     .order-data {
//         .order-data-box {
//             .product-item {
//                 img {
//                     top: 0px;
//                     left: 0px;
//                     display: block;
//                     position: relative;
//                     margin: auto 0;

//                     &.delivery-item{
//                         width: 65px !important;
//                         margin-right: 10px !important;
//                     }
//                 }
//                 .manufacturer-logo {
//                     display: block;
//                     margin: 10px auto;
//                 }
//             }
//         }
//     }
// }

@include screen($null, $sm) {
    .checkoutContainer{
        .product-item{
            &.delivery-item{
                img{
                    &.delivery-item{
                        width: 60px;
                        top: 0px;
                        transform: none;
                    }
                }

                .product-description{
                    padding-left: 50px;
                }
            }
            .product-description{
                padding-left: 80px;
        
                .manufacturer-logo img{
                    margin: 0px !important;
                }
            }
        }
    }
    .more-items{
        .item{
            position: relative;
            padding-top: 15px;
            padding-bottom: 15px;
            border-top: 1px solid $gray-9;

            &:last-child{
                margin-bottom: 70px;
                border-bottom: 1px solid $gray-9;
            }

            .col-xs-5, .col-xs-7{
                position: static;

                img{
                    max-height: 120px;
                    max-width: 100px;
                }

                .item-info-wrapper{
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: translateY(0);

                    img{
                        max-width: none;
                        max-height: 15px;
                    }

                    h4{
                        margin: 0;
                        line-height: 1.3em;
                    }
                }
            }

            .auto-height{
                position: relative;
                height: 100%;
                top: 0;

                .item-info-wrapper{
                    left: 0;
                    width: 100%;
                    margin-top: 0 !important;

                    .old-price{
                        display: inline-block;
                        font-weight: bold;
                        @include font-size(18px);
                        text-decoration: line-through;
                    }

                    .new-price{
                        color: $orange-1;
                        font-weight: bold;
                        display: inline-block;
                        @include font-size(32px);
                    }

                    .select-wrapper{
                        width: 100%;
                        margin-top: 15px;
                        margin-bottom: 0;
                        display: block;
                    }

                    .btn-block{
                        display: block;
                        margin-top: 15px;
                        padding: 15px 35px;
                    }

                    &.form-element{
                        text-align: right;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

@include screen(($sm + 1),
$lg) {
    .visszavesszuk-csereljuk {
        margin: 40px 0 0;
        .visszavesszuk-csereljuk-container {
            .col-md-2 {
                width: 25%;
                float: left;
            }
            .col-md-10 {
                width: 75%;
                float: left;
            }
            .with-banner {
                padding-bottom: 21px + 17px;
            }
            .with-texts {
                padding: 30px 30px 30px 0;
                .title {
                    padding: 0 0 0 50px;
                    @include font-size(27px);
                    &::before {
                        top: -7px;
                        width: 43px;
                        height: 40px;
                    }
                }
                p {
                    @include font-size(16px);
                }
            }
        }
    }
}

@include screen(($lg+1),
$xl) {
    .visszavesszuk-csereljuk {
        margin: 40px 0 0;
        .visszavesszuk-csereljuk-container {
            .with-texts {
                padding: 40px 40px 40px 0;
                .title {
                    padding: 0 0 0 70px;
                    @include font-size(35px);
                    &::before {
                        top: -7px;
                        width: 55px;
                        height: 50px;
                    }
                }
                p {
                    @include font-size(16px);
                }
            }
        }
    }
}

@media (min-width: 0) {
    .overide-bootstrap-padding-in-address-left {
        padding-left: 0px;
    }
    .overide-bootstrap-padding-in-address-right {
        padding-right: 0px;
    }
    .overide-bootstrap-padding-in-address {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (min-width: $screen-md-min) {}

//992px & greater
@media (min-width: $screen-lg-min) {
    .overide-bootstrap-padding-in-address-left {
        padding-left: 15px;
    }
    .overide-bootstrap-padding-in-address-right {
        padding-right: 15px;
    }
    .overide-bootstrap-padding-in-address {
        padding-left: 15px;
        padding-right: 15px;
    }
}

//1200px & greater