.show-filter {
  overflow: hidden;
  #overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .8);
    overflow: hidden;
    z-index: 99999998;
  }
}

.cancelSelect {
  font-family: "WebSymbolsRegular";
  font-weight: normal;
  font-style: normal;
  color: #ff7b05;
  display: inline-block;
  margin-right: 5px;
  border: 0px;
  background: transparent;
  position: absolute;
  line-height: 44px;
  right: 20px;
  font-size: 24px;
  top: 0;
}

.filter-sidebar {
  .show-on-mobile {
    display: none;
  }
  .form-checkbox+label span {
    width: 20px;
    height: 20px;
    margin: -4px 10px 0 0;
    background-size: 100%;
  }
}

@include screen($null, $sm) {
  .filter-sidebar {
    width: 300px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: -300px;
    background: $gray-19;
    padding: 25px 15px;
    z-index: 99999999;
    transition-duration: 1s;
    overflow-y: auto;
    max-height: 100%;
    .show-on-mobile {
      display: block;
    }
    .new-search-link{
      display: block;
      margin-top: 30px;
      color: #ffa402;
      font-weight: bold;
    }
  }
  .show-filter {
    .filter-sidebar {
      -webkit-transform: translateX(300px);
      transform: translateX(300px);
      -webkit-transform: translate3d(300px, 0, 0);
      transform: translate3d(300px, 0, 0);
      z-index: 99999999;
      transition-duration: 1s;
    }
  }
}

@include screen(($sm + 1), $lg) {
  .filter-sidebar {
    width: 300px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: -300px;
    background: $gray-19;
    padding: 25px 15px;
    z-index: 99999999;
    transition-duration: 1s;
    overflow-y: auto;
    max-height: 100%;
    .show-on-mobile {
      display: block;
    }
  }
  .show-filter {
    .filter-sidebar {
      -webkit-transform: translateX(300px);
      transform: translateX(300px);
      -webkit-transform: translate3d(300px, 0, 0);
      transform: translate3d(300px, 0, 0);
      z-index: 99999999;
      transition-duration: 1s;
    }
  }
}

@include screen(($lg+1), $xl) {
  .filter-sidebar {
    width: 20%;
    padding-left: 0;
  }
}