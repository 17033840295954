.breadcrumb {
  font-family: $roboto-condensed-regular;
  margin: 0;
  padding: 0;
  @include font-size(16px);
  line-height: 1.4em;
  color: $gray;
  text-align: left;

  li {
    position: relative;
    list-style: none;
    display: inline-block;
    margin-left: 8px;
    color: $gray-4;

    &:first-child {
      margin-left: 0;

    }

     &::after {
      position: relative;
      top: -1px;
      content: '»';
      color: $gray;
      margin-left: 8px;

    }

    &:last-child {
      &::after {
        content: none;

      }

    }

    a {
      color: $gray;
      @include transition(color .3s ease);

      &:hover {
        color: $orange;

      }

    }

  }

}

@include screen($null, $lg) {

  .breadcrumb {
    @include font-size(14px);
    line-height: 1.3em;

    li {
      margin-left: 5px;

       &::after {
        margin-left: 5px;

      }

      &.hidden-on-small-device {
        display: none;

      }

    }

  }

}


@include screen(($lg+1), $xl) {

  .breadcrumb {
    @include font-size(14px);
    line-height: 1.3em;

    li {
      margin-left: 5px;

       &::after {
        margin-left: 5px;

      }

    }

  }

}
