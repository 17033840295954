footer {

    .footer-menu-container {
        background-color: $gray-1;
        padding: 70px 0 80px;

        .footer-menu-wrapper {
            width: 91%;
            margin: 0 auto;

        }

        .fm-title {
            margin: 0 0 25px;
            padding: 0;
            font-family: $roboto-bold;
            font-weight: normal;
            @include font-size(21px);
            color: $yellow;
            text-transform: uppercase;

        }

        .footer-menu {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                padding: 9px 0;
                position: relative;
                @include font-size(17px);

                &::before {
                    content: '\00BB';
                    color: $yellow;
                    display: block;
                    float: left;
                    position: relative;
                    top: -3px;
                    margin-right: 12px;

                }

                a {
                    color: $white;
                    display: block;
                    margin-left: 19px;
                    @include font-size(17px);
                    line-height: 1.3em;
                    @include transition(color .3s ease);

                    &:hover {
                        color: $yellow;

                    }

                }

                &.current a {
                    color: $yellow;

                }

            }

        }

    }

    .footer-information-container {
        background-color: $black;
        padding: 50px 0;
        text-align: center;

        span {
            font-family: $roboto-bold;
            font-weight: normal;
            color: $white;
            @include font-size(21px);
            position: relative;

            &.tel {
                // margin-right: -32px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: -32px;
                    width: 18px;
                    height: 18px;
                    background: url('/images/nettogumi/yellow_phone_icon.png') no-repeat;
                    background-size: 100%;

                }

            }

            &.envelop {
                margin-right: -37px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 9px;
                    left: -37px;
                    width: 22px;
                    height: 14px;
                    background: url('/images/nettogumi/yellow_envelop_icon.png') no-repeat;
                    background-size: 100%;

                }

            }

            &.nyomkovetes {
                margin-right: -37px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: -37px;
                    width: 30px;
                    height: 29px;
                    background: url('/images/nettogumi/yellow_nyomonkovetes_icon.png') no-repeat;
                    background-size: 100%;

                }

            }

            &.facebook {
                // margin-right: -37px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 4px;
                    left: -37px;
                    width: 26px;
                    height: 25px;
                    background: url('/images/nettogumi/yellow_facebook_icon.png') no-repeat;
                    background-size: 100%;

                }

            }

            a {
                color: $white;
                @include transition(color .3s ease);

                &:hover {
                    color: $yellow;

                }

            }

        }

    }

    .footer-copyright-info {
        text-align: center;
        padding: 25px 0;
        border-bottom: 1px solid $gray-4;

        .text {
            margin: 0;
            padding: 0;
            color: $gray;
            @include font-size(17px);

            a {
                color: $gray;
                @include transition(color .3s ease);

                &:hover {
                    color: $yellow;

                }

            }

        }

    }

    .footer-others {
        padding: 30px 0;
        text-align: center;

        a {
            display: inline-block;
            line-height: 0;

        }

        img {
            margin: 0 15px;
            line-height: 0;

        }

    }


}

@include screen($null, $sm) {

    footer {

        .footer-menu-container {
            padding: 50px 0;

            .footer-menu-wrapper {
                width: 100%;

            }

            .fm-title {
                margin: 0 0 15px;
                @include font-size(19px);

            }

            .col-md-3,
            .col-md-4 {
                margin-bottom: 30px;

            }

            .footer-menu {

                li {
                    padding: 5px 0;
                    @include font-size(15px);

                    &::before {
                        top: -3px;

                    }

                    a {
                        @include font-size(15px);

                    }

                }

            }

        }

        .footer-information-container {
            padding: 25px 0;

            .col-md-3 {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;

                }

            }

            span {
                @include font-size(17px);

                &.tel {
                    margin-right: -24px;

                    &::before {
                        top: 4px;
                        left: -24px;
                        width: 15px;
                        height: 15px;

                    }

                }

                &.envelop {
                    margin-right: -30px;

                    &::before {
                        top: 6px;
                        left: -30px;
                        width: 20px;
                        height: 12px;

                    }

                }

                &.nyomkovetes {
                    margin-right: -31px;

                    &::before {
                        top: 1px;
                        left: -31px;
                        width: 25px;
                        height: 24px;

                    }

                }

                &.facebook {
                    margin-right: -29px;

                    &::before {
                        top: 2px;
                        left: -29px;
                        width: 20px;
                        height: 20px;

                    }

                }

            }

        }

        .footer-copyright-info {
            padding: 20px 0;

            .container {
                padding: 0;
                margin: 0;

            }

            .text {
                margin: 0;
                padding: 0;
                @include font-size(13px);
                line-height: 1.3em;

                &.developer {
                    margin-top: 20px;

                }

            }

        }

        .footer-others {
            padding: 30px 0;

            img {
                margin: 0 10px;
                max-width: 25%;

            }

        }

    }

}

@include screen(($sm + 1), $lg) {

    footer {
        .footer-menu-container {
            padding: 50px 0 50px;

            .footer-menu-wrapper {
                width: 100%;

                .col-md-3,
                .col-md-4,
                .col-md-2 {
                    width: 25%;
                    float: left;

                }

            }

            .fm-title {
                margin: 0 0 15px;
                @include font-size(17px);

            }

            .footer-menu {

                li {
                    padding: 5px 0;
                    @include font-size(13px);

                    &::before {
                        top: -2px;

                    }

                    a {
                        @include font-size(13px);

                    }

                }

            }

        }

        .footer-information-container {
            padding: 30px 0;

            .col-md-3 {
                width: 50%;
                float: left;
                margin-bottom: 20px;

                &:nth-of-type(3),
                &:nth-of-type(4) {
                    margin-bottom: 0;

                }

            }

            span {
                @include font-size(14px);

                &.tel {
                    margin-right: -22px;

                    &::before {
                        top: 3px;
                        left: -22px;
                        width: 12px;
                        height: 12px;

                    }

                }

                &.envelop {
                    margin-right: -27px;

                    &::before {
                        top: 5px;
                        left: -27px;
                        width: 18px;
                        height: 10px;

                    }

                }

                &.nyomkovetes {
                    margin-right: -27px;

                    &::before {
                        top: 1px;
                        left: -27px;
                        width: 27px;
                        height: 26px;

                    }

                }

                &.facebook {
                    margin-right: -27px;

                    &::before {
                        top: 0;
                        left: -27px;
                        width: 21px;
                        height: 21px;

                    }

                }

            }

        }

        .footer-copyright-info {
            padding: 20px 0;

            .col-md-6 {
                width: 50%;
                float: left;

            }

            .text {
                @include font-size(14px);

            }

        }

        .footer-others {
            padding: 20px 0;

            img {
                max-width: 20%;

            }

        }

    }

}

@include screen(($lg+1), $xl) {

    footer {

        .footer-menu-container {
            padding: 50px 0 60px;

            .footer-menu-wrapper {
                width: 100%;

                .col-md-4,
                .col-md-2 {
                    width: 25%;

                }

            }

            .fm-title {
                margin: 0 0 20px;
                @include font-size(20px);

            }

            .footer-menu {

                li {
                    padding: 5px 0;
                    @include font-size(16px);


                    a {
                        margin-left: 19px;
                        @include font-size(16px);

                    }

                }

            }

        }

        .footer-information-container {
            padding: 30px 0;

            span {
                @include font-size(17px);

                &.tel {

                    &::before {
                        top: 4px;

                    }

                }

                &.envelop {

                    &::before {
                        top: 6px;

                    }

                }

                &.nyomkovetes {

                    &::before {
                        top: 2px;

                    }

                }

                &.facebook {

                    &::before {
                        top: 1px;

                    }

                }

            }

        }

        .footer-copyright-info {
            padding: 20px 0;

            .text {
                @include font-size(15px);

            }

        }

        .footer-others {
            padding: 30px 0;

        }


    }

}

.facebook-thanks-page {
    font-family: $roboto-bold;
    font-weight: normal;
    color: $black;
    @include font-size(21px);
    position: relative;
    display: inline-block;
    margin-bottom: 30px;
    margin-right: -37px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -37px;
        width: 26px;
        height: 25px;
        background: url('/images/nettogumi/yellow_facebook_icon.png') no-repeat;
        background-size: 100%;

    }

    a {
        color: $black;
        @include transition(color .3s ease);

        &:hover {
            color: $yellow;

        }

    }

}
