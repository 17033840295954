.promotion-in-product-details-page {
  margin: 40px 0 0;

  .promotion-wrapper {
    background-color: black;
    border: 1px solid black;
    position: relative;
    height: 224px;

    .col-md-3{
        position: inherit;
    }

    .promotion-image {
      position: absolute;
      bottom: -1px;
      left: 15px + 7px;
      line-height: 0;

    }

    .text {
      padding: 50px 45px 33px 0;

      .title {
        margin: 0;
        padding: 0;
        font-family: $roboto-bold;
        font-weight: normal;
        line-height: 1.2em;
        @include font-size(30px);
        color: $yellow;
        letter-spacing: 3px;

        strong {
          color: $yellow-3;
          text-transform: uppercase;

        }

      }

      p {
        margin-top: 26px;
        padding: 0;
        @include font-size(17px);
        color: $white;
        line-height: 1.3em;
        letter-spacing: 1px;

        &:nth-of-type(1) {
          margin-top: 26px;

        }

        &:last-child {
          margin-bottom: 0;

        }

      }

      
    }

    &.michelin{
      background-color: #465d8f;
      height: 170px;
      border: none;

      .text{
        padding: 25px 25px 25px 0px;

        .title{
          color: #fff;
          letter-spacing: 0px;
          font-weight: normal; 
          font-size: 1.675rem;
  
          span{
            text-transform: uppercase;
            color: #FCE500;
          }
        }

        p{
          margin-top: 15px;
          letter-spacing: 0px;
        }
      }

      .promotion-image{
        left: 10px;
        width: 270px;
      }
    }
  }

}

@include screen($null, $lg) {

  .promotion-in-product-details-page {

    .promotion-wrapper {
      position: inherit;
      height: auto !important;

      .col-md-3 {
        display: none;

      }

      .promotion-image {
        display: none;

      }

      .text {
        padding: 30px 20px;

        .title {
          @include font-size(23px);

        }

        p {
          margin: 0 0 10px;
          @include font-size(16px);

        }

      }

      &.michelin{
        height: auto;
      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .promotion-in-product-details-page {

    .promotion-wrapper {

      .promotion-image {
        bottom: -1px;
        left: 7px;

      }

      .text {
        padding: 35px 45px 27px 0;

        .title {
          @include font-size(19px);

        }

        p {
          @include font-size(16px);

        }

      }

    }

  }

}
