.thanks-data{
    .thanks-box{
        position: relative;
        padding: 10px 25px;
        margin-top: 45px;
        margin-bottom: 45px;
        border: 1px solid $gray-9;

        p{
            .emp{
                color: $orange-1;
                @include font-size(22px);
            }

            a{
                color: $orange;
                text-decoration: underline;
            }
        }
    }

    .thanks-dog{
        position: absolute;
        width: 227px;
        height: 391px;
        top: 0;
        right: -215px;
        background-position: left;
        background: url('/images/nettogumi/thanks_dog.png') no-repeat;
        background-size: 95%;
    }
}
