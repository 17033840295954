#cookie-warning{
    position: fixed;
    bottom: 0;
    right: 80px;
    width: 195px;
    z-index: 1500;
    color: $white;
    text-align: center;
    padding: 0 15px 15px;
    @include font-size(13px);
    background: rgba(0, 0, 0, 0.75);

    a{
        color: $yellow;
    }

    .btn-primary{
        color: $black;
        cursor: pointer;
        margin-top: 15px;
        padding: 2px 10px;
        background: $yellow;
        display: inline-block;
    }
}
