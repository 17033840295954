//Override BxLoader image Path!;
//Bx loader the one CSS File
@import 'node_modules/bxslider/dist/jquery.bxslider';
.bx-loading {
    background: url("/images/nettogumi/bx_loader.gif") center center no-repeat #ffffff !important;
}

@import 'node_modules/magnific-popup/dist/magnific-popup';
@import 'helpers/variables'; // Sass Variables
@import 'mixins/mixin'; // Sass mixins, functions
@include normalize; // Normalize
//@import 'vendors/font_awesome/font-awesome'; // Include Font-Awsome
//@import 'vendors/socicon'; // Include Socicon The social icon font - http://www.socicon.com/chart.php
//@import 'vendors/cartographer'; // Include cartographer icon font - http://www.dafont.com/cart-o-grapher.font
@import 'vendors/websymbol'; // Include websymbol font - https://www.fontsquirrel.com/fonts/web-symbols
@import 'components/components'; // Components: btn, forms, headings etc.
@import 'layout/layout'; // Grid system, Header, Footer etc.
@import 'vendors/owl-carousel/owl.carousel.scss'; 
@import 'vendors/owl-carousel/owl.theme.default.scss'; 

.hidden {
    display: none;
}
//Magnific popup hack
body.magnificpopupnoscroll{
    overflow-y: hidden !important;
}

@media (max-width: $screen-xs-min) { 
    .hidden-xs{
        display: none;
    }
}
/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
    .visible-xs{
        display: none;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) { 
    .visible-xs{
        display: none;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 
    .visible-xs{
        display: none;
    }
 }