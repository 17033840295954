#back-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: $gray-8;
  color: $white;
  @include border-radius(50%);
  @include transition(.3s);
  z-index: 999;
  display: none;
  text-align: center;

  span {
    text-align: center;
    display: block;
    width: 34px;
    height: 34px;
    border: 3px solid $white;
    @include border-radius(50%);
    margin-top: (50px - 34px) / 2;
    margin-left: (50px - 34px) / 2;
    background-color: $yellow;

    i {
      font-family: $websymbol;
      display: block;
      font-style: normal;
      @include font-size(23px);
      @include transform(rotate(90deg));
      margin-top: 1px;
      margin-left: 2px;

    }

  }

  &:hover {

    span {
      background: $gray-8;

    }

  }

}
