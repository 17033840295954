.floated-cart {

  width: 100%;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 1000;
  -moz-box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  -o-box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  box-shadow: 0 -4px 12px rgba(0, 0, 0, .1);
  transition-duration: 1s;


  &.animate {
    height: 200px;
    transition-duration: 1s;

  }


  .floated-cart-wrapper {
    padding: 30px 40px;

    @include clearfix();

    .product-image {

      img {
        line-height: 0;
        display: block;
        margin: 0;

      }

    }

    .text-container {

      .title {
        margin: 0;
        padding: 0;
        font-family: $poppins-medium;
        font-weight: normal;
        @include font-size(27px);
        color: $gray-16;
        line-height: 1.3em;

      }

      .price-cart-container {
        margin-top: 22px;

        @include clearfix();

        .low-price-package {
          width: 160px;

          .first {
            font-family: $roboto-condensed-bold;
            font-weight: normal;
            @include font-size(16px);
            color: $orange-1;
            background-color: $white;
            padding: 5px 10px;
            border: 1px solid $orange-1;
            text-align: center;

          }

          .last {
            font-family: $roboto-condensed-bold;
            font-weight: normal;
            @include font-size(19px);
            color: $white;
            padding: 7px 10px;
            text-align: center;
            background-color: $orange-1;

            small {
              @include font-size(16px);

            }

          }

        }

        .price-cont {
          margin-left: 34px;

          .price {
            margin: -5px 0 0;

            @include clearfix();

            .price-text {
              float: left;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(24px);
              color: $black-1;
              line-height: 1.1em;
              margin-top: 10px;
              margin-right: 10px;

              small {
                display: block;
                font-family: $roboto-bold;
                font-weight: normal;
                text-transform: uppercase;
                @include font-size(11px);
                line-height: .9em;

              }

            }

            .price-price {
              float: left;
              font-family: $roboto-condensed-bold;
              font-weight: normal;
              @include font-size(30px);
              color: $orange-1;
              line-height: 2.1em;

              small {
                @include font-size(29px);

              }

            }

          }

          .piece-price {
            display: block;
            padding: 3px 0 0;
            font-family: $roboto-bold;
            font-weight: normal;
            @include font-size(20px);
            color: $gray;

          }

        }

        .piece-button {
          margin-left: 20px;

          .button-wrapper {
            @include clearfix();

            .select-wrapper {
              position: relative;
              margin-top: 0;
              margin-right: 10px;

              select {
                padding: 17px 40px 17px 20px;
                background-color: $gray-2;
                border: 1px solid $gray-15;
                @include border-radius(0);
                width: 100%;
                color: $gray-16;
                @include font-size(21px);
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

              }

              &::after {
                content: '';
                height: 9px;
                width: 9px;
                background: url('/images/nettogumi/arrow_down.png') no-repeat;
pointer-events: none;
                background-size: 100%;
                position: absolute;
                top: 25px;
                right: 15px;
                z-index: 1;

              }

            }

            .product-button {
              display: inline-block;
              margin: -4px auto 0;
              padding: 20px 55px;
              font-family: $roboto-bold;
              font-weight: normal;
              @include font-size(19px);
              color: $black-1;
              @include transition(background .3s ease);
              background-color: $yellow;
              text-transform: uppercase;
              @include border-radius(12px);

              span {
                display: inline-block;
                width: 38px;
                height: 30px;
                background: url('/images/nettogumi/cart_icon_btn.png') center center no-repeat;
                background-size: 100%;
                margin: 0 10px -5px 0;

              }

              strong {
                font-weight: normal;

              }

              &:hover {
                color: $white;
                background-color: $black-1;

              }

            }

          }

        }

      }

    }

  }

}

@include screen($null, $sm) {

  .floated-cart {

    &.animate {
      height: 120px;

    }


    .floated-cart-wrapper {
      padding: 10px 0;

      .product-image {
        display: none;

      }

      .col-9 {
        width: 100%;

      }

      .text-container {

        .title {
          @include font-size(15px);

        }

        .price-cart-container {
          margin-top: 5px;

          .low-price-package {
            float: none;
            width: 100%;
            margin-bottom: 5px;

            @include clearfix();

            .first {
              float: left;
              width: 50%;
              @include font-size(13px);
              padding: 3px 10px;
              line-height: 1.2em;

            }

            .last {
              float: left;
              width: 50%;
              @include font-size(13px);
              padding: 4px 10px 4px;
              line-height: 1.2em;

              small {
                @include font-size(13px);

              }

            }

          }

          .price-cont {
            margin: 0;
            float: left;
            width: 45%;
            padding-left: 0;

            .price {
              margin: 0;

              .price-text {
                @include font-size(14px);
                margin-top: 0;
                margin-right: 0;

                small {
                  display: inline-block;
                  text-transform: none;
                  @include font-size(14px);
                  margin-right: 3px;

                }

              }

              .price-price {
                @include font-size(30px);

                small {
                  @include font-size(19px);

                }

              }

            }

            .piece-price {
              display: none;

            }

          }

          .piece-button {
            margin-top: 5px;
            margin-left: 0;
            float: left;
            width: 55%;

            .button-wrapper {

              .select-wrapper {
                margin-right: 10px;

                select {
                  padding: 8px 16px 8px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 17px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 8px 18px 10px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 0 -8px 0;

                }

                strong {
                  display: none;

                }

              }

            }

          }

        }

      }

    }

  }

}

@include screen(($sm + 1), $lg) {

  .floated-cart {

    &.animate {
      height: 140px;

    }


    .floated-cart-wrapper {
      padding: 15px 10px 10px;

      .product-image {
        display: none;

      }

      .col-9 {
        width: 100%;

      }

      .text-container {

        .title {
          @include font-size(24px);

        }

        .price-cart-container {
          margin-top: 15px;

          .low-price-package {
            width: 125px;

            .first {
              padding: 1px 10px;
              @include font-size(14px);

            }

            .last {
              padding: 3px 10px;
              @include font-size(14px);

              small {
                @include font-size(14px);

              }

            }

          }

          .price-cont {
            margin-top: 5px;
            margin-left: 20px;

            .price {

              .price-text {
                @include font-size(22px);
                margin-top: 2px;

                small {
                  @include font-size(10px);

                }

              }

              .price-price {
                @include font-size(33px);

                small {
                  @include font-size(22px);

                }

              }

            }

            .piece-price {
              padding: 0;
              @include font-size(16px);

            }

          }

          .piece-button {
            margin-left: 15px;

            .button-wrapper {

              .select-wrapper {
                margin-right: 5px;

                select {
                  padding: 12px 32px 12px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 21px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 13px 42px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 10px -8px 0;

                }

              }

            }

          }

        }

      }

    }

  }

}

@include screen(($sm + 1), $md, landscape) {

  .floated-cart {

    &.animate {
      height: 120px;

    }

    .floated-cart-wrapper {
      padding: 10px 0 10px;

      .text-container {

        .title {
          @include font-size(17px);

        }

        .price-cart-container {

          .low-price-package {
            width: 20%;
          }

          .price-cont {
            width: 40%;
            margin-left: 0;
            padding-left: 15px;

            .piece-price {
              padding: 0;
              margin-top: 0;
              @include font-size(14px);
              line-height: 1.1em;

            }

          }

          .piece-button {
            width: 40%;
            margin-left: 0;
            padding-left: 15px;

            .button-wrapper {

              .select-wrapper {
                margin-right: 10px;

                select {
                  padding: 8px 16px 8px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 17px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 8px 18px 10px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 0 -8px 0;

                }

                strong {
                  display: none;

                }

              }

            }

          }

        }

      }

    }

  }

}

@include screen(($lg+1), $xl) {

  .floated-cart {

    &.animate {
      height: 140px;

    }


    .floated-cart-wrapper {
      padding: 15px 10px 10px;

      .product-image {

        img {
          max-width: 80%;

        }

      }

      .text-container {

        .title {
          @include font-size(24px);

        }

        .price-cart-container {
          margin-top: 15px;

          .low-price-package {
            width: 125px;

            .first {
              padding: 1px 10px;
              @include font-size(14px);

            }

            .last {
              padding: 3px 10px;
              @include font-size(14px);

              small {
                @include font-size(14px);

              }

            }

          }

          .price-cont {
            margin-top: 2px;
            margin-left: 20px;

            .price {

              .price-text {
                @include font-size(22px);
                margin-top: 2px;

                small {
                  @include font-size(10px);

                }

              }

              .price-price {
                @include font-size(33px);
                line-height: 1.1em;
                small {
                  @include font-size(22px);

                }

              }

            }

            .piece-price {
              padding: 0;
              @include font-size(16px);

            }

          }

          .piece-button {
            margin-left: 15px;

            .button-wrapper {

              .select-wrapper {
                margin-right: 5px;

                select {
                  padding: 12px 32px 12px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 21px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 13px 42px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 10px -8px 0;

                }

              }

            }

          }

        }

      }

    }

  }

}

@include screen(($xl+1), $xxxl) {

  .floated-cart {

    &.animate {
      height: 135px;

    }


    .floated-cart-wrapper {
      padding: 15px 15px 10px;

      .product-image {

        img {
          max-width: 80%;

        }

      }

      .text-container {

        .title {
          @include font-size(24px);

        }

        .price-cart-container {
          margin-top: 15px;

          .low-price-package {
            width: 140px;

            .first {
              padding: 1px 10px;
              @include font-size(14px);

            }

            .last {
              padding: 4px 10px;
              @include font-size(14px);

              small {
                @include font-size(14px);

              }

            }

          }

          .price-cont {
            margin-top: 2px;
            margin-left: 20px;

            .price {

              .price-text {
                @include font-size(22px);
                margin-top: 2px;

                small {
                  @include font-size(10px);

                }

              }

              .price-price {
                @include font-size(33px);
                line-height: 1.1em;
                small {
                  @include font-size(22px);

                }

              }

            }

            .piece-price {
              padding: 0;
              @include font-size(16px);

            }

          }

          .piece-button {
            margin-left: 15px;

            .button-wrapper {

              .select-wrapper {
                margin-right: 5px;

                select {
                  padding: 12px 32px 12px 16px;
                  @include font-size(18px);

                }

                &::after {
                  top: 21px;
                  right: 10px;

                }

              }

              .product-button {
                margin: -3px auto 0;
                padding: 13px 42px;
                @include font-size(17px);

                span {
                  width: 31px;
                  height: 30px;
                  margin: 0 10px -8px 0;

                }

              }

            }

          }

        }

      }

    }

  }

}
