#auth{
    .panel{
        padding: 15px;
        margin: 40px 0px;

        .form-control{
            width: 100%;
            padding: 10px 20px;
        }

        .control-label{
            text-align: right;
            padding-top: 10px;
        }
    }
}